import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {selectProjects} from 'hsi/selectors';
import { ProjectType } from 'hsi/types/shared';

export default function useProjectsById() {
    const projects = useSelector(selectProjects);

    return useMemo(
        () =>
            (projects || []).reduce<Record<number, ProjectType>>((res, d) => {
                res[d.id] = d;
                return res;
            }, {}),
        [projects],
    );
};
