import { useCallback } from "react";

import useQueryId from "hsi/hooks/useQueryId";
import useQueryUserData, { useGetQueryUserDataSetter } from "hsi/hooks/useQueryUserData";
import { CardType, AggregateCardsType, AggregateCards, DefaultAggregates } from "hsi/types/cards";
import mapValues from "lodash/mapValues";
import { QueryUserData } from "hsi/types/queryUserData";


export function cardHasAggregate(type: any): type is keyof AggregateCardsType {
    return type in AggregateCards;
}

export function getCardAggregateOptions<T extends CardType>(type: T) {
    if(cardHasAggregate(type)) {
        return AggregateCards[type]
    }

    return null;
}

export function useGetCardAggregate<T extends CardType>(queryId: number | null | undefined, type : T) {
    return useQueryUserData(queryId, (queryUserData) => queryId !== null && queryId !== undefined && cardHasAggregate(type) 
        ? getCardAggregateFromState(type, queryUserData?.cardAggregates)
        : undefined )
}

export function useGetCurQueryCardAggregate<T extends CardType>(type: T) {
    return useGetCardAggregate(useQueryId(), type);
}

export function useGetCurQueryCardAggregates() {
    return useGetCardAggregates(useQueryId());
}

export function useGetCardAggregates(queryId?: number | null | undefined) {
    return useQueryUserData(queryId, (queryUserData) => {
        return mapValues(AggregateCards, (value, type) => {
            return getCardAggregateFromState(type as CardType, queryUserData?.cardAggregates);
        })
    });
}

export function getCardAggregateFromState(type: CardType, state: QueryUserData['cardAggregates'] | null | undefined) {
    return cardHasAggregate(type) ? state?.[type] ?? DefaultAggregates[type] : undefined;
}


/**
 * Returns a function that can be called to update the value of a given card's aggregate
 * @param queryId 
 * @param type  the card type
 * @returns setter function
 */
export function useGetCardAggregateSetter<T extends keyof AggregateCardsType>(queryId: number, type: T) {
    const setter = useGetQueryUserDataSetter(queryId);

    return useCallback(async (value: AggregateCardsType[T][number]) => {
        if(!setter) {
            return false;
        }

        await setter('cardAggregates', (cardAggregates) => {
            return {
                ...cardAggregates,
                [type]: value,
            };
        });

        return true
    }, [setter, type]);
}
