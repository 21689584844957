import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);
    return {
        container: {
            minHeight: v2 ? '93vh' : '80vh',
            backgroundColor: v2 ? theme.colors.uiGrayDark : undefined,
        },
        subtitleWrapper: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            height: 36,
            margin: v2 ? '27px 27px 13px 27px' : '20px 20px 10px 20px',
        },
        subtitle: {
            margin: '0 23px 0 0',
            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                    fontSize: 24,
                    fontWeight: 700,
                }),
        },
        subtitleEntitiesLeft: {
            height: v2 ? 0 : 20,
            paddingLeft: v2 ? 0 : 20,
            ...(v2 ? {} : {borderLeft: `1px solid ${theme.colors.darkGrey60}`}),
        },
        subtitleLoader: {
            marginLeft: 0,
        },
        titleWrapper: {
            color: v2 ? theme.colors.carbon : theme.colors.textDefault,
            display: 'flex',
            marginTop: 16,
        },
        titleHelpIcon: {
            marginLeft: 8,
        },
        title: {
            margin: 0,
            ...(v2
                ? theme.fonts.headlineStrong
                : {
                    fontSize: 24,
                    fontWeight: 700,
                }),
        },
        titleDescription: {
            margin: '16px 0',
            ...(v2 ? theme.fonts.headtitleNormal : {fontWeight: 400, fontSize: 16}),
        },
        titleButtonIcon: {
            display: v2 ? 'none' : 'inherit',
            fill: theme.colors.textMeta,
            marginRight: 7,
        },

        addSrcBtn: {
            display: 'flex',
            margin: '0 0 0 auto',
        },

        tabs: {
            padding: '0 20px',
        },
        tab: {
            alignItems: v2 ? 'flex-start' : 'center',
            display: 'inline-flex',
            verticalAlign: 'bottom',
        },
        tabIcon: {
            height: 20,
            marginRight: 7,
            width: 20,
        },
    }
});
