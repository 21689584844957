import {useMemo, useState} from 'react';

// Components
import PaginationButtons from 'hsi/components/PaginationButtons';
import Heading from 'hsi/components/aria/Heading';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';

// Utils
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import useManagedPagination from 'hsi/components/table/ManagedTable/useManagedPagination';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useGetTableColumns from './useReportTableColumns';

import {T} from 'hsi/i18n';
import useStyles from './styles';

// Types
import {SnapshotList} from 'hsi/types/snapshots';
import PulseLoader from '../PulseLoader';
import Banner from '../Banner';

// Consts
const perPage = 5;

interface ReportListProps {
    snapshots: SnapshotList;
    loading: boolean;
    showSuccessBanner: boolean;
}

/**
 *
 * @returns
 */
export default function ReportList({snapshots, loading, showSuccessBanner}: ReportListProps) {
    const classes = useStyles();
    const columns = useGetTableColumns();
    const [sortingDirection, setSortingDirection] = useState<'desc' | 'asc'>('asc');
    const {page, pages, setPage} = useManagedPagination(snapshots?.length ?? 0, perPage);

    const onSortClick = () => {
        if (sortingDirection === 'asc') {
            setSortingDirection('desc');
        } else {
            setSortingDirection('asc');
        }
    };

    const sortedRows = useMemo(() => {
        return sortingDirection === 'asc' ? [...snapshots].reverse() : snapshots;
    }, [snapshots, sortingDirection]);

    // Apply pagination
    const paginatedRows = usePaginateRows(sortedRows, page, perPage);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    return (
        <>
            {showSuccessBanner && (
                <Banner variant="success">{T('exportReports.passwordGenerator.success')}</Banner>
            )}
            <Heading className={classes.title}>{T('exportReports.list.title')}</Heading>
            {loading ? (
                <PulseLoader />
            ) : snapshots?.length > 0 ? (
                <>
                    <ManagedGridTable
                        columns={columns}
                        rowData={formattedRows}
                        caption={T('exportReports.list.title')}
                        sortColumn={'date'}
                        sortDirection={sortingDirection}
                        onSortClick={onSortClick}
                    />
                    <PaginationButtons page={page} pages={pages} setPage={setPage} />
                </>
            ) : (
                <span>{T('exportReports.list.empty')}</span>
            )}
        </>
    );
}
