import {useEffect, useRef} from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {useAppDispatch} from 'hsi/hooks/useRedux';

import {toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';
import {T} from 'hsi/i18n';

import {Theme, isV2} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme) => {
    const v2 = isV2(theme);
    const {font} = theme;

    return {
        root: {
            padding: 32,
            textAlign: 'center',
        },
        title: {
            ...(v2 ? theme.fonts.headtitleStrong : {fontSize: 16, fontWeight: font.bold}),
            paddingBottom: 8,
        },
        msg: {
            '& a': {
                cursor: 'pointer',
            },
        },
    };
});

type NoFacebookPagesFoundProps = {
    title?: string;
};

const NoFacebookPagesFound = ({title}: NoFacebookPagesFoundProps) => {
    const dispatch = useAppDispatch();
    const cl = useStyles();

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const link = ref?.current?.querySelector('a');

        const onClick = (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            dispatch(toggleFacebookLoginDialog(true));
        };

        link?.addEventListener('click', onClick);

        return () => link?.removeEventListener('click', onClick);
    }, [dispatch]);

    return (
        <div className={cl.root}>
            <div className={cl.title}>{title || T('queryBuilder.noPagesFound')}</div>
            <div
                ref={ref}
                className={cl.msg}
                dangerouslySetInnerHTML={{__html: T('queryBuilder.fbAuthToAddPages')}}
            />
        </div>
    );
};

export default NoFacebookPagesFound;
