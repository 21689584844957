import {PropsWithChildren, useCallback} from 'react';
import capitalize from 'lodash/capitalize';

//Components
import Button from 'hsi/components/Button';
import Popover from 'hsi/components/Popover';
import Markdown from 'hsi/components/Markdown';

//Actions
import {openSaveSearchDialog} from 'hsi/actions/savedSearchActions';

//Utils
import isBCR from 'hsi/utils/isBCR';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import { useAppDispatch, useAppSelector } from 'hsi/hooks/useRedux';

export type SaveSearchButtonProps = PropsWithChildren<{
    inlineLink?: boolean;
}>;

//The component
export default function SaveSearchButton({inlineLink, children}: SaveSearchButtonProps) {
    const classes = useStyles();

    //Redux
    const maxSearches = useAppSelector((state) => state.user.account!.client.queryLimit);
    const numSearches = useAppSelector((state) => state.user.usedQueries);
    const canSaveSearch = useAppSelector((state) => state.user.canSaveSearch);
    const validated = useAppSelector((state) => state.query.validated);

    const dispatch = useAppDispatch();

    //calculates values
    const disabled = numSearches >= maxSearches;
    const isSaveSearchEnabled = !(disabled || !canSaveSearch);

    //Callbacks
    const onClick = useCallback(
        () => (!disabled && canSaveSearch ? dispatch(openSaveSearchDialog(true)) : null),
        [dispatch, disabled, canSaveSearch],
    );

    //Render
    const btn = !inlineLink ? (
        <Button priority="cta" onClick={isSaveSearchEnabled ? onClick : undefined} disabled={disabled}>
            {children ||
                (isBCR() ? T('searchbar.savequery') : T('searchbar.savesearch', capitalize))}
        </Button>
    ) : (
        <button
            type="button"
            onClick={isSaveSearchEnabled ? onClick : undefined}
            className={classes.saveSearchLink}
        >
            {children}
        </button>
    );
    if (!isSaveSearchEnabled) {
        const popMsg = !validated
            ? T('searchbar.notValidated')
            : canSaveSearch
            ? T('searchbar.searchesLimitReached')
            : T('search.unauthorizedToSave');

        return (
            <Popover
                portal
                content={<Markdown className={classes.savedSearchBtnMsg}>{popMsg}</Markdown>}
            >
                {btn}
            </Popover>
        );
    } else {
        return btn;
    }
}
