/* A hook to handle export report logic, API calls and modal UI flow. */

import {MutableRefObject, useCallback, useEffect, useState} from 'react';

// Redux
import {exportToPDF, getSnapshotData} from 'hsi/actions/exportActions';
import {useAppDispatch, useAppSelector, useAppStore} from 'hsi/hooks/useRedux';
import {resetPDFState, saveExportConfig} from 'hsi/slices/pdfExport';
import {showNotification} from 'hsi/actions/notificationsActions';

// APIs
import {
    addSharedDashboardSnapshot,
    addSharedDashboards,
    editSharedDashboards,
    getSharedDashboardSnapshots,
} from 'hsi/services/apiv2/sharedDashboardsAdmin';

// Types
import {CCFlagsType} from 'hsi/types/cc-flags';
import {AnnotationKey, ChartKey} from 'hsi/types/charts';
import {AppSource} from 'hsi/types/shared';
import {SnapshotList} from 'hsi/types/snapshots';
import {ReportModalSections} from '..';
import {SNAPSHOT_DATA_VERSION} from 'hsi/constants/config';
import {T} from 'hsi/i18n';

interface UseExportReportsModalProps {
    savedSearchId: number;
    projectId: number;
    flags: CCFlagsType;
    appSource: AppSource;
    openWithSection: ReportModalSections;
    handleClose: () => void;
    nameRef: MutableRefObject<{name?: string} | null>;
}

export const useExportReportsModal = ({
    savedSearchId,
    projectId,
    flags,
    appSource,
    openWithSection,
    handleClose,
    nameRef,
}: UseExportReportsModalProps) => {
    const dispatch = useAppDispatch();
    const store = useAppStore();

    const {cardsToExport} = useAppSelector((state) => state.pdfExport);

    // Modal section to display
    const [modalSection, setModalSection] = useState<ReportModalSections>(openWithSection);

    // Snapshots state
    const [snapshots, setSnapshots] = useState<SnapshotList>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Password state
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

    // *******
    // Utils
    // *******

    const saveExportConfigToStore = useCallback(
        (
            selectedCards: Partial<Record<ChartKey, boolean>>,
            annotations: Partial<Record<AnnotationKey, string | undefined>>,
        ) => {
            const selectedCardsToExport = (Object.keys(selectedCards) as ChartKey[]).filter(
                (cardName) => selectedCards[cardName],
            );
            dispatch(
                saveExportConfig({
                    annotations: {...annotations, [savedSearchId]: annotations},
                    cardsToExport: {
                        ...cardsToExport,
                        [savedSearchId]: selectedCardsToExport,
                    },
                }),
            );
        },
        [cardsToExport, dispatch, savedSearchId],
    );

    // ********
    // API CALLS
    // ********

    // Fetch snapshots and handle initial modal state
    const fetchSnapshots = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getSharedDashboardSnapshots(projectId, savedSearchId);
            const snapshots = res.body.response;
            setSnapshots(snapshots);
        } catch (error: any) {
            // if we receive a 404 from fetch snapshots during the create snapshot flow, shared dashboards are not enabled for this query
            // therefore we send the user to password generator to create a password and enable shared dashboards
            if (error.status === 404) {
                if (openWithSection === 'viewReports') {
                    // if we receive a 404 from fetch snapshots in the viewReports section, snapshots are not enabled for this query and obviously no snapshots exist, so ignore the error.
                    return;
                }
                setModalSection('passwordGenerator');
            } else {
                // unknown error
                setError(T('exportReports.errors.fetch'));
            }
            throw error;
        } finally {
            setLoading(false);
        }
    }, [openWithSection, projectId, savedSearchId]);

    // Save query password and enable shared dashboards for this query.
    const enableSharedDashboardsForQuery = useCallback(async () => {
        try {
            setLoading(true);
            await addSharedDashboards(projectId, savedSearchId, password);
        } catch (error) {
            setError(T('exportReports.errors.password'));
            throw error;
        } finally {
            setLoading(false);
        }
    }, [password, projectId, savedSearchId]);

    const changePassword = useCallback(async () => {
        try {
            setLoading(true);
            await editSharedDashboards(projectId, savedSearchId, password);
            setPassword('');
            dispatch(
                showNotification({
                    message: T('exportReports.passwordGenerator.passwordUpdated'),
                    hidable: true,
                    variant: 'success',
                }),
            );
            setModalSection('viewReports');
        } catch (error) {
            setError(T('exportReports.errors.password'));
            throw error;
        } finally {
            setLoading(false);
        }
    }, [dispatch, password, projectId, savedSearchId]);

    // Save snapshot report for this query
    const saveReport = useCallback(async () => {
        try {
            setLoading(true);
            const name = nameRef.current?.name!;
            const state = store.getState();
            const snapshotData = getSnapshotData(state, flags, appSource);
            const snapshotAPIObj = {
                name,
                dataVersion: SNAPSHOT_DATA_VERSION,
                data: snapshotData,
            };

            const res = await addSharedDashboardSnapshot(projectId, savedSearchId, snapshotAPIObj);
            const snapshot = res.body.response;

            setSnapshots((prev) => [...prev, snapshot]);
            setModalSection('viewReports');
        } catch (error) {
            setError(T('exportReports.errors.save'));
            throw error;
        } finally {
            setLoading(false);
        }
    }, [nameRef, store, flags, appSource, projectId, savedSearchId]);

    // *********
    // Modal Flow Control
    // *********

    const onClose = useCallback(() => {
        // reset pdf state
        dispatch(resetPDFState());
        handleClose();
    }, [handleClose, dispatch]);

    const enableSharedDashboardsForQueryHandler = useCallback(async () => {
        try {
            // Save password for this query reports + enable report sharing
            await enableSharedDashboardsForQuery();
            // Save this snapshot
            await saveReport();
        } catch (error) {
            console.error('Error enabling shareable dashboard for report.');
        }
    }, [enableSharedDashboardsForQuery, saveReport]);

    const savePasswordHandler = useCallback(async () => {
        if (isChangingPassword) {
            await changePassword();
        } else {
            await enableSharedDashboardsForQueryHandler();
        }
    }, [changePassword, enableSharedDashboardsForQueryHandler, isChangingPassword]);

    const changePasswordHandler = useCallback(() => {
        setModalSection('passwordGenerator');
        setIsChangingPassword(true);
    }, []);

    // Export PDF logic
    const exportAsPDFHandler = useCallback(
        async (
            selectedCards: Partial<Record<ChartKey, boolean>>,
            annotations: Partial<Record<AnnotationKey, string | undefined>>,
        ) => {
            saveExportConfigToStore(selectedCards, annotations);
            dispatch(exportToPDF(flags, appSource, true));
            onClose();
        },
        [saveExportConfigToStore, dispatch, flags, appSource, onClose],
    );

    const createReportHandler = useCallback(
        async (
            selectedCards: Partial<Record<ChartKey, boolean>>,
            annotations: Partial<Record<AnnotationKey, string | undefined>>,
        ) => {
            saveExportConfigToStore(selectedCards, annotations);
            try {
                await fetchSnapshots();
                await saveReport();
            } catch (error) {
                console.error('Error creating report.');
            }
        },
        [saveExportConfigToStore, fetchSnapshots, saveReport],
    );

    // Fetch reports when opening from view reports section
    useEffect(() => {
        if (openWithSection === 'viewReports' && snapshots.length === 0) {
            const loadFromViewReports = async () => {
                try {
                    await fetchSnapshots();
                } catch (error) {
                    console.error('Error fetching snapshots');
                }
            };
            loadFromViewReports();
        }
    }, [
        dispatch,
        fetchSnapshots,
        modalSection,
        openWithSection,
        projectId,
        savedSearchId,
        snapshots.length,
    ]);

    return {
        modalSection,
        snapshots,
        password,
        setPassword,
        isPasswordValid,
        setIsPasswordValid,
        nameRef,
        saveReport,
        savePasswordHandler,
        exportAsPDFHandler,
        createReportHandler,
        onClose,
        loading,
        error,
        setIsChangingPassword,
        changePasswordHandler,
    };
};
