import {makeStyles} from '@mui/styles';

export default makeStyles(() => ({
    container: {
        display: 'flex',
        width: '100vw',
        minHeight: '100vh',
        position: 'relative',
    },
    content: {
        flex: 1,
    },
    snapshotContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
}));
