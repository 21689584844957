import {
    configureStore as reduxConfigureStore,
    PreloadedState,
    StateFromReducersMapObject,
} from '@reduxjs/toolkit';
import {createReduxHistoryContext} from 'redux-first-history';
import {createBrowserHistory, History} from 'history';
//import logger from 'redux-logger'; - leave because can be useful to enable when debugging

import {CreateRootReducer, RootReducer} from 'hsi/reducers/rootReducer';
import {sharedDashboardMiddleware} from 'hsi/slices/sharedDashboard/middleware';

export const history = createBrowserHistory();
export type ConfiguredPreloadedState = PreloadedState<StateFromReducersMapObject<RootReducer>>;
export type Store = ReturnType<typeof configureStore>[0];
export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;

export default function configureStore(
    createRootReducer: CreateRootReducer,
    history: History,
    preloadedState?: ConfiguredPreloadedState,
) {
    const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
        history,
    });

    const store = reduxConfigureStore({
        reducer: createRootReducer(routerReducer), // root reducer with router state
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true,
                immutableCheck: false, // This currently warns A LOT! We should switch on and fix at some point
                serializableCheck: false, // This currently warns A LOT! We should switch on and fix at some point
            })
                .prepend(sharedDashboardMiddleware.middleware) // this middleware updates multiple slices when selecting a report in our public shared dashboard
                .concat(routerMiddleware),
    });

    return [store, createReduxHistory!(store)] as const;
}
