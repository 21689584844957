import { useMemo } from 'react';

// Components
import CompactNumber from 'hsi/components/format/CompactNumber';

// Utils
import {T} from 'hsi/i18n';

// Types
import { TableColumnDefinition } from './types';
import { TotalVolumeMetricType } from 'hsi/types/cards';


//The hook
export default function useGetTableColumns(metrics: TotalVolumeMetricType[], classes: Record<string, string>): TableColumnDefinition[] {
    return useMemo(() => {
        const columns: TableColumnDefinition[] = [
            {
                name: 'position',
                label: <span className="offscreen">{T('cards.tophashtags.tableFields.position')}</span>,
                horizontalAlign: 'start',
                width: 'auto',
                format: (row) => <span className={classes.tableRowPosition}>{row.position}</span>
            },
            {
                name: 'name',
                label: T('cards.totalVolumeBySearch.tableFields.name'),
                horizontalAlign: 'start',
                truncateHeaderLabelText: true,
                truncateCellText: true,

                width: '2fr',
                sortable: true,
            },
        ];

        metrics.forEach((metric) => {
            columns.push({
                name: metric,
                label: T(`cards.totalVolumeBySearch.tableFields.${metric}`),
                truncateHeaderLabelText: true,
                truncateCellText: true,
                width: '1fr',
                format: (row) => {
                    return (row[metric] === null || row[metric] === undefined) 
                        ? '-' 
                        : <CompactNumber value={row[metric]!} tooltip />
                },
                sortable: true,
                sortType: 'numeric',
                defaultSortDir: 'desc',
            });
        })

        return columns;
    }, [classes.tableRowPosition, metrics]);
};
