import {
    CLEAR_RESULTS,
    LOADING_DATA,
    MENTIONS_DRILL_IN,
    PEAKS_LOADED,
    TOGGLE_CONFIG,
    TOTAL_VOLUME_CONFIG_UPDATE,
    WORD_CLOUD_CONFIG_UPDATE,
    REPLACE_RESULTS_STATE
} from 'hsi/constants/actionTypes';

const initialState = {
    peaks: {},
    sideDrawer: {
        open: false,
        type: 'wordCloud',
    },
};

const resultReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_RESULTS:
            return initialState;
        case LOADING_DATA:
            return {
                ...state,
                peaks: {
                    ...state.peaks,
                    [action.payload.type]: null,
                },
            };
        case PEAKS_LOADED:
            return {
                ...state,
                peaks: {...state.peaks, [action.payload.type]: action.payload.peaks},
            };
        case MENTIONS_DRILL_IN:
            return {...state, sideDrawer: {...state.sideDrawer, open: false}};
        case TOGGLE_CONFIG:
            return {...state, sideDrawer: {...state.sideDrawer, ...action.payload}};
        case WORD_CLOUD_CONFIG_UPDATE:
            return {
                ...state,
                sideDrawer: {
                    ...state.sideDrawer,
                    wordCloud: {...state.sideDrawer.wordCloud, ...action.payload},
                },
            };
        case TOTAL_VOLUME_CONFIG_UPDATE:
            return {
                ...state,
                sideDrawer: {
                    ...state.sideDrawer,
                    totalVolume: {...state.sideDrawer.totalVolume, ...action.payload},
                },
            };
        case REPLACE_RESULTS_STATE: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default resultReducer;
