// TODO: Split into get/add/remove hooks, then different data source hooks
import _orderBy from 'lodash/orderBy';
import {useCallback, useMemo, useState} from 'react';
import {useMutation, useInfiniteQuery, useQuery, useQueryClient} from '@tanstack/react-query';
import {useDebounceCallback} from '@react-hook/debounce';
import {push} from 'redux-first-history';

import useEventTrack from 'hsi/hooks/useEventTrack';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useLinkedinIsEnabled from 'hsi/hooks/useLinkedinIsEnabled';

import {showNotification} from 'hsi/actions/notificationsActions';
import {resetQueryData, submitQuery} from 'hsi/actions/queryActions';

import FacebookTokenService from 'hsi/services/facebookTokenService';
import * as TargetedDataService from 'hsi/services/targetedDataService';
import {formatLinkedinAuthentications} from 'hsi/utils/dataManagement/formatAuthentications';
import {authorsParsers, parseInstagramHashtags} from 'hsi/utils/formulaParser';
import {inputAccountValueParser} from 'hsi/containers/QueryBuilder/GuidedQueryForm/utils';
import {getSearchResultsUrl} from 'hsi/utils/url';

import parsers from 'hsi/utils/dataManagement/parsers';

import {T} from 'hsi/i18n';
import * as queryKeys from 'hsi/constants/queryKeys';
import {
    AuthenticationItem,
    Data,
    DataManagementDataTypes,
    FacebookPageItem,
    InstagramAccountItem,
    InstagramHashtagItem,
    Item,
    LinkedinChannelItem,
} from 'hsi/types/dataManagement';

const PAGE_SIZE = 20;

const unknownErrorMessage = {
    message: T('unknownError'),
    variant: 'warning',
};

type UseDataSourceProps = {
    /** This is the function used to add a new data source */
    addFn: (args: any) => Promise<unknown>;
    /** This lets you disable immediate fetch when using this hook */
    isGetEnabled?: boolean;
    /** This is the function that gets the current list of data */
    getFn: (args: any) => Promise<unknown>;
    /** This allows you to manually transform the data returned from the get function.
     * It's useful for manual sorting or manual filtering when it's not supported by the API
     * */
    getFilter?: (data: Data) => Data;
    /** This is used to change the parameters in the get function for API based sorting and filtering */
    getParams?: any;
    /** This is a callback for when you successfully add a new data source */
    onAddSuccess?: (items: Item[]) => void;
    /** This is a callback for when you successfully remove a data source */
    onRemoveSuccess?: (items: Item[]) => void;
    /** This is the function used to remove a data source */
    removeFn: (args: any) => Promise<unknown>;
    /** This selects the parser that is used on the data returned by each function.
     * This might be changed to be the parser itself rather than selecting a parser
     * */
    type: DataManagementDataTypes;
    /** This is the function that validates a set of data sources and tells you if they are valid or not */
    validateFn?: (args: any) => Promise<unknown>;
};

const useDataSource = ({
    addFn,
    getFn,
    getFilter,
    getParams,
    isGetEnabled = true,
    onAddSuccess,
    removeFn,
    onRemoveSuccess,
    type,
    validateFn,
}: UseDataSourceProps) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    if (!parsers[type]) {
        throw new Error(`Parsers does not exist for type: ${type}`);
    }

    const parser = parsers[type];

    const all = useInfiniteQuery({
        enabled: isGetEnabled,
        getNextPageParam: (lastPage: Data) =>
            lastPage.summary.totalPages !== lastPage.summary.number
                ? lastPage.summary.number + 1
                : undefined,
        queryFn: async (args): Promise<Data> => {
            const response = await getFn(args);
            return parsers[type].list(response);
        },
        queryKey: [type, getParams],
        select: (data) => ({
            pages: data.pages.map((group) => getFilter?.(group) || group),
            pageParams: data.pageParams,
        }),
    });

    const add = useMutation(addFn, {
        onError: () => {
            dispatch(showNotification(unknownErrorMessage));
        },
        onSuccess: (rawResult, inputItem) => {
            const result = parser.response(rawResult, inputItem);

            queryClient.invalidateQueries([type]);
            queryClient.invalidateQueries([queryKeys.DATA_SOURCE_COUNT]);

            if (result?.succeeded?.length > 0 && result?.failed.length === 0) {
                if (result.succeeded.length === 1) {
                    dispatch(
                        showNotification({
                            message: T(`dataManagement.notifications.addOneSuccess.${type}`, {
                                name: result.succeeded[0].name,
                            }),
                            variant: 'success',
                        }),
                    );
                } else {
                    dispatch(
                        showNotification({
                            message: T(`dataManagement.notifications.addManySuccess.${type}`, {
                                amount: result.succeeded.length,
                            }),
                            variant: 'success',
                        }),
                    );
                }

                onAddSuccess?.(result.succeeded);
            } else {
                dispatch(showNotification(unknownErrorMessage));
            }
        },
    });

    const remove = useMutation(removeFn, {
        onError: () => {
            dispatch(showNotification(unknownErrorMessage));
        },
        onSuccess: (rawResult, inputItem: Item) => {
            const result = parser.response(rawResult, inputItem);

            queryClient.invalidateQueries([type]);
            queryClient.invalidateQueries([queryKeys.DATA_SOURCE_COUNT]);

            if (result?.succeeded?.length > 0 && result?.failed.length === 0) {
                dispatch(
                    showNotification({
                        message: T(`dataManagement.notifications.deleteSuccess.${type}`, {
                            name: inputItem.name,
                        }),
                        variant: 'success',
                    }),
                );

                onRemoveSuccess?.(result.succeeded);
            } else {
                dispatch(showNotification(unknownErrorMessage));
            }
        },
    });

    const _validate = useMutation(validateFn ? validateFn : () => null as any);
    const validate = useCallback(
        async (items: any) => {
            try {
                const response = await _validate.mutateAsync(items);
                if (response && parser?.validate) {
                    return parser.validate(response);
                } else {
                    return {failed: [], succeeded: []};
                }
            } catch (error) {
                console.log(error);
                return {failed: [], succeeded: []};
            }
        },
        [_validate, parser],
    );

    return {
        all, // TODO: Probably remove this after check it's not used
        // Data
        data: all?.data?.pages.map((group) => group.items).flatMap((x) => x),
        totalElements: all?.data?.pages?.[0]?.summary?.totalElements || 0,
        // Statuses
        isError: all?.isError,
        isLoading: !!all?.isLoading || !!add?.isLoading || !!remove?.isLoading,
        isSuccess: all?.isSuccess,
        // Functions
        add,
        fetchNextPage: all?.fetchNextPage,
        remove,
        validate,
    };
};

const useFacebookPages = ({
    isGetEnabled = true,
    defaultOrder = 'asc',
    defaultOrderBy = 'name',
    defaultSearch = '',
    defaultSize = PAGE_SIZE,
} = {}) => {
    const dispatch = useAppDispatch();
    const {track} = useEventTrack();

    const [order, setOrder] = useState<string>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [search, setSearch] = useState<string>(defaultSearch);

    const debounceSetSearch = useDebounceCallback(setSearch, 300);

    const getParams = useMemo(
        () => ({order, orderBy, search, size: defaultSize}),
        [defaultSize, order, orderBy, search],
    );

    const sanitizeList = (pages: string[]) => pages.map((i) => i.replace('profile.php?id=', ''));

    const params = useMemo(
        () => ({
            ...getParams,
            setOrder,
            setOrderBy,
            setSearch: debounceSetSearch,
        }),
        [debounceSetSearch, getParams],
    );

    const dataSource = useDataSource({
        addFn: ({pages}) => TargetedDataService.addFacebookPages(pages),
        getFn: ({pageParam = 0}) =>
            TargetedDataService.getFacebookPages({...getParams, page: pageParam}),
        getParams,
        isGetEnabled,
        onAddSuccess: (items) => {
            track('targetedDataSourceAdded', {
                type: 'Facebook Page',
                owned: (items[0] as FacebookPageItem).owned,
            });
        },
        onRemoveSuccess: () => {
            track('targetedDataSourceDeleted', {type: 'Facebook Page'});
        },
        removeFn: (item: FacebookPageItem) =>
            TargetedDataService.deleteFacebookPage(item.id, item.owned),
        type: queryKeys.FACEBOOK_PAGES,
        //sanitizeList removes 'profile.php?id=' as they were not being found.
        validateFn: (pages: string[]) => TargetedDataService.findFacebookPages(sanitizeList(pages)),
    });

    return {
        // TODO: Memoize this
        ...dataSource,
        activeOwned:
            (dataSource.data as FacebookPageItem[])?.filter(({active, owned}) => active && owned) ||
            [],
        activeNonOwned:
            (dataSource.data as FacebookPageItem[])?.filter(
                ({active, owned}) => active && !owned,
            ) || [],
        onExplore: (item: FacebookPageItem) => {
            // TODO: resetQueryData is here to skip persistId and should be remove once persistId is refactored
            dispatch(resetQueryData());
            dispatch(
                submitQuery(authorsParsers.facebook([inputAccountValueParser.facebook(item)])),
            );
        },
        params,
    };
};

const useInstagramAccounts = ({
    isGetEnabled = true,
    defaultOrder = 'asc',
    defaultOrderBy = 'igbaHandle',
    defaultSearch = '',
    defaultSize = PAGE_SIZE,
    fetchGraphHashtagCount = false,
    owned,
    authenticated,
}: {
    isGetEnabled?: boolean;
    defaultOrder?: string;
    defaultOrderBy?: string;
    defaultSearch?: string;
    defaultSize?: number;
    fetchGraphHashtagCount?: boolean;
    owned?: boolean;
    authenticated?: boolean;
} = {}) => {
    const dispatch = useAppDispatch();
    const {track} = useEventTrack();

    const [order, setOrder] = useState<string>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [search, setSearch] = useState<string>(defaultSearch);

    const debounceSetSearch = useDebounceCallback(setSearch, 300);

    const getParams = useMemo(
        () => ({
            order,
            orderBy,
            search,
            size: defaultSize,
            fetchGraphHashtagCount,
            owned,
            authenticated,
        }),
        [defaultSize, order, orderBy, search, fetchGraphHashtagCount, owned, authenticated],
    );

    const params = useMemo(
        () => ({
            ...getParams,
            setOrder,
            setOrderBy,
            setSearch: debounceSetSearch,
        }),
        [debounceSetSearch, getParams],
    );

    const dataSource = useDataSource({
        addFn: ({accounts}) => TargetedDataService.addInstagramAccounts(accounts),
        getFn: ({pageParam = 0}) =>
            TargetedDataService.getInstagramAccounts({...getParams, page: pageParam}),
        getParams,
        isGetEnabled,
        onAddSuccess: (items) => {
            track('targetedDataSourceAdded', {
                type: 'Instagram Account',
                owned: (items[0] as InstagramAccountItem).owned,
            });
        },
        onRemoveSuccess: () => {
            track('targetedDataSourceDeleted', {type: 'Instagram Account'});
        },
        removeFn: (item: InstagramAccountItem) =>
            TargetedDataService.deleteInstagramAccount(item.id, item.owned),
        type: queryKeys.INSTAGRAM_ACCOUNTS,
        validateFn: (handles: string[]) => TargetedDataService.verifyInstagramAccounts(handles),
    });

    return {
        ...dataSource,
        activeOwned:
            (dataSource.data as InstagramAccountItem[])?.filter(
                ({active, owned}) => active && owned,
            ) || [],
        activeNonOwned:
            (dataSource.data as InstagramAccountItem[])?.filter(
                ({active, owned}) => active && !owned,
            ) || [],
        onExplore: (item: InstagramAccountItem) => {
            // TODO: resetQueryData is here to skip persistId and should be remove once persistId is refactored
            dispatch(resetQueryData());
            dispatch(
                submitQuery(authorsParsers.instagram([inputAccountValueParser.instagram(item)])),
            );
        },
        params,
    };
};

const useInstagramHashtags = ({
    isGetEnabled = true,
    defaultOrder = 'asc',
    defaultOrderBy = 'hashtag',
    defaultSearch = '',
    defaultSize = PAGE_SIZE,
} = {}) => {
    const dispatch = useAppDispatch();
    const {track} = useEventTrack();

    const [order, setOrder] = useState<string>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [search, setSearch] = useState<string>(defaultSearch);

    const debounceSetSearch = useDebounceCallback(setSearch, 300);

    const getParams = useMemo(
        () => ({
            order,
            orderBy: orderBy !== 'linkedAccount' ? orderBy : undefined,
            search,
            size: defaultSize,
        }),
        [defaultSize, order, orderBy, search],
    );

    const params = useMemo(
        () => ({
            ...getParams,
            orderBy,
            setOrder,
            setOrderBy,
            setSearch: debounceSetSearch,
        }),
        [debounceSetSearch, getParams, orderBy],
    );

    const dataSource = useDataSource({
        addFn: ({
            instagramBusinessAccountId,
            hashtags,
        }: {
            instagramBusinessAccountId: string;
            hashtags: string[];
        }) => TargetedDataService.addInstagramHashtags(instagramBusinessAccountId, hashtags),
        getFn: ({pageParam = 0}) =>
            TargetedDataService.getInstagramHashtags({...getParams, page: pageParam}),
        getFilter: (data) => {
            if (orderBy !== 'linkedAccount') return data;

            return {
                ...data,
                items: _orderBy(data.items, [orderBy], [order as 'asc' | 'desc']),
            };
        },
        getParams,
        isGetEnabled,
        onAddSuccess: () => {
            track('targetedDataSourceAdded', {type: 'Instagram Hashtag'});
        },
        onRemoveSuccess: () => {
            track('targetedDataSourceDeleted', {type: 'Instagram Hashtag'});
        },
        removeFn: (item: InstagramHashtagItem) =>
            TargetedDataService.deleteInstagramHashtag(item.linkedAccountId, item.name),
        type: queryKeys.INSTAGRAM_HASHTAGS,
    });

    return {
        ...dataSource,
        active: (dataSource.data as InstagramHashtagItem[])?.filter(({active}) => active) || [],
        onExplore: (item: InstagramHashtagItem) => {
            // TODO: resetQueryData is here to skip persistId and should be remove once persistId is refactored
            dispatch(resetQueryData());
            dispatch(submitQuery(parseInstagramHashtags({values: [item.name]})));
        },
        params,
    };
};

const useLinkedinChannels = ({
    isGetEnabled = true,
    defaultOrder = 'asc',
    defaultOrderBy = 'name',
    defaultSearch = '',
} = {}) => {
    const dispatch = useAppDispatch();
    const {track} = useEventTrack();

    const [order, setOrder] = useState<string>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [search, setSearch] = useState(defaultSearch);

    const debounceSetSearch = useDebounceCallback(setSearch, 300);

    const getFilterParams = useMemo(() => ({order, orderBy, search}), [order, orderBy, search]);

    const params = useMemo(
        () => ({
            ...getFilterParams,
            setOrder,
            setOrderBy,
            setSearch: debounceSetSearch,
        }),
        [debounceSetSearch, getFilterParams],
    );

    const dataSource = useDataSource({
        addFn: ({
            org,
            channelName,
            projectId,
        }: {
            org: string;
            channelName: string;
            projectId: string;
        }) => TargetedDataService.createLinkedinChannel({org, channelName, projectId}),
        getFn: () => TargetedDataService.getLinkedinChannels(),
        getFilter: (data) => {
            return {
                ...data,
                items: _orderBy(
                    data.items.filter((item) =>
                        item.name.toLowerCase().includes(getFilterParams.search),
                    ),
                    [orderBy],
                    [order as 'asc' | 'desc'],
                ),
            };
        },
        isGetEnabled,
        onAddSuccess: () => {
            track('targetedDataSourceAdded', {type: 'Linkedin Page'});
        },
        onRemoveSuccess: () => {
            track('targetedDataSourceDeleted', {type: 'Linkedin Page'});
        },
        removeFn: (item: LinkedinChannelItem) =>
            TargetedDataService.deleteLinkedinChannel({
                channelId: item.id,
                projectId: item.projectId,
            }),
        type: queryKeys.LINKEDIN_CHANNELS,
    });

    const active = useMemo(
        () => (dataSource.data as LinkedinChannelItem[])?.filter(({active}) => active) || [],
        [dataSource.data],
    );
    const getActiveByProject = useCallback(
        (projectId: string) => active?.filter((item) => item.projectId === projectId),
        [active],
    );

    return {
        ...dataSource,
        active,
        getActiveByProject,
        onExplore: (item: LinkedinChannelItem) => {
            dispatch(push(getSearchResultsUrl(item)));
        },
        params,
    };
};

const useFacebookUserAuthentications = ({
    isGetEnabled = true,
    defaultOrder = 'asc',
    defaultOrderBy = 'facebookUserName',
    defaultSearch = '',
    defaultSize = PAGE_SIZE,
} = {}) => {
    const clientId = useAppSelector((state) => state.user.account!.client.id);
    const facebookAppId = useAppSelector((state) => state.user.settings.FB_APP_ID);

    const [order, setOrder] = useState<string>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [search, setSearch] = useState<string>(defaultSearch);

    const debounceSetSearch = useDebounceCallback(setSearch, 300);

    const getParams = useMemo(
        () => ({order, orderBy, search, size: defaultSize}),
        [defaultSize, order, orderBy, search],
    );

    const params = useMemo(
        () => ({
            ...getParams,
            setOrder,
            setOrderBy,
            setSearch: debounceSetSearch,
        }),
        [debounceSetSearch, getParams],
    );
    // TODO: This should store the user token and then user the token to add authentications
    // TODO: This should also be used in useFacebookLogin

    const dataSource = useDataSource({
        addFn: ({accessToken}: {accessToken: string}) =>
            FacebookTokenService.storeUserToken(clientId.toString(), accessToken, facebookAppId),
        getFn: ({pageParam = 0}) =>
            TargetedDataService.getFacebookUserAuthentications({...getParams, page: pageParam}),
        getParams,
        isGetEnabled,
        removeFn: (item: AuthenticationItem) =>
            TargetedDataService.deleteFacebookUserAuthentication(item.id),
        type: queryKeys.FACEBOOK_USER_AUTHENTICATIONS,
    });

    return {...dataSource, onExplore: null, params};
};

const useLinkedinAuthentications = ({isGetEnabled = true} = {}) => {
    const isLinkedinEnabled = useLinkedinIsEnabled();
    isGetEnabled = isLinkedinEnabled && isGetEnabled;

    const auths = useQuery({
        enabled: isGetEnabled,
        queryKey: [queryKeys.LINKEDIN_CHANNEL_AUTHENTICATIONS],
        queryFn: () =>
            TargetedDataService.getLinkedinAuthentications().then((response) =>
                formatLinkedinAuthentications(response),
            ),
    });

    const authentications = useMemo(
        () => (auths.isSuccess && auths?.data) || [],
        [auths.isSuccess, auths?.data],
    );

    return {
        authentications,
        error: auths.isError,
        loaded: auths.isFetched,
        loading: auths.isLoading,
        onExplore: null,
    };
};

const useLinkedinAuthenticationsV2 = ({
    isGetEnabled = true,
    defaultOrder = 'asc',
    defaultOrderBy = 'name',
    defaultSearch = '',
    defaultSize = PAGE_SIZE,
} = {}) => {
    const [order, setOrder] = useState<string>(defaultOrder);
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);
    const [search, setSearch] = useState<string>(defaultSearch);

    const getParams = {order, orderBy, search, size: defaultSize};
    const params = {
        ...getParams,
        setOrder,
        setOrderBy,
        setSearch,
    };

    const dataSource = useDataSource({
        addFn: ({accessToken}: {accessToken: string}) =>
            Promise.reject(new Error('add (mutation) not supported by linkedin in useDataSource')),
        getFn: ({pageParam = 0}) => TargetedDataService.getLinkedinAuthentications(),
        //getParams, //prevent re-fetch
        isGetEnabled,
        removeFn: (item: any) =>
            Promise.reject(
                new Error('remove (mutation) not supported by linkedin in useDataSource'),
            ),
        type: queryKeys.LINKEDIN_CHANNEL_AUTHENTICATIONS,
        getFilter: (data) => ({
            ...data,
            items: _orderBy(
                data.items.filter((item) => item.name.toLowerCase().includes(search)),
                [orderBy],
                [order as 'asc' | 'desc'],
            ),
        }),
    });

    return {...dataSource, onExplore: null, params};
};

export {
    useDataSource,
    useFacebookPages,
    useInstagramAccounts,
    useInstagramHashtags,
    useLinkedinChannels,
    useFacebookUserAuthentications,
    useLinkedinAuthentications,
    useLinkedinAuthenticationsV2,
};
