import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

export type SimpleMenuStyleParams = {
    selectedItemLbL: string;
};

export default makeStyles<Theme, SimpleMenuStyleParams>((theme) => ({
    title: {
        fontFamily: theme.font?.name,
        fontWeight: theme.font?.bold,
        fontSize: '14px',
        textTransform: 'uppercase',
        textAlign: 'left',
        padding: isV2(theme) ? '12px 24px 0' : '14px 12px 0',
    },
    item: {
        position: 'relative',

        '&.Mui-selected:after': {
            //'&:after': {
            ...theme.mixin.offscreen,
            content: ({selectedItemLbL}) => `"${selectedItemLbL}"`,
        },
    },
    icon: {
        position: 'absolute',
        top: '50%',
        right: isV2(theme) ? '24px' : '12px',
        transform: 'translate(0, -50%)',
        fontSize: 0, //ensures correct centering of inline-block item
    },
    text: {
        paddingRight: isV2(theme) ? '26px' : '36px',
    },
    ellipsisIcon: {
        width: isV2(theme) ? '18px' : '24px',
        height: isV2(theme) ? '18px' : '24px',
    },
    disabledWithTooltip: {
        '&&': {// workaround to increase specificity
            pointerEvents: 'unset',
        }
    },
}));
