import {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';

// Components
import {List, ListItemButton, ListItemText} from '@mui/material';
import Drawer from 'hsi/components/Drawer';
import Select from 'hsi/components/Select';
import ToggleDrawerButton from 'hsi/components/ToggleDrawerButton';
import Tooltip from 'hsi/components/Tooltip';
import Heading, {HeadingContents} from 'hsi/components/aria/Heading';

// Types
import {SortOption} from '../types';
import {SnapshotList} from 'hsi/types/snapshots';

// Hooks
import useDates from 'hsi/hooks/useDates';

// Utils
import {T} from 'hsi/i18n';
import {SORT_OPTIONS, sortSnapshots} from '../utils';

// Styles
import useStyles from './styles';

interface ISharedDashboardSidebar {
    currentSnapshotId: string;
    snapshots: SnapshotList;
    isSidebarOpen: boolean;
    handleToggleDrawer: () => void;
}

const SharedDashboardSidebar = ({
    currentSnapshotId,
    snapshots,
    isSidebarOpen,
    handleToggleDrawer,
}: ISharedDashboardSidebar) => {
    const [sortBy, setSortBy] = useState<SortOption['value']>('snapshot_date_desc');
    const dates = useDates();
    const classes = useStyles();

    const sortedSnapshots = useMemo(() => sortSnapshots(snapshots, sortBy), [snapshots, sortBy]);

    const tooltipContent = useMemo(
        () => <div>{T(`sharedDashboard.${isSidebarOpen ? 'hideSidebar' : 'showSidebar'}`)}</div>,
        [isSidebarOpen],
    );

    const shouldRenderSnapshots = isSidebarOpen && sortedSnapshots.length > 0;

    return (
        <Drawer
            as="section"
            aria-label={T('sharedDashboard.drawer')}
            anchor="left"
            open={isSidebarOpen}
            widthType={'filters'}
            fixed
        >
            {() => (
                <>
                    <div className={classes.header}>
                        {isSidebarOpen && <Heading>{T('sharedDashboard.header')}</Heading>}
                        <Tooltip tooltip={tooltipContent}>
                            <ToggleDrawerButton
                                className={classes.toggleFiltersButton}
                                open={isSidebarOpen}
                                toggleDrawer={handleToggleDrawer}
                                openLabel={T('sharedDashboard.showSidebar')}
                                closeLabel={T('sharedDashboard.hideSidebar')}
                                collapsedIcon="hsi-double-arrow-right"
                                drawerSide="left"
                            />
                        </Tooltip>
                    </div>

                    <HeadingContents>
                        {shouldRenderSnapshots && (
                            <nav aria-label="Reports">
                                <div className={classes.select}>
                                    <label htmlFor="sort-reports-select">
                                        {T('sharedDashboard.sortBy')}
                                    </label>
                                    <Select
                                        id="sort-reports-select"
                                        value={sortBy}
                                        onChange={(e) =>
                                            setSortBy(e.target.value as SortOption['value'])
                                        }
                                    >
                                        {SORT_OPTIONS.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                                <List>
                                    {sortedSnapshots?.map((snapshot) => (
                                        <li key={snapshot.id}>
                                            <ListItemButton
                                                selected={currentSnapshotId === snapshot.id}
                                                component={Link}
                                                to={`/shared-dashboard/${snapshot.id}`}
                                                aria-current="page"
                                            >
                                                <ListItemText
                                                    primary={`${snapshot.name} | ${dates.formatTo(
                                                        snapshot.creation_date,
                                                        'MMM d, h:mm a ZZZZ',
                                                    )}`}
                                                    primaryTypographyProps={{
                                                        className:
                                                            currentSnapshotId === snapshot.id
                                                                ? classes.selected
                                                                : undefined,
                                                    }}
                                                />
                                            </ListItemButton>
                                        </li>
                                    ))}
                                </List>
                            </nav>
                        )}
                    </HeadingContents>
                </>
            )}
        </Drawer>
    );
};

export default SharedDashboardSidebar;
