import {makeStyles} from '@mui/styles';
import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        justifyContent: 'space-between',
        height: '84px',
    },
    select: {
        padding: '8px',
    },
    drawer: {
        height: 'auto',
    },
    toggleFiltersButton: {
        width: '32px',
        height: '32px',
    },
    selected: {
        fontWeight: theme.font.bold,
    },
    relative: {
        position: 'relative'
    }
}));
