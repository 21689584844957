import useDataSourceCount from 'hsi/hooks/useDataSourceCount';
// Types
import {QueryContextType} from './query';
import {TimezoneID} from 'hsi/utils/timezones';
import {CCFlagsType} from './cc-flags';

// Consts
import {EMOTIONS_CATEGORIES, GENDERS, SENTIMENT_CATEGORIES} from 'hsi/constants/config';
import {Writable} from 'type-fest';

/**
 * @description All possible pageType values - may not all be available for a given integration
 * */
export type AllPageTypes =
    | 'twitter'
    | 'facebook'
    | 'facebook_public'
    | 'instagram'
    | 'instagram_public'
    | 'tumblr'
    | 'forum'
    | 'youtube'
    | 'reddit'
    | 'news'
    | 'blog'
    | 'review'
    | 'qq'
    | 'linkedin'
    | 'bluesky';

export type SentimentType = (typeof SENTIMENT_CATEGORIES)[number];
export type EmotionsType = (typeof EMOTIONS_CATEGORIES)[number];
export type GendersType = (typeof GENDERS)[number];

export type ContentSources =
    | 'forum'
    | 'youtube'
    | 'news'
    | 'qq'
    | 'twitter'
    | 'review'
    | 'facebook'
    | 'facebook_public'
    | 'reddit'
    | 'tumblr'
    | 'instagram'
    | 'instagram_public'
    | 'blog'
    | 'bluesky';

export type AppSource = 'BW' | 'HSI' | 'C3' | 'NGC3' | 'LUQI' | 'FALCON' | 'C1';

export type HttpVerbs = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';

export type BCRApiError = {
    code: number;
    message: string;
};

export type BCRApiErrorResponse = {
    errors: BCRApiError[];
};

export function isBCRApiError(obj: unknown): obj is BCRApiErrorResponse {
    return (obj as any)?.errors instanceof Array;
}
export interface PageType {
    iconName: string;
    value: AllPageTypes;
    label: string;
}

export interface PageTypeDefinitionType extends Omit<PageType, 'label'> {
    isAvailable?: (
        targetedDataSources: ReturnType<typeof useDataSourceCount>,
        flags?: CCFlagsType,
        isSavedSearch?: boolean, //isSavedSearch includes editing a saved search
        projectId?: number,
    ) => boolean;
}

export type LangCodeType = string; //TODO better typing?

export interface ProjectType {
    billableClientId: number;
    billableClientIsPitch?: boolean;
    billableClientName: string;
    description: string;
    id: number;
    name: string;
    timezone: TimezoneID;
    dataAnonymizationEnabled?: boolean;
}

export const BCRQueryTypes = ['monitor', 'twitter', 'publicfacebook', 'instagram'] as const;
export type BCRQueryType = (typeof BCRQueryTypes)[number];

export interface BCRQuery {
    active: boolean;
    audienceLists: any; // unknown type (null is a known value)
    booleanQuery: string;
    contentSources: ContentSources[];
    createdByUsername: string;
    createdByWizard?: false;
    creationDate: ISO8601String; //ISO8601
    description: string | null;
    displayName: string | null;
    exclusionSnippets: number[]; // Array of IDs (https://developers.brandwatch.com/docs/exclusion-snippets) - not used within Listen
    highlightTerms: string[];
    id: number;
    imageFilter: any; //unknown type (null is known value)
    languages: any[]; //array of language ids (https://developers.brandwatch.com/docs/languages-and-timezones#languages)
    lastModificationDate: ISO8601String; //ISO8601 date string
    lastModifiedUsername: string;
    locationFilter: any; //unknown type (null is known value)
    lockedByUsername: string | null;
    lockedQuery: boolean;
    lockedTime: string | null;
    monitorId?: number;
    name: string;
    percentComplete: number;
    projectId: number;
    queryLimitUsage: number;
    samplePercentage: number | null;
    sampled: boolean;
    sentimentClassifierVersion: number;
    startDate: ISO8601String; //ISO8601 date string
    trackedPageFilter: any; //unknown type (null is known value)
    trendsEnabled: boolean;
    type: BCRQueryType;
    userRequestedSampling: boolean;
    state: string; //probably a limited set of values, but I don't know them (includes 'active')
}

export interface SavedSearchType extends BCRQuery {
    project: ProjectType;
}

export type SearchType = 'saved' | 'quick';
export type SearchTypeStreams = 'saved' | 'quick' | 'streams';
export type QueryType = SearchType | 'edit';

export type SearchStateType = any; //TODO

export type LinkedinChannelIdsType = number[];

export type CardRuleArgs = {
    queryContext: Partial<QueryContextType>;
    flags: CCFlagsType;
};

export type ExportMentionFormatType = {
    enabled: boolean;
    min: number;
    defaultNum: number;
    max: number;
};

//Chips
export type ChipType<TValue> = {
    //Any of these might be optional?
    actionType: string; //An icon name?
    include: boolean;
    key: number | string;
    label: React.ReactNode; //Might be string?
    value: TValue;
};

//ARIA stuff
export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

//Can't really do better than this sadly: https://gist.github.com/MrChocolatine/367fb2a35d02f6175cc8ccb3d3a20054
//But at least using this type marks a prop as being a date
/**
 * Indicates the format of this string, but is only enforced as a string
 */
export type ISO8601String = string;

//Shared generic interfaces
export interface AsyncResultsType<T> {
    loading: boolean;
    loaded: boolean;
    error: boolean | null; //??
    results: T[];
}

export interface IdObject<Key> {
    [index: string]: any;
    id: Key;
}

export type Nullable<Type> = {
    [Property in keyof Type]: Type[Property] | null;
};

//Use this to make a new Type, based on an existing type where all the props except those listed in K are set
//to optional e.g. PartialExcept<SavedSearchType, "id" | "name"> would make a new type where id and name are still
//required, but all other props are optional
export type PartialExcept<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>;

export type PartialProps<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

//Use to get the keys of properties with a specific type V from T
//e.g. KeyOfType<T, string> would return a union type of all the properties of T with the type 'string'
export type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: any;
};

//Very similar to above, except builds the new type, rather than just getting the keys
export type PickByType<T, ValueType> = Pick<
    T,
    {[Key in keyof T]-?: T[Key] extends ValueType ? Key : never}[keyof T]
>;

export type OmitByType<T, OmitType> = Omit<T, KeyOfType<T, OmitType>>;

export type RemoveNever<T> = OmitByType<T, never>;

export type FilterKeys<T extends object, TKeyExtends> = RemoveNever<{
    [K in keyof T]-?: K extends TKeyExtends ? T[K] : never;
}>;

//Used to get a type which includes the values of a type.
//Needs to be combined with 'as const' for object/array literals
//e.g.
//const myObj = {a: 'hello', b: 'world', c: 3}
//type X = ValueOf<typeof myObj>;// 'hello' | 'world' | 3
export type ValueOf<T> = T[keyof T];

//Specific version for arrays, excludes array props e.g. length, etc
export type ValueOfArray<T> = ValueOf<Omit<T, keyof Array<any>>>;

//Types for polymorphic components
//https://blog.logrocket.com/build-strongly-typed-polymorphic-components-react-typescript/
type AsProp<C extends React.ElementType> = {
    as?: C;
};

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

// This is the type for the "ref" only
export type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];
//Functionally like S & T, but gives better code hints {a: any, b: any} instead of {a: any} & {b: any}
export type NiceIntersection<S, T> = {[K in keyof (S & T)]: (S & T)[K]};

//Merges types to the left, so the rightmost type takes precedence
export type MergeTypesLeft<T1, T2> = NiceIntersection<
    {
        [Property in keyof T1 as Exclude<Property, keyof T2>]: T1[Property];
    },
    T2
>;

//Used to remove index of type TIndex from type T, e.g. RemoveIndex<{[key: string]: any, foo: string}> => {foo: string}
export type RemoveIndex<T, TIndex = string> = {
    [K in keyof T as TIndex extends K ? never : K]: T[K];
};

//Get props of T1 that aren't in T2
export type Diff<T1, T2> = Omit<T1, keyof T2>;

// This is the first reusable type utility we built
export type PolymorphicComponentProp<
    C extends React.ElementType,
    Props = {},
> = React.PropsWithChildren<Props & AsProp<C>> &
    Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

// This is a new type utitlity with ref!
export type PolymorphicComponentPropWithRef<
    C extends React.ElementType,
    Props = {},
> = PolymorphicComponentProp<C, Props> & {ref?: PolymorphicRef<C>};

//Use this if you want a type that can be readonly or not
export type MutableOrImmutable<T> = Writable<T> | Readonly<T>;

/**
 * Merge two types together, making any props only present in one of the types optional.
 * For props with the same name but different types, create a union type
 */
export type OptionalMerge<T1, T2> = Partial<Pick<T1, Exclude<keyof T1, keyof T2>>> &
    Partial<Pick<T2, Exclude<keyof T2, keyof T1>>> & {
        [P in keyof (T1 | T2)]: T1[P] | T2[P];
    };

/**
 * Takes a type of a object where each value is an array, and returns a type where each value must be a value allowed in that array,
 * e.g. {a: number[], b: [boolean, string]} becomes {a: number, b: boolean | string}
 */
export type MapObjValuesToValue<T extends Record<any, any[] | Readonly<any[]>>> = {
    [P in keyof T]: T[P][number];
};
