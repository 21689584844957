import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

const MAX_WIDTH = '800px';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        createPDFExport: {
            color: theme.colors.textDefault,
            fontSize: '16px',

            '& .MuiDialog-paper': {
                height: 'auto',
                maxWidth: MAX_WIDTH,
                flex: `1 1 ${MAX_WIDTH}`,
                overflowY: 'visible',
            },
        },
        content: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '16px',
        },
        title: {
            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                      fontSize: '17px',
                      fontWeight: 'bold',
                  }),
            paddingBottom: '15px',
            margin: v2 ? 0 : undefined,
        },
        copy: {
            ...(v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
            paddingBottom: '10px',
        },
        clear: {
            marginRight: 'auto',
            color: theme.colors.primaryBlue100,
        },

        cardSection: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '16px',

            padding: v2 ? '16px' : undefined,
            borderRadius: v2 ? '2px' : undefined,
            background: v2 ? theme.colors.lightGrey20 : undefined,
        },

        cardWrapper: {
            pointerEvents: 'none',
        },
        dateRange: {
            fontWeight: v2 ? theme.fonts.bodyNormal.fontWeight : 700,
            fontSize: v2 ? theme.fonts.bodyNormal.fontSize : '16px',
        },
        time: {
            color: '#000',
        },
        primaryCta: {
            color: theme.colors.primaryBlue100,
        },

        passwordGeneratorDialog: {
            '& .MuiDialog-paper': {
                maxWidth: '600px',
                flex: '1 1 600px',
            },
        },
    };
});
