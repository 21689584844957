import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        validationRules: {
            ...(v2
                ? theme.fonts.bodyNormal
                : {
                      fontSize: '16px',
                  }),
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridRowGap: '10px',
        },
        rule: {
            display: 'flex',
            alignItems: 'center',
        },
        checkIcon: {
            fill: theme.colors.positive,
        },
        crossIcon: {
            fill: theme.colors.negative,
        },
    };
});
