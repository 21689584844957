import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';


export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        clearField: {
            ...theme.mixin.blankButton,
            ...(v2
                ? theme.fonts.bodyStrong
                : {
                      fontSize: '16px',
                      fontWeight: theme.font.bold,
                  }),

            color: v2 ? theme.colors.textHyperlink : theme.colors.secondaryBlue100,
        },
        helperText: {
            display: 'flex',
            alignItems: 'center',
            gap: 4,
        },
        warning: {
            display: v2 ? 'none' : 'flex',
            alignItems: 'center',
            gap: 4,
        },
        warningIcon: {
            fill: theme.colors.primaryRed100,
            width: '21px',
            height: '21px',
            padding: '5px',
            backgroundColor: theme.colors.primaryRed40,
            borderRadius: '50%',
        },
        characterLimit: {
            flex: '1 0 auto',
            textAlign: 'right',
            color: theme.colors.textDefault,
        },
    };
});
