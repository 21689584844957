import React, {useMemo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';
import startCase from 'lodash/startCase';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import BenchmarkBarChart from 'hsi/components/BenchmarkBarChart';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useElementSize from 'hsi/hooks/useElementSize';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useBenchmarkSubtitle from 'hsi/hooks/useBenchmarkSubtitle';
import useDates from 'hsi/hooks/useDates';
import { useGetCardBreakdownSetter, useGetCurQueryCardBreakdown, getCardBreakdownOptions } from 'hsi/utils/cards/breakdowns';
import useQueryId from 'hsi/hooks/useQueryId';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
const DRILLS = {
    pageTypes: 'pageType',
    sentiment: 'sentiment',
    emotions: 'classifications',
    interest: 'interest',
    languages: 'language',
    countries: 'location',
};
const LIMIT = 10;
const type = 'benchmark';

//The components
export const BenchmarkCard = React.forwardRef(function Benchmark({title, ...props}, ref) {
    const classes = useStyles();
    const {
        links: {dashboardInfoBenchmarkCTA: popupCTA},
    } = useConfig();
    const [sizeRef, {height, width} = {}] = useElementSize(null, {width: true, height: true});
    const {trackWithSearchData} = useEventTrack();

    //Redux
    const dispatch = useDispatch();
    const isDrilledIn = useSelector((state) => state.mentions.drillInCard === type);
    const {data: _data, loading, loaded, error} = useSelector((state) => state.chart.benchmark);
    const breakdown = useGetCurQueryCardBreakdown(type);
    const {startDate, endDate} = useSelector((state) => state.filters.dateRange);
    const _loadData = useGetLoadData(type);

    const {hasPreviousValue, benchmarkSubHeader} = useBenchmarkSubtitle(startDate, endDate);
    const {
        getPreviousPeriod,
    } = useDates();
    const {
        startDate: prevStartDate,
        endDate: prevEndDate,
    } = getPreviousPeriod(startDate, endDate);
    //Memod data
    const loadData = useCallback(() => _loadData(true), [_loadData]);
    const data = useMemo(
        () => (!Array.isArray(_data?.categories) ? [] : _data.categories.slice(0, LIMIT)),
        [_data],
    );

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    const setCardBreakdown = useGetCardBreakdownSetter(useQueryId(), type);

    //Callbacks
    const updateCardBreakdown = useCallback(
        (breakdown) => {
            trackWithSearchData('cardCustomized', {
                breakdown,
                type,
            });
            setCardBreakdown(breakdown);
            loadData();
        },
        [trackWithSearchData, setCardBreakdown, loadData],
    );

    const drillIn = useCallback((dateType) =>
        ({payload: drillIn}) => {
            function getDrillInValue() {
                switch (breakdown) {
                    case 'languages':
                    case 'countries':
                        return {
                            activeModeIsInclude: true,
                            values: [{id: drillIn.id, fullName: drillIn.name}],
                        };
                    case 'interest':
                        return [drillIn.id];
                    default:
                        return {[drillIn.id]: true};
                }
            }

            const drillinFilter = {
                [DRILLS[breakdown]]: getDrillInValue(),
            };

            const drillinName = ['countries', 'interest'].includes(breakdown)
                ? drillIn.name
                : startCase(drillIn.name);

            trackWithSearchData('cardDrilledIn', {
                breakdown,
                value: drillinName,
                type,
            });

            let dateRange = null;
            if (dateType === 'previous') {
                dateRange = {
                    startDate: prevStartDate,
                    endDate: prevEndDate,
                };
            }

            dispatch(mentionsDrillIn(drillinFilter, type, dateRange, drillinName));
        },
        [breakdown, dispatch, prevStartDate, prevEndDate, trackWithSearchData],
    );

    const content = useMemo(
        () => (
            <div className={classes.chartContainer}>
                <div className={cn('chart', 'catSize' + data.length)} ref={sizeRef}>
                    {hasPreviousValue && (
                        <BenchmarkBarChart
                            barSize={24}
                            breakdown={breakdown}
                            data={data}
                            height={height}
                            onClick={drillIn}
                            width={width}
                        />
                    )}
                </div>
            </div>
        ),
        [
            breakdown,
            classes.chartContainer,
            data,
            drillIn,
            height,
            sizeRef,
            width,
            hasPreviousValue,
        ],
    );

    const hasData = !!data && data.length > 0;

    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle
                    breakdown={breakdown}
                    breakdownOptions={getCardBreakdownOptions(type)}
                    breakdownPrefix="cards.breakdownFor"
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    updateBreakdown={updateCardBreakdown}
                    hasData={hasData}
                />
            }
            subTitle={hasPreviousValue ? benchmarkSubHeader : null}
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            selected={isDrilledIn}
            loadData={loadData}
            ref={ref}
            data-testid={type}
            type={type}
            notAvailablePerDataLimitation={!hasPreviousValue}
        >
            {content}
        </CardLoadState>
    );
});
