import {useMemo} from 'react';

import useConfig from './useConfig';
import useDataSourceCount from 'hsi/hooks/useDataSourceCount';
import useFlags from 'hsi/hooks/useFlags';
import useQueryContext from './useQueryContext';

import {PageType, PageTypeDefinitionType} from 'hsi/types/shared';
import {T} from 'hsi/i18n';
import {ReadonlyDeep} from 'type-fest';

//The hook
export default function usePageTypes(isSavedSearch: boolean, projectId: number) {
    const flags = useFlags();
    const {_basePageTypes: pageTypeConfig} = useConfig();
    const targetedDataAvailability = useDataSourceCount(projectId);

    return useMemo(
        () =>
            getPageTypesFromConfig(
                pageTypeConfig,
                targetedDataAvailability,
                flags,
                isSavedSearch,
                projectId,
            ),
        [pageTypeConfig, targetedDataAvailability, flags, isSavedSearch, projectId],
    );
}

export function useActiveQueryPageTypes() {
    const {isSavedSearch, isEditSearch, projectId} = useQueryContext();

    return usePageTypes(!!isSavedSearch || !!isEditSearch, projectId as number);
}

//Internal helpers
function getPageTypesFromConfig(
    config: ReadonlyDeep<PageTypeDefinitionType[]>,
    targetedData: ReturnType<typeof useDataSourceCount>,
    flags: ReturnType<typeof useFlags>,
    isSavedSearch: boolean,
    projectId: number,
): PageType[] {
    return config.reduce<PageType[]>((output, {isAvailable, ...pageType}) => {
        //if isAvailable is not set, assume = true.
        //in which case, execute the function, and use the result
        if (
            isAvailable === undefined ||
            isAvailable(targetedData, flags ?? {}, isSavedSearch, projectId)
        ) {
            output.push({...pageType, label: T(`pageTypes.${pageType.value}`)});
        }
        return output;
    }, []);
}
