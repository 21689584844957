import { useState } from 'react';

// Hooks
import { useAppDispatch } from 'hsi/hooks/useRedux';
import { guestAuth } from 'hsi/slices/sharedDashboard/thunks';
import { IAuthDialog } from '.';

interface IUseAuthDialogLogic extends IAuthDialog {}

export const useAuthDialogLogic = ({snapshotId, onAuthenticated}: IUseAuthDialogLogic) => {
    const dispatch = useAppDispatch();
    const [password, setPassword] = useState<string>('');
    const [loadingAuth, setLoadingAuth] = useState<boolean>(false);
    const [authError, setAuthError] = useState({hasError: false, message: ''});

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (authError.hasError) {
            setAuthError({hasError: false, message: ''});
        }

        setPassword(e.target.value);
    };

    const submitHandler = async () => {
        setLoadingAuth(true);

        try {
            await dispatch(guestAuth({snapshotId, password})).unwrap();
            onAuthenticated();
        } catch (error) {
            setAuthError({hasError: true, message: error as string});
        } finally {
            setLoadingAuth(false);
        }
    };

    const onPressEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const {key} = event;
        if (key === 'Enter' && password.length) {
            submitHandler();
        }
    };

    return {
        loadingAuth,
        handlePasswordChange,
        onPressEnter,
        password,
        submitHandler,
        authError
    };
};
