import makeStyles from '@mui/styles/makeStyles';

import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => {
    return {
        visibleIcon: {
            display: 'flex',
            cursor: 'pointer',
            border: 'none',
            padding: 0,
            minWidth: 'inherit',
            '&:hover': {
                border: 'none',
                backgroundColor: theme.colors.white,
            },
        },
    };
});
