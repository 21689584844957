import find from 'lodash/find';
import {replace} from 'redux-first-history';
import {
    LOG_IN,
    LOG_OUT,
    LOG_IN_LOADING,
    SET_USED_QUERIES,
    SET_USER_LINK_PENDING,
    SET_PACKAGE_USAGE,
    SET_LOGIN_LOADING_MSG,
    OPEN_IMPERSONATION_DIALOG,
    SET_IMPERSONATION_LOADING,
    REPLACE_USER_STATE
} from '../constants/actionTypes';

import userService from '../services/userService';

import {loadSearches} from './savedSearchActions';
import {loadRecentQueries} from 'hsi/actions/queryActions';

import {LOGIN_DEBOUNCE_MS, MAX_LOGIN_ATTEMPTS} from 'hsi/constants/config';
import getConfig from 'hsi/config';

import {T} from 'hsi/i18n';
import {showNotification} from './notificationsActions';

let loginAttempts = 0;

export const setLoginLoading = (val) => ({
    type: LOG_IN_LOADING,
    payload: val,
});

export const updateUsedQueries = (clientId) => async (dispatch, getState) => {
    clientId = clientId || getState().user?.account?.client?.id;
    if (!clientId) return;

    try {
        const clientUseData = await userService.clientUseData(clientId);
        dispatch({
            type: SET_USED_QUERIES,
            payload: clientUseData,
        });
    } catch (error) {
        console.log(error);
    }
};

export const loginError = (error) => ({
    type: LOG_IN,
    payload: {
        loginLoading: false,
        isLoggedIn: false,
        projects: [],
        account: null,
        errors: error?.body?.errors || null,
        errorCode: error?.body?.code || null,
        status: error?.body?.status || 401,
    },
});

const processLogin =
    (loginResp, loadHSIExtras = true) =>
    async (dispatch) => {
        const {appSource} = getConfig();

        if (loginResp.user.defaultProjectId === -1 && loginResp.projects.length === 0) {
            const err = new Error('no default project id');
            err.body = {
                errors: [T('noDefaultProjectError')],
                code: 'NO_DEFAULT_PROJECT_ID',
            };
            throw err;
        }

        const defaultProject =
            find(loginResp.projects, (p) => p.id === loginResp.user.defaultProjectId) ||
            loginResp.projects[0];

        await dispatch({
            type: LOG_IN,
            payload: {
                loginLoading: false,
                isLoggedIn: loginResp.isLoggedIn,
                projects: loginResp.projects,
                account: loginResp.user,
                settings: loginResp.settings,
                defaultProject: defaultProject,
                isViewOnly: loginResp.user.uiRole === 'view',
                canSaveSearch: ['regular', 'admin', 'superadmin'].includes(loginResp.user.uiRole),
                linkPending: false,
                sessionStart: loginResp.sessionStart,
                canImpersonate: loginResp.canImpersonate,
                isImpersonated: loginResp.isImpersonated,
            },
        });
        if (loginResp.notifyUserLinkCreated) {
            dispatch(
                showNotification({
                    message: T('notifyUserBeenLinked', {clientName: loginResp.user.client.name}),
                    variant: 'info',
                    markdown: true,
                }),
            );
        }

        dispatch(loadRecentQueries(loginResp.user.id));

        if (appSource !== 'BCR') {
            await Promise.all([
                loadHSIExtras && dispatch(updateUsedQueries(loginResp.user.client.id)),
                dispatch(loadSearches()),
            ]);
        }
    };

export const logIn =
    (params = {}, loadHSIExtras = true) =>
    async (dispatch) => {
        try {
            const loginResp = await userService.logIn(params);

            if (loginResp.provisioningState === 'in_progress') {
                dispatch({
                    type: SET_LOGIN_LOADING_MSG,
                    payload: T(
                        loginResp.op === 'create'
                            ? 'loginUserIsProvisioning'
                            : 'loginUserIsUpdating',
                    ),
                });
                dispatch(logIn(params, loadHSIExtras));
                return;
            } else if (loginResp.status === 'link_pending') {
                dispatch({
                    type: SET_USER_LINK_PENDING,
                    payload: true,
                });
                return;
            }
            await dispatch(processLogin(loginResp, loadHSIExtras));
        } catch (e) {
            // attempt to login again on rate limit after 3 secs
            if ([409, 425, 429].includes(e.status) && loginAttempts < MAX_LOGIN_ATTEMPTS) {
                loginAttempts++;
                setTimeout(() => {
                    dispatch(logIn(params, loadHSIExtras));
                }, LOGIN_DEBOUNCE_MS);
            } else {
                dispatch(loginError(e));
            }
        }
    };

export const logOut = () => async (dispatch) => {
    dispatch(setLoginLoading(true));
    userService
        .logOut()
        .then(() => dispatch({type: LOG_OUT}))
        .catch((e) => console.error('logout error', e))
        .finally(() => {
            dispatch(setLoginLoading(false));
            dispatch(replace('/'));
            document.location.reload();
        });
};

export const linkUser =
    (params = {}) =>
    async (dispatch) => {
        try {
            const loginResp = await userService.linkUser(params);
            await dispatch(processLogin(loginResp));
        } catch (e) {
            dispatch(loginError(e));
        }
    };

export const loadPackageUsage = () => async (dispatch) => {
    userService
        .getPackageUsage()
        .then((data) => {
            dispatch({
                type: SET_PACKAGE_USAGE,
                payload: data,
            });
        })
        .catch((e) => {
            dispatch({
                type: SET_PACKAGE_USAGE,
                payload: {error: true},
            });
        });
};

export const openImpersonationDialog = (payload) => ({type: OPEN_IMPERSONATION_DIALOG, payload});

export const setImpersonationLoading = (payload) => ({type: SET_IMPERSONATION_LOADING, payload});

export const impersonateUser = (params) => async (dispatch) => {
    try {
        dispatch(setImpersonationLoading(true));
        const {body: resp} = await userService.impersonate(params);
        if (resp.jwt && typeof document !== 'undefined') {
            const baseUrl = document.location.protocol + '//' + document.location.host;
            document.location.href = `${baseUrl}?apiAuthorization=${resp.jwt}`;
        } else {
            throw new Error('No JWT in response from server');
        }
    } catch (err) {
        dispatch(
            showNotification({
                variant: 'warning',
                message:
                    err.status === 404
                        ? 'User not found'
                        : 'Error while impersonating. Check console output.',
            }),
        );
        console.error('Error while impersonating', err);
        dispatch(openImpersonationDialog(false));
        dispatch(setImpersonationLoading(false));
    }
};

// Used in shared dashboard to replace the whole state with stored report state
export const replaceUserState = (newState) => ({
    type: REPLACE_USER_STATE,
    payload: newState
  });