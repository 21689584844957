import http from '../../classes/Http';
import { ApiResponse } from 'hsi/types/apiv2';
import { GuestAuthReponse } from 'hsi/types/sharedReports';
import { Snapshot } from 'hsi/types/snapshots';

/**
 * Authentication API for shared dashboards.
 * @param snapshotId UUID of snapshot - obtained from query parameter.
 * @param password 
 * @returns List of associated snapshots.
 */
export async function sharedDashboardGuestAuth(snapshotId: string, password: string) {
    const url = `/apiv2/shared-dashboards/guest-auth`;
    const params = {
        snapshotId,
        password,
    };
    return http.post<ApiResponse<GuestAuthReponse>>(url, params);
}

/**
 * Requires an authenticated session using sharedDashboardGuestAuth
 *
 * @param snapshotId
 */
export async function sharedDashboardGuestGetSnapshot(snapshotId: string) {
    const url = `/apiv2/shared-dashboards/snapshot/${snapshotId}`;
    return http.get<ApiResponse<Snapshot>>(url);
}
