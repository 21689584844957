//TODO SelectChartParams component does not return keyboard focus correctly when
//exiting popover menu
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import { MouseEventHandler, ReactNode, useCallback, useLayoutEffect, useMemo } from 'react';

//Components
import { useCardContext } from 'hsi/components/Card';
import HelpIcon from 'hsi/components/HelpIcon';
import IconRouter from 'hsi/components/IconRouter';
import NewBadge from 'hsi/components/NewBadge';
import ExportMenu from './ExportMenu';

//Hooks
import { useCardLoadStateContext } from 'hsi/components/Card/CardLoadState';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';
import useQueryContext from 'hsi/hooks/useQueryContext';
import useGetContextQueryId from 'hsi/hooks/useGetContextQueryId';
import { useGetQueryDataSetter } from 'hsi/hooks/useQueryData';
import { useHorizontalPositionedItemContext } from 'hsi/components/layout/HorizontalPositionedColumns/Item/context';
import useIsCardHidden from 'hsi/hooks/useIsCardHidden';
import useIsCardPinned from 'hsi/hooks/useIsCardPinned';

//Other
import SimpleMenu from 'hsi/components/SimpleMenu';
import { T } from 'hsi/i18n';
import { AggregateType, BreakdownType, CardTitleType, CardType } from 'hsi/types/cards';
import useStyles from './styles';
import Heading from 'hsi/components/aria/Heading';
import useFlags from 'hsi/hooks/useFlags';
import { cardHasBreakdown } from 'hsi/utils/cards/breakdowns';
import { cardHasAggregate } from 'hsi/utils/cards/aggregates';

type CardTitleArgs<T extends CardType> = {
    //Required props
    title: CardTitleType;
    type: T;
    //Breakdown props (optional - if searchType === 'saved' && updateParam != null )
    updateBreakdown?: Parameters<typeof SelectChartParams>[0]['updateChartParam'];
    breakdown?: BreakdownType;
    breakdownOptions?: string[];
    updateAggregate?: Parameters<typeof SelectChartParams>[0]['updateChartParam'];
    aggregate?: AggregateType;
    aggregateOptions?: string[];
    //config props,
    hasConfig?: boolean;
    onConfigClick?: Parameters<typeof IconButton>[0]['onClick'];
    //Tooltip props
    tooltipComponent?: Parameters<typeof HelpIcon>[0]['tooltip'];
    hasData?: boolean;
    hasNewBadge?: boolean;
};

//The component
export default function CardTitle<T extends CardType>({
    //Required props
    title,
    type,
    //Breakdown props (optional - if searchType === 'saved' && updateParam != null )
    updateBreakdown,
    breakdown,
    breakdownOptions,
    updateAggregate,
    aggregate,
    aggregateOptions,
    //config props,
    hasConfig = false,
    onConfigClick,
    //Tooltip props
    tooltipComponent: TooltipComponent,
    hasData = false,
    hasNewBadge = false,
}: CardTitleArgs<T>) {
    const classes = useStyles();
    const {hasDashboardPinAndHide} = useFlags();
    const {trackWithSearchData} = useEventTrack();
    const {isSavedSearch, isMultipleSearch} = useQueryContext();
    const cardLoadStateContext = useCardLoadStateContext();
    const loading = !!cardLoadStateContext?.loading;
    const horizontalPositionContext = useHorizontalPositionedItemContext();
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    const {isFocusIn, isPointerIn, headingId} = useCardContext();

    const isCardHidden = useIsCardHidden(type);
    const isCardPinned = useIsCardPinned(type);

    const hasBreakdown = !!(isSavedSearch && breakdown);
    
    const {mainTitle, subTitle, titleString} = useGetCardTitleParts(type, {
        title,
        aggregate,
        aggregateOptions,
        updateAggregate,
        breakdown,
        breakdownOptions,
        updateBreakdown,
        isCardHidden,
        isSavedSearch,
        loading,
        isCardInteractivityDisabled
    });

    const queryId = useGetContextQueryId();
    const queryDataSetter = useGetQueryDataSetter(queryId);
    const queryDataDashboardProp = isMultipleSearch 
        ? "multipleSearchDashboardCardsOptions" 
        : "dashboardCardsOptions" ;

    const onPinClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
        e.stopPropagation();
        
        queryDataSetter?.(queryDataDashboardProp, (curValue) => {
            trackWithSearchData(curValue?.[type]?.visibility !== 'pinned' ? "cardPinned" : "cardUnpinned", {title: titleString, type});
            
            return {
                ...curValue!,
                [type]: {
                    visibility: (curValue?.[type]?.visibility === 'pinned') ? null : 'pinned'
                }
            };
        });
    }, [queryDataSetter, titleString, trackWithSearchData, type, queryDataDashboardProp]);
    
    const onHideClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
        e.stopPropagation();

        queryDataSetter?.(queryDataDashboardProp, (curValue) => {
            trackWithSearchData(curValue?.[type]?.visibility !== 'hidden' ? "cardHidden" : "cardShown", {title: titleString, type});

            return {
                ...curValue!,
                [type]: {
                    visibility: (curValue?.[type]?.visibility === 'hidden') ? null : 'hidden'
                }
            };
        });

    }, [queryDataSetter, titleString, trackWithSearchData, type, queryDataDashboardProp]);

    //After 'hidden' status changes, need to let horizontalPositionedColuns component know
    useLayoutEffect(() => {
        horizontalPositionContext?.updateSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCardHidden]);

    const isStringHeader = typeof mainTitle === 'string';

    return (
        <div
            className={cn(
                classes.root,
                isPointerIn && classes.isPointerIn,
                isFocusIn && classes.isFocusIn,
            )}
        >
            <div className={cn(classes.header, hasBreakdown && classes.hasBreakdown)}>
                {isStringHeader 
                    ? <Heading id={headingId} data-testid="cardTitle" className={cn(classes.title, hasBreakdown && classes.hasBreakdown)}>{titleString}</Heading>
                    : <>
                        <Heading id={headingId} data-testid="cardTitle" className="offscreen">{titleString}</Heading>
                        {mainTitle}
                    </>}
                {hasNewBadge && <NewBadge />}
                {subTitle && !isCardHidden && <div className={classes.title}>{subTitle}</div>}
            </div>
            {!isCardInteractivityDisabled && <div className={classes.buttonsWrapper}>
                {hasConfig && !isCardHidden && (
                    <IconButton
                        aria-label={`Configure ${titleString}`}
                        className={classes.config}
                        onClick={onConfigClick}
                        disabled={loading}
                    >
                        <IconRouter name="settings-gear-64" aria-hidden />
                    </IconButton>
                )}
                {isSavedSearch && hasDashboardPinAndHide && !isCardHidden && !!queryDataSetter &&
                    <IconButton
                        aria-label={T(isCardPinned ? 'cards.unpinCard' : 'cards.pinCard', {title: titleString})}
                        className={classes.config}
                        onClick={onPinClick}
                    >
                        <IconRouter name={isCardPinned ? 'pin-remove' : 'pin-add' } aria-hidden />
                    </IconButton>
                }
                {isSavedSearch && hasDashboardPinAndHide && !!queryDataSetter &&
                    <IconButton
                        aria-label={T(isCardHidden ? 'cards.unhideCard' : 'cards.hideCard', {title: titleString})}
                        className={classes.config}
                        onClick={onHideClick}
                    >
                        <IconRouter name={isCardHidden ? 'preview' : 'hide' } aria-hidden />
                    </IconButton>
                }
                {!isMultipleSearch && !isCardHidden && TooltipComponent && (
                    <HelpIcon
                        className={classes.tooltip}
                        icon="c-question-e"
                        onClick={(e) => e.stopPropagation()}
                        onShow={() =>
                            trackWithSearchData('cardTooltipShown', {
                                title: titleString,
                                type,
                            })
                        }
                        placement="bottom-start"
                        size="large"
                        buttonLbl={T('cards.moreInfoLbl', {name: getCardName(title)})}
                        tooltip={TooltipComponent}
                    />
                )}
                {hasData && isSavedSearch && !isCardHidden && (
                    <ExportMenu disabled={loading} type={type} mainTitle={titleString} />
                )}
            </div>}
        </div>
    );
}

interface SelectChartParamsArgs {
    updateChartParam: (option: string) => void;
    chartParam: string;
    chartParamsOptions: string[];
    type: 'aggregations' | 'breakdowns';
    disabled?: boolean;
    cardName: string;
}

function SelectChartParams({
    cardName,
    updateChartParam,
    chartParam,
    chartParamsOptions,
    type,
    disabled,
}: SelectChartParamsArgs) {
    const classes = useStyles();

    const buttonLbl = T(`cards.${type}.btnLbl`, {name: cardName});
    const menuLbl = T(`cards.${type}.menuLbl`);
    const menuDesc = T(`cards.${type}.menuDesc`);
    const paramName = T(`${type}.${chartParam}`);

    const button = useMemo(
        () => (
            <button
                type="button"
                className={cn(classes.breakdown, disabled && classes.disabled)}
                aria-label={buttonLbl}
                aria-disabled={disabled}
            >
                {chartParam && <span aria-hidden>{paramName}</span>}
                <IconRouter className={classes.breakdownDropdownIcon} aria-hidden name="chevron-down" />
            </button>
        ),
        [
            buttonLbl,
            chartParam,
            classes.breakdown,
            classes.breakdownDropdownIcon,
            classes.disabled,
            disabled,
            paramName,
        ],
    );

    const entries = useMemo(() => {
        return chartParamsOptions.map((option) => ({
            label: T(`${type}.${option}`),
            onClick: () => {
                updateChartParam(option);
            },
            icon: chartParam === option ? <IconRouter name="f-check" /> : undefined,
        }));
    }, [chartParam, chartParamsOptions, type, updateChartParam]);

    return (
        <SimpleMenu
            title={menuLbl}
            hideTitle
            description={menuDesc}
            buttonComponent={button}
            entries={entries}
            menuDistance={8}
            nonBWStyle
        />
    );
}

type TitlePartsType = {
    mainTitle: ReactNode;
    subTitle?: string;
    titleString: string;
};

type UseGetCardTitlePartsArg = {
    title: CardTitleType;
    isSavedSearch: boolean | undefined;
    isCardHidden: boolean | undefined;
    updateBreakdown?: Parameters<typeof SelectChartParams>[0]['updateChartParam'];
    breakdown?: BreakdownType;
    breakdownOptions?: string[];
    updateAggregate?: Parameters<typeof SelectChartParams>[0]['updateChartParam'];
    aggregate?: AggregateType;
    aggregateOptions?: string[];
    loading: boolean | undefined;
    isCardInteractivityDisabled: boolean | undefined;
};


function useGetCardTitleParts(type: CardType, {
    title, 
    isSavedSearch = false,
    isCardHidden = false,
    breakdown, 
    breakdownOptions, 
    updateBreakdown, 
    aggregate, 
    aggregateOptions, 
    updateAggregate, 
    loading = false,
    isCardInteractivityDisabled
}: UseGetCardTitlePartsArg): TitlePartsType {
    const hasBreakdown = !!(isSavedSearch && cardHasBreakdown(type));
    const hasBreakdownDropdown = hasBreakdown && !isCardHidden && !isCardInteractivityDisabled && !!updateBreakdown;
    
    const hasAggregate = !!(isSavedSearch && cardHasAggregate(type));
    const hasAggregateDropdown = hasAggregate && !isCardHidden && !isCardInteractivityDisabled && !!updateAggregate;

    const titleString = formatCardTitleAsString(title, hasBreakdown ? breakdown : undefined, hasAggregate ? aggregate : undefined);

    if(hasBreakdownDropdown && !breakdownOptions) {
        throw new Error('CardTitle missing breakdownOptions');
    }

    if(hasAggregateDropdown && !aggregateOptions) {
        throw new Error('CardTitle missing aggregateOptions');
    }

    const aggregateDrop: JSX.Element | undefined = useMemo(
        () =>
            hasAggregateDropdown ? (
                <SelectChartParams
                    key="aggregates"
                    cardName={getCardName(title)}
                    updateChartParam={updateAggregate!}
                    chartParam={aggregate!}
                    chartParamsOptions={aggregateOptions!}
                    type={'aggregations'}
                    disabled={loading}
                />
            ) : undefined,
        [aggregate, aggregateOptions, hasAggregateDropdown, loading, updateAggregate, title],
    );

    const breakdownDrop: JSX.Element | undefined = useMemo(
        () =>
            hasBreakdownDropdown ? (
                <SelectChartParams
                    key="breakdowns"
                    cardName={getCardName(title)}
                    updateChartParam={updateBreakdown}
                    chartParam={breakdown!}
                    chartParamsOptions={breakdownOptions!}
                    type={'breakdowns'}
                    disabled={loading}
                />
            ) : undefined,
        [breakdown, breakdownOptions, hasBreakdownDropdown, loading, title, updateBreakdown],
    );

    return useMemo(() => {
        if (typeof title === 'string') {
            return {mainTitle: T(title), titleString};
        }
    
        const {breakdownType, generalDimension, overDimension, subTitle, title: cardTitle} = title;

        const mainTitle = (!hasAggregate || !hasAggregateDropdown) && (!hasBreakdown || !hasBreakdownDropdown)
            // All parts of title are strings, so can just return a formatted string
            ? titleString
            // The title involves intermixed text and interactive elements
            : (
                <>
                    {hasAggregate ? (
                        <>
                            <span aria-hidden>{T(generalDimension)}</span>
                            {hasAggregateDropdown ? aggregateDrop : T(`aggregations.${aggregate}`).toLowerCase()}
                            <span aria-hidden>{T(overDimension)}</span>
                        </>
                    ) : (
                        <span aria-hidden>{T(cardTitle)}</span>
                    )}
                    {hasBreakdown && (
                        <>
                            <span aria-hidden>
                                {T('cards.breakdownWithPrefix', {prefix: T(breakdownType)})}
                            </span>
                            {hasBreakdownDropdown ? breakdownDrop : T(`breakdowns.${breakdown}`).toLowerCase()}
                        </>
                    )}
                </>
            );
    
        return {
            mainTitle,
            subTitle: subTitle ? (T(subTitle) as string) : undefined,
            titleString
        };
    }, [aggregate, aggregateDrop, breakdown, breakdownDrop, hasAggregate, hasAggregateDropdown, hasBreakdown, hasBreakdownDropdown, title, titleString])
}

/**
 * SF: I just made everything optional to get rid of the warnings. It still produces the same titles.
 * This should probably be refactored to make sure that it handles things being undefined properly.
 */
export function formatCardTitleAsString(
    cardTitle: CardTitleType,
    breakdown?: BreakdownType,
    aggregate?: AggregateType,
): string {
    if (typeof cardTitle !== 'string') {
        const {breakdownType, generalDimension, overDimension, subTitle, title} = cardTitle;
        if(aggregate && !breakdown) {
            throw new Error('this is not implmented');
        }

        if (aggregate) {
            return T('cards.titleWithAggregationAndBreakdown', {
                generalDimension: generalDimension ? T(generalDimension) : '',
                aggregate: T(`aggregations.${aggregate}`).toLowerCase(),
                overDimension: overDimension ? T(overDimension) : '',
                breakdownType: breakdownType ? T(breakdownType) : '',
                breakdown: breakdown ? T(`breakdowns.${breakdown}`).toLowerCase() : '',
                subTitle: subTitle ? T(subTitle) : '',
            }).trim();
        } else if(breakdown) {
            return T('cards.titleWithBreakdown', {
                title: title ? T(title) : '',
                breakdownType: breakdownType ? T(breakdownType) : '',
                breakdown: breakdown ? T(`breakdowns.${breakdown}`).toLowerCase() : '',
                subTitle: subTitle ? T(subTitle) : '',
            }).trim();
        } else {
            return T(title)
        }
    }

    return T(cardTitle);
}

export function getCardName(title: CardTitleType) {
    if(typeof title === 'string') {
        return T(title);
    }

    return T(title.title);
}

