import { useMemo } from "react";

//Hooks
import useQueryUserData from "hsi/hooks/useQueryUserData";

//Other
import { WORD_CLOUD_DEFAULT, WordCloudTypes } from "hsi/constants/config";
import { WordCloudConfigType } from "hsi/types/queryUserData";
import compact from "lodash/compact";

//Consts
export const DefaultWordCloudConfig: WordCloudConfigType = {
    types: [...WordCloudTypes],
    orderBy: 'volume',
    color: 'topicType',
    size: WORD_CLOUD_DEFAULT
};


//The hook
export default function useGetWordCloudConfig(queryId: number | null | undefined): WordCloudConfigType {
    const wordCloudConfig = useQueryUserData(queryId, 'wordCloudCard');

    return useMemo(() => ({
        types: wordCloudConfig?.types && compact(wordCloudConfig.types).length > 0 ? wordCloudConfig?.types : DefaultWordCloudConfig.types,
        orderBy: wordCloudConfig?.orderBy || DefaultWordCloudConfig.orderBy,
        color: wordCloudConfig?.color || DefaultWordCloudConfig.color,
        size: wordCloudConfig?.size || DefaultWordCloudConfig.size
    }), [wordCloudConfig?.color, wordCloudConfig?.orderBy, wordCloudConfig?.size, wordCloudConfig?.types]);
}