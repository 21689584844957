import makeStyles from '@mui/styles/makeStyles';
import {isV2, Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);
    const errorCol = v2 ? theme.colors.error : theme.colors.primaryRed100; //#BA3038
    const errorBgCol = v2 ? undefined : theme.colors.errorBg;

    return {
        chip: {
            overflow: 'hidden',
            margin: 0,

            '& .MuiChip-label': {
                overflow: 'hidden',
                padding: 0,
                margin: '0 8px',
                display: 'inline-block',
            },
        },
        fullWidth: {
            flex: '1 1 100%',

            '& .MuiChip-label': {
                maxWidth: 'none',
            },
        },
        chipLabelInner: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            lineHeight: 'inherit',
        },
        chipError: {
            backgroundColor: errorBgCol,
            color: errorCol,
            border: `1px solid ${errorCol}`,
            '& svg': {
                fill: `${errorCol} !important`,
            },
        },
    };
});
