import useGetProjectIdFromQueryId from "./useGetProjectIdFromQueryId";
import useQueryContext from "./useQueryContext";
import { useCallback } from "react";
import { putQueryDataKey } from "hsi/services/apiv2/queryDataService";
import { QueryData, QueryDataKeys } from "hsi/types/queryData";
import { useAppSelector, useAppStore } from "./useRedux";
import { Nullable } from "hsi/types/shared";
import { updateQueryData } from "hsi/slices/queryData";
import { RootState } from "hsi/utils/configureStore";
import useIsExport from 'hsi/hooks/useIsExport';

/**
 * Get the per-query persisted data property for the supplied query id. If queryId is null, undefined or unknown, will return null.
 * Will trigger an update of the calling component every time this property of the query data changes
 * @param queryId queryId to get the data for. If null or undefined, will return null
 * @param key the prop within the queryData to retrieve
 * @returns null or the persisted value
 */
export default function useQueryData<Prop extends QueryDataKeys>(queryId: number | null | undefined, key: Prop): QueryData[Prop] | null;
/**
 * Get the per-query persisted data property returned by the supplied selector
 * Will trigger an update of the calling component every time the selector returns different data (using referential equality)
 * @param queryId queryId to get the data for. If null or undefined, will return null
 * @param selector a function called with the persisted data for this query, which selects and return data
 * @returns the selected value
 */
export default function useQueryData<ReturnProp>(queryId: number | null | undefined, selector: (state: Partial<Nullable<QueryData>> | null) => ReturnProp): ReturnProp;
export default function useQueryData(queryId: number | null | undefined, keyOrSelector: any): any {
    const selector = queryId
         ?(typeof keyOrSelector === 'string')
            ? (state: RootState)  => state.queryData[queryId]?.[keyOrSelector as QueryDataKeys] ?? null
            : (state: RootState) => keyOrSelector(state.queryData[queryId])
        : () => null
    
    return useAppSelector(selector);
}

/**
 * Get the per-query persisted data property for the current query. If no current query, will return null.
 * Will trigger an update of the calling component every time this property of the query data changes
 * @param key the prop within the queryData to retrieve
 * @returns null or the persisted value
 */
export function useCurrentQueryData<Prop extends QueryDataKeys>(key: Prop): QueryData[Prop] | null;
/**
 * Get the per-query persisted data property for the current query returned by the supplied selector
 * Will trigger an update of the calling component every time the selector returns different data (using referential equality)
 * @param selector a function called with the persisted data for this query, which selects and return data
 * @returns the selected value
 */
export function useCurrentQueryData<ReturnProp>(selector: (state: Partial<Nullable<QueryData>> | null) => ReturnProp): ReturnProp;
export function useCurrentQueryData(keyOrSelector: any): any {

    const {savedSearchId} = useQueryContext();

    return useQueryData(savedSearchId, keyOrSelector);
}

/**
 * Get the per-query persisted data for the supplied query id. If queryId is null, undefined or unknown, will return null
 * @param queryId queryId to get the data for. If null or undefined, will return null
 * @returns null or an object that may contain the QueryData props, if they are set
 */
export function useAllQueryData(queryId: number | null | undefined): Partial<Nullable<QueryData>> | null {
    return useAppSelector(queryId ? state => state.queryData[queryId!] : () => null);
}

/**
 * Get the per-query persisted data for the current query (if applicable). If not in a query context, will return null
 * @returns null or an object that may contain the QueryData props, if they are set
 */
export function useAllCurrentQueryData() {
    const {savedSearchId} = useQueryContext();

    return useAllQueryData(savedSearchId);
}

/**
 * Returns a function to set persisted values on a per query basis
 * If called with a queryId of null, or an invalid query Id, will return null
 * @param queryId 
 * @returns function to set persisted values OR null
 */
export function useGetQueryDataSetter(queryId: number | null) {
    const isExport = useIsExport();
    const projectId = useGetProjectIdFromQueryId(!isExport ? queryId : null);
    const store = useAppStore();

    // setter can only be called if queryId and projectId are not null
    const setter = useCallback(async <T extends QueryDataKeys>(key: T, value: QueryData[T] | ((currentValue: QueryData[T] | null) => QueryData[T] | null) | null) => {
        const useValue = value instanceof Function 
            ? value(store.getState().queryData[queryId!]?.[key] ?? null) 
            : value;

        const result = await putQueryDataKey(queryId!, projectId!, key, useValue);

        if(result.ok) {
            //Update the state with the new value
            await store.dispatch(updateQueryData({queryId: queryId as number, key, value: useValue}));
        }

        return result;
    }, [projectId, queryId, store]);

    return queryId !== null && projectId !== null 
        ? setter
        : null;
}