import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';


//Components
import EditSearchBar from 'hsi/components/EditSearchBar';
import QuickSearchBar from 'hsi/components/QuickSearchBar';
import SearchResults from 'hsi/containers/SearchResults';
import InsightsContainer from 'hsi/containers/InsightsContainer';
import FullscreenLoading from 'hsi/components/FullscreenLoading';

//Actions
import {loadSearch} from 'hsi/actions/savedSearchActions';

//Hooks
import { useAppDispatch, useAppSelector } from 'hsi/hooks/useRedux';
import useQueryContext from 'hsi/hooks/useQueryContext';

//Other
import withLoadQueryState from 'hsi/hocs/withLoadQueryState';
import { T } from 'hsi/i18n';


//The component
export default withLoadQueryState(function EditSavedSearch() {
    const {savedSearch} = useQueryContext();
    const dispatch = useAppDispatch();
    const hasSavedSearch = !isEmpty(savedSearch);
    const {id: searchId} = useParams<{id?: string}>();

    useEffect(() => {
        !hasSavedSearch && dispatch(loadSearch(searchId));
    }, [dispatch, hasSavedSearch, searchId]);

    const filtersConfig = useAppSelector((state) => state.filters.allFiltersConfig);

    if(isEmpty(filtersConfig)) {
        return <FullscreenLoading message={T('loading')} />
    }

    if(!savedSearch) {
        return <div>Error: saved search not found</div>;//handle error better?
    }

    return (
        <>
            {hasSavedSearch && <EditSearchBar savedSearch={savedSearch} />}
            <QuickSearchBar savedSearch={savedSearch} />
            <InsightsContainer isSavedSearch={false}>
                {hasSavedSearch && <SearchResults />}
            </InsightsContainer>
        </>
    );
});

//export default withLoadQueryState(EditSavedSearch);
