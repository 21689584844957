import React, {useRef, useMemo, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {scaleLinear} from 'd3-scale';
import {interpolate, interpolateRgb} from 'd3-interpolate';
import {rgb} from 'd3-color';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import WordCloudContainer from './WordCloudContainer';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useRefCallback from 'hsi/hooks/useRefCallback';
import useQueryContext from 'hsi/hooks/useQueryContext';
import useQueryId from 'hsi/hooks/useQueryId';
import useGetWordCloudConfig from './useGetWordCloudConfig';
import useIsComponentInit from 'hsi/hooks/useIsComponentInit';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';
import {toggleConfig} from 'hsi/actions/resultsActions';

//Other
import {T} from 'hsi/i18n';
import { WordCloudTypes, WordCloudTypeToColorKey } from 'hsi/constants/config';

//Constants
const type = 'wordCloud';

//The components
export default React.forwardRef(function WordCloud({title, ...props}, ref) {
    const {
        themeColors,
        links: {dashboardInfoWordCloudCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();
    const wordCloudRef = useRef();

    //Redux
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const isSelectedCard = useSelector((state) => state.mentions.drillInCard === 'wordcloud'); // Will this work or should it be wordCloud?
    const isSideDrawerOpen = useSelector((state) => state.results.sideDrawer.open);
    const wordCloudConfig = useGetWordCloudConfig(useQueryId());
    const dispatch = useDispatch();

    //Calculated values
    const {searchType} = useQueryContext();
    const loadData = useGetLoadData(type);

    const configColor = useMemo(
        () => (searchType === 'quick' ? 'default' : wordCloudConfig.color),
        [searchType, wordCloudConfig.color],
    );

    const colors = useMemo(() => themeColors[configColor], [configColor, themeColors]);

    const topics = useMemo(
        () =>
            (data?.topics || []).slice(0, wordCloudConfig.size).map((topic, i, topics) => ({
                ...topic,
                color: colorProcessor(topic, topics, i, configColor, colors),
            })),
        [data?.topics, wordCloudConfig.size, configColor, colors],
    );

    const legend = useMemo(() => {
        switch (configColor) {
            case 'topicType':
                const types = topics
                    .map((t) => t.topicType)
                    .filter((val, i, a) => a.indexOf(val) === i);
                return {
                    type: 'discrete',
                    fields: WordCloudTypes.filter(
                        (wordCloudType) => wordCloudType !== 'emojis' && types.includes(WordCloudTypeToColorKey[wordCloudType]),
                    ).map((wordCloudType) => ({
                        label: T(`cards.wordCloud.types.${wordCloudType}`),
                        color: colors[WordCloudTypeToColorKey[wordCloudType]],
                    })),
                };
            case 'gender':
                return {
                    type: 'discrete',
                    fields: [
                        {label: 'Male', color: colors.male},
                        {label: 'Female', color: colors.female},
                        {label: 'Unknown', color: colors.unknown},
                    ],
                };
            case 'sentiment':
                return {
                    type: 'discrete',
                    fields: [
                        {label: 'Positive', color: colors.positive},
                        {label: 'Negative', color: colors.negative},
                        {label: 'Neutral', color: colors.neutral},
                    ],
                };
            case 'volume':
            case 'trending':
                let fn, range;
                if (configColor === 'volume') {
                    fn = interpolate(0, Math.max(...topics.map((t) => t.volume)));
                    range = [fn(0), fn(0.25), fn(0.5), fn(0.75), fn(1)].map(Math.ceil);
                }

                return {
                    type: 'continuous',
                    colorBy: configColor,
                    range: configColor === 'volume' ? range : null,
                    mainLabel: configColor === 'volume' ? 'Total mentions' : undefined,
                    startLabel: configColor === 'volume' ? undefined : 'Fading',
                    endLabel: configColor === 'volume' ? undefined : 'Trending',
                    gradientColor: {start: colors.low, middle: colors.mid, end: colors.high},
                };
            case 'default':
            default:
                return {};
        }
    }, [topics, configColor, colors]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    //Callbacks
    const toggleConfigDrawer = useCallback(() => {
        trackWithSearchData('configDrawerToggled', {
            state: !isSideDrawerOpen ? 'open' : 'closed',
            type,
        });
        dispatch(toggleConfig('wordCloud'));
    }, [dispatch, isSideDrawerOpen, trackWithSearchData]);

    const drillIn = useRefCallback((drillIn) => {
        const topic = topics.find(({id}) => id === drillIn.id);
        const drillinValue = topic.topicType === 'emoji' ? topic.value : topic.label;
        const drillinFilter = {keyword: drillinValue};
        const label = topic.topicType === 'emoji' ? topic.value : topic.label;
        trackWithSearchData('cardDrilledIn', {
            type,
            value: label,
        });

        dispatch(
            mentionsDrillIn(
                drillinFilter,
                'wordcloud', // Ideally this would be wordCloud
                null,
                label,
            ),
        );
    });

    const hasData = !!topics && topics.length > 0;
    const isComponentInit = useIsComponentInit();

    useEffect(() => {
        //Reload card data if config changes
        !isComponentInit && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordCloudConfig.orderBy, wordCloudConfig.types])

    //Render
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle
                    hasConfig={searchType !== 'quick'}
                    onConfigClick={toggleConfigDrawer}
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    hasData={hasData}
                />
            }
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            selected={isSelectedCard}
            loadData={loadData}
            ref={ref}
            data-testid={'wordCloud'}
            type={type}
        >
            <WordCloudContainer
                collection={topics}
                legend={legend}
                onWordClick={drillIn}
                wordCloudRef={wordCloudRef}
            />
        </CardLoadState>
    );
});

//Internal helpers
const colorProcessor = (topic, topics, index, configColor, colors) => {
    switch (configColor) {
        case 'topicType':
            return colors[topic.topicType] || '#000';
        case 'gender':
            if (topic.gender.male > topic.gender.female) {
                return colors.male;
            } else if (topic.gender.male < topic.gender.female) {
                return colors.female;
            } else {
                return colors.unknown;
            }
        case 'sentiment':
            if (topic.sentimentScore > 20) {
                return colors.positive;
            } else if (topic.sentimentScore < -20) {
                return colors.negative;
            } else {
                return colors.neutral;
            }
        case 'volume':
            return getInterpolatedColour({
                start: colors.low,
                mid: colors.mid,
                end: colors.high,
                value: topic.volume,
                max: Math.max(...topics.map((t) => t.volume)),
            });
        case 'trending':
            return getInterpolatedColour({
                start: colors.low,
                mid: colors.mid,
                end: colors.high,
                value: topic.trending,
                min: Math.min(...topics.map((t) => t.trending)),
                max: Math.max(...topics.map((t) => t.trending)),
            });
        case 'random':
            return colors[Math.floor(Math.random() * Math.floor(colors.length))];
        case 'none':
            return colors[0];
        case 'default':
        default:
            return colors[index % colors.length];
    }
};

//Shamelessly copied from https://github.com/BrandwatchLtd/bw-js-packages //
const getInterpolatedColour = ({value, min = 0, max, start, mid, end}) => {
    let scaleFn = scaleLinear()
        .domain([min, (min + max) / 2, max])
        .range([start, mid, end])
        .interpolate(interpolateRgb);
    return rgbToHex(rgb(scaleFn(value)));
};

// Shamelessly copied from https://github.com/BrandwatchLtd/bw-js-packages //
const rgbToHex = (rgbColor) => {
    return (
        '#' +
        ['r', 'g', 'b']
            .map((level) => rgbColor[level].toString(16).padStart(2, 0).toUpperCase())
            .join('')
    );
};
