import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

const width = '360px';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        container: {
            backgroundColor: theme.colors.white,
            border: `1px solid ${theme.colors.borders}`,
            boxShadow: `0px 4px 9px ${theme.colors.borders}`,
            borderRadius: '3px',
            width,
        },

        searchBox: {
            padding: '20px 25px 15px 25px',
        },

        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 25px',
        },

        title: {
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: 'bold',
        },

        optionsLeft: {
            textTransform: 'uppercase',
            color: theme.colors.primaryRed100,
            fontWeight: 'bold',
        },

        checkboxes: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '250px',
            overflowY: 'scroll',
            padding: v2 ? '10px 15px' : 0,
            borderTop: '1px #ccc solid',
            marginTop: '10px',
            '& .chbox-icon-frame.checked': {
                backgroundColor: v2
                    ? `${theme.colors.accentDefault} !important`
                    : `#FFF !important`,
                borderColor: v2
                    ? `${theme.colors.accentDefault} !important`
                    : `${theme.colors.icons} !important`,
            },
            '& .MuiFormControlLabel-label': {
                color: v2 ? theme.colors.carbon : undefined,
                fontWeight: 'normal',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                lineHeight: 'unset',
                paddingRight: '24px',
            },
        },

        checkboxesList: {
            listStyle: 'none',
            margin: 0,
            padding: 0,
        },

        checkboxLabel: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontWeight: v2 ? 'normal' : 'bold',
        },

        noResultsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '60px 30px',
        },

        noResultsIconContainer: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            padding: '10px',
            backgroundColor: 'rgba(63, 63, 63, 0.05)',
            borderRadius: '24px',
        },

        noResultsIcon: {
            width: '18px',
            height: '18px',
        },

        noResultsText: {
            fontWeight: 'bold',
            margin: '12px 0px 2px 0px',
            textAlign: 'center',
        },

        info: {
            borderTop: '1px #ccc solid',
            padding: '12px 16px',
        },

        actions: {
            borderTop: '1px #ccc solid',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px',
            '& button': {
                margin: '0 6px',
            },
        },

        actionsRight: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        saveButtonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-10px',
            marginLeft: '-10px',
        },

        cancelButton: {
            color: v2 ? theme.colors.accentDefault : undefined,
        },

        clearAllButton: {
            color: v2 ? theme.colors.accentDefault : undefined,
        },
    };
});
