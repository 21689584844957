import {useState} from 'react';
import pick from 'lodash/pick';

import BackDialog from './components/BackDialog';
import {CharLimitErrorMsg} from 'hsi/components/ErrorMessage';
import Formula from './components/Formula';
import Header from './components/Header';
import Footer from './components/Footer';
import Section from './components/Section';
import {Tabs, Tab} from '../components/Tabs';
import {HeadingContents} from 'hsi/components/aria/Heading';

import useEventTrack from 'hsi/hooks/useEventTrack';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import useQueryContext from 'hsi/hooks/useQueryContext';

import {setBooleanQuery, validateBooleanQuery} from 'hsi/actions/queryActions';

import {GUIDED_OPTS} from '../config';
import {MAX_EDIT_SEARCH_LENGTH, MAX_QUICK_SEARCH_LENGTH} from 'hsi/constants/config';
import {updateFormula} from 'hsi/utils/formulaParser';

import useStyles from './styles';
import Dialog from 'hsi/components/Dialog';

type GuidedQueryFormProps = {
    isEditSearch?: boolean;
    onSubmit: (query: string | null) => void;
    open?: boolean;
    setUnguided: () => void;
};

const GuidedQueryForm = ({
    isEditSearch,
    onSubmit,
    open = false,
    setUnguided,
}: GuidedQueryFormProps) => {
    const cl = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();

    const queryContext = useQueryContext();
    const projects = useAppSelector((state) => state.user.projects);

    const formula = useAppSelector((state) => pick(state.query, ['guidedForm', 'booleanQuery']));
    const config = GUIDED_OPTS.find((d) => d.id === formula?.guidedForm?.type);

    const [sectionIdx, setSectionIdx] = useState(0);
    const [openBackModal, setOpenBackModal] = useState(false);

    const maxLength = isEditSearch ? MAX_EDIT_SEARCH_LENGTH : MAX_QUICK_SEARCH_LENGTH;
    const isQuickSearchMax = formula?.booleanQuery?.length > MAX_QUICK_SEARCH_LENGTH;

    const onSectionChange = ({sectionName, componentName, values}: any) => {
        const payload = updateFormula({
            config,
            prevFormula: formula,
            sectionName,
            componentName,
            values,
        });

        dispatch(setBooleanQuery(payload));
    };

    const onTabChange = (_e: React.ChangeEvent<{}>, value: number) => setSectionIdx(value);

    //This is the onSubmit handler!
    const onSearch = async () => {
        const projectId = queryContext?.projectId || projects![0].id;

        const result = await dispatch(
            validateBooleanQuery({
                //why is this an action?
                booleanQuery: formula.booleanQuery,
                projectId,
                isSaved: false,
            }),
        );

        if (result?.payload) {
            trackWithSearchData('searchErrored', {
                errors: result.payload,
            });

            onSubmit(null);
        } else {
            //success
            trackWithSearchData('guidedSearchCompleted', {
                type: formula?.guidedForm?.type,
            });

            onSubmit(formula.booleanQuery);
        }
    };

    const onGoBackToUnguided = () =>
        formula.booleanQuery ? setOpenBackModal(true) : setUnguided();

    return (
        <Dialog
            PaperProps={{className: cl.guidedWrapper}}
            aria-labelledby="guidedQueryForm-header"
            data-testid="guidedQueryForm"
            open
            onClose={onGoBackToUnguided}
            noCloseBtn
        >
            <Header
                id="guidedQueryForm-header"
                className={cl.header}
                label={config?.label}
                onBack={onGoBackToUnguided}
            />
            <HeadingContents>
                {isQuickSearchMax && !isEditSearch && (
                    <CharLimitErrorMsg
                        className={cl.error}
                    />
                )}

                <Formula
                    className={cl.formula}
                    exitGuidedMode={onGoBackToUnguided}
                    formula={formula}
                    maxLength={maxLength}
                />

                <Tabs
                    className={cl.tabs}
                    value={sectionIdx}
                    onChange={onTabChange}
                >
                    {config?.sections.map((d) => (
                        <Tab label={d.label} key={d.name} />
                    ))}
                </Tabs>

                {config && (
                    <Section
                        className={cl.section}
                        {...config.sections[sectionIdx]}
                        formula={formula}
                        onChange={onSectionChange}
                    />
                )}

                <Footer
                    className={cl.footer}
                    disabled={isQuickSearchMax || !formula?.booleanQuery?.length}
                    onSearch={onSearch}
                />

                <BackDialog
                    open={openBackModal}
                    onAccept={() => setUnguided()}
                    onReject={() => setOpenBackModal(false)}
                />
            </HeadingContents>
        </Dialog>
    );
};

export default GuidedQueryForm;
