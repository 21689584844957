import React, {createContext, ReactNode, useContext, useEffect, useMemo, useRef} from 'react';

//Components
import Card, {
    CardErrorMsg,
    CardNoDataMsg,
    CardComingSoonMsg,
    CardUnsupportedMsg,
    CardDatanotAvailablePerDataLimitation,
} from 'hsi/components/Card';
import FadeInOnVisible from 'hsi/components/FadeInOnVisible';

//Utils
import {mergeRefs} from 'hsi/utils/react';

//Other
import {CardType} from 'hsi/types/cards';
import {VisibleObserver} from 'hsi/hooks/useOnVisible';
import useIsCardHidden from 'hsi/hooks/useIsCardHidden';


type CardLoadStateContextType = {
    loading: boolean;
    hasData: boolean;
};

const CardLoadStateContext = createContext<CardLoadStateContextType | undefined>(undefined);

interface LoadDataType {
    (): void;
    abort?: () => void;
}

export interface CardLoadStateProps
    extends Omit<JSX.IntrinsicElements['div'], 'title' | 'ref' | 'key'>,
        Pick<Parameters<typeof Card>[0], 'title' | 'renderWhenVisible'> {
    type: CardType;
    subTitle?: ReactNode;
    height?: number;
    skipCardContent?: boolean;

    error?: boolean;
    loading?: boolean;
    loaded?: boolean;
    hasData?: boolean;
    loadData?: LoadDataType;
    selected?: boolean;
    comingSoon?: boolean;
    unsupported?: boolean;
    notAvailablePerDataLimitation?: boolean;

    isVisible?: boolean; //card will load it's data once it is visible, and defaults to assuming it is.
    fadeOnVisible?: boolean | React.Context<VisibleObserver>; //if false, will not fade. If true, will fade on visible using default is visible container, else if a context will use that context for deetected isVisible

    children?: ReactNode;

    exportLoadedCallback?: () => void;
    onContentChanged?: () => void;
}

//The component
export default React.forwardRef<HTMLDivElement, CardLoadStateProps>(function CardLoadState(
    {
        title,
        renderWhenVisible,
        type,
        subTitle = null,
        height = undefined,
        skipCardContent = false,

        error = false,
        loading = true,
        loaded = false,
        hasData = false,
        loadData = undefined,
        selected = false,
        comingSoon = false,
        unsupported = false,
        notAvailablePerDataLimitation = undefined,

        exportLoadedCallback,
        isVisible = true, //card will load it's data once it is visible, and defaults to assuming it is.
        onContentChanged,
        fadeOnVisible = false, //if false, will not fade. If true, will fade on visible using default is visible container, else if a context will use that context for deetected isVisible

        children = null,
        ...rest
    },
    ref,
) {
    const localRef = useRef<HTMLDivElement>(null);
    const withConfig = !!(title as any)?.props?.hasConfig;
    const isCardHidden = useIsCardHidden(type);

    if(type === undefined) {
        throw new Error(`CardLoadState error: parameter 'type' is required`);
    }

    //Calculated values
    const content = useMemo(() => {
        if (unsupported) {
            return <CardUnsupportedMsg />;
        }

        if (comingSoon) {
            return <CardComingSoonMsg />;
        }

        if (notAvailablePerDataLimitation) {
            return <CardDatanotAvailablePerDataLimitation type={type} />;
        }

        return (
            <>
                {loaded && !error && !loading && !hasData && (
                    <CardNoDataMsg withConfig={withConfig} />
                )}
                {!error && !loading && hasData && children}
                {error && <CardErrorMsg />}
            </>
        );
    }, [
        loading,
        loaded,
        hasData,
        withConfig,
        error,
        children,
        comingSoon,
        unsupported,
        notAvailablePerDataLimitation,
        type,
    ]);

    //Side effects
    useEffect(() => onContentChanged && onContentChanged(), [content, onContentChanged]);

    useEffect(() => {
        //if the card is visible, not marked as hidden, and doesn't already have data, and has a loadData method, then load the data
        isVisible && !loaded && !loading && !!loadData && !isCardHidden && loadData();
    }, [isVisible, loaded, loadData, loading, isCardHidden]);

    useEffect(() => {
        return () => {
            !!loadData && !!loadData.abort && loadData.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        exportLoadedCallback && exportLoadedCallback();
    }, [exportLoadedCallback]);

    useEffect(() => {
        //dispatch loadeddata event when loaded changes from false to true, which can be use
        //by parent components e.g. HorizontalPositionedColumns to re-check size & reflow
        //if required
        if (loaded) {
            //the ?. seems to confuse the linter
            // eslint-disable-next-line no-unused-expressions
            localRef.current?.dispatchEvent(new Event('loadeddata'));
        }
    }, [loaded]);

    const contextValue = useMemo(
        () => ({
            loading,
            hasData,
        }),
        [loading, hasData],
    );

    //Render
    return (
        <div {...rest} ref={mergeRefs(ref, localRef)}>
            <CardLoadStateContext.Provider value={contextValue}>
                <Card
                    title={title}
                    type={type}
                    subheader={subTitle}
                    loading={loading}
                    selected={selected}
                    height={height && !(!error && !loading && hasData) ? `${height}px` : null}
                    skipCardContent={skipCardContent}
                    renderWhenVisible={renderWhenVisible}
                >
                    {fadeOnVisible ? (
                        <FadeInOnVisible
                            context={fadeOnVisible === true ? undefined : fadeOnVisible}
                        >
                            <div style={{opacity: 0}}>{content}</div>
                        </FadeInOnVisible>
                    ) : (
                        content
                    )}
                </Card>
            </CardLoadStateContext.Provider>
        </div>
    );
});

export function useCardLoadStateContext() {
    return useContext(CardLoadStateContext);
}
