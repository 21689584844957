import http from "hsi/classes/Http";
import { ApiResponse } from "hsi/types/apiv2";
import { AllFilteringState } from "hsi/types/filters";
import { QueryContextType } from "hsi/types/query";
import { BCRApiErrorResponse, BCRQuery } from "hsi/types/shared";
import getQueryIdUrl from "hsi/utils/app/getQueryIdUrl";
import { filterStateToAPIFormat } from "hsi/utils/filters";
import { getSearchParams, queryParamsToString, SavedSearchParamsType } from "hsi/utils/url";


export async function checkSearchHasData(
    queryId: number, 
    projectId: number, 
    filters: Pick<AllFilteringState, 'filters' | 'allFiltersConfig' | 'dateRange'>,
    queryContext: QueryContextType,
    additionalQueries: number[] | undefined,
    linkedinChannelIds: number[] | undefined,
) {
    //queryId not required as it's part of the url, and timezone is not required as start & end date are already specified
    const {queryId: _, timezone, ...params} = getSearchParams(
        filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
        filters.dateRange,
        queryContext,
        additionalQueries,
        linkedinChannelIds,
        true
    ) as SavedSearchParamsType;

    return http.get<ApiResponse<boolean>>(`/apiv2/${projectId}/saved-search/${getQueryIdUrl(queryId, additionalQueries, linkedinChannelIds)}/results/hasData${queryParamsToString(params)}`)
}

/**
 * Creates a BCR query
 * 
 * @param projectId The project ID the new query will belong to
 * @param values The query params, name & booleanQuery are required
 * @returns A promise with a HttpResult object with the response being the full set of values for the new query (if successfull)
 */
export async function createQuery(projectId: number, values: Pick<BCRQuery, 'name' | 'booleanQuery'> & Partial<Omit<BCRQuery, 'id' | 'name' | 'booleanQuery'>>) {
    return http.post<ApiResponse<BCRQuery | BCRApiErrorResponse>>(`/apiv2/${projectId}/saved-search`, values)
}

/**
 * Edit an existing BCR query
 * 
 * @param projectId 
 * @param queryId 
 * @param values The new values for the query (only values that are being changed are required)
 * @returns A promise with a HttpResult object with the response being the full set of values for the updated query (if successfull)
 */
export async function updateQuery(projectId: number, queryId: number, values: Partial<Omit<BCRQuery, 'id'>>) {
    return http.patch<ApiResponse<BCRQuery>>(`/apiv2/${projectId}/saved-search/${queryId}`, values);
}