import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {isEmpty} from 'lodash';

import {UPDATE_SEARCH_SUCCESS} from 'hsi/constants/actionTypes';
import {GuidedFormType, QueryStateType, LegacySearchState} from 'hsi/types/query';
import {
    loadRecentQueries,
    persistActiveQuery,
    validateBooleanQuery,
    saveActiveQuery,
} from './thunks';
import {LinkedinChannelIdsType} from 'hsi/types/shared';
import {T} from 'hsi/i18n';
import { GetAllQueriesResponse } from 'hsi/services/apiv2/userService';

// initial state
const initialState: QueryStateType = {
    booleanQuery: '',
    guidedForm: null,
    isGuided: false,
    validating: false,
    validated: true,
    errors: null,
    persistId: null,
    savedSearchId: null,
    recentQueries: [],
    recentQueriesLoaded: false,
    savedSearchQueries: {},
    additionalQueries: [],
    linkedinChannelIds: [],
    saving: false,
    saveError: null,
    context: {},
};

// main slice object
const querySlice = createSlice({
    name: 'query',
    initialState: initialState as QueryStateType,
    reducers: {
        setQueryValidated: (state, {payload}: PayloadAction<boolean>) => {
            state.validated = payload;
        },

        setQueryValidating: (state, {payload}: PayloadAction<boolean>) => {
            state.validating = payload;
        },

        setAdditionalQueries: (state, {payload}: PayloadAction<number[]>) => {
            state.additionalQueries = payload;
            state.context.isMultipleSearch = isMultipleSearchFromState(state);
        },

        setLinkedinChannelIds: (state, {payload}: PayloadAction<LinkedinChannelIdsType>) => {
            state.linkedinChannelIds = payload;
            state.context.isMultipleSearch = isMultipleSearchFromState(state);
        },

        setQueryUnguided: (state) => {
            state.isGuided = false;
            state.guidedForm = null;
        },

        setBooleanQuery: (
            state,
            {
                payload: {booleanQuery, guidedForm, validated},
            }: PayloadAction<{
                booleanQuery: string;
                guidedForm: GuidedFormType;
                validated?: boolean;
            }>,
        ) => {
            state.booleanQuery = booleanQuery;
            state.guidedForm = guidedForm;
            state.isGuided = !!guidedForm;

            if (validated !== undefined) {
                state.validated = validated;
            }
        },

        setQueryErrors: (state, {payload: errors}: PayloadAction<string[]>) => {
            state.errors = errors;
        },

        clearQueryErrors: (state) => {
            state.errors = null;
        },

        setGuidedQueryType: (state, {payload: type}: PayloadAction<string>) => {
            state.isGuided = true;
            state.booleanQuery = '';
            state.guidedForm = {type};
        },

        //TODO this should be changed to listen for a 'saved searches loaded' action
        //from a different slice.
        setSavedSearchQueries: (state, {payload}: PayloadAction<GetAllQueriesResponse>) => {
            state.savedSearchQueries = payload.queryData.reduce<{[key: number]: LegacySearchState}>((acc, curr) => {
                if (curr.queryId) {
                    const query = payload.results.find(({id}) => id === curr.queryId);

                    if(!!query) {
                        const {queryData, queryUserData, ...queryProps} = curr;
                        acc[curr.queryId] = {
                            ...queryProps,
                            formState: null,
                            queryId: curr.queryId,
                            booleanQuery: query.booleanQuery,
                        };
                    }
                    
                }

                return acc;
            }, {});
        },
        resetQueryData: (state, {payload}: PayloadAction<Partial<QueryStateType> | undefined>) =>
            updateContext({
                ...state,
                savedSearchId: null,
                persistId: null,
                booleanQuery: '',
                guidedForm: null,
                isGuided: false,
                validated: true,
                ...payload,
            }),

        setQueryStateFromURL: (
            state,
            {
                payload: {query, isEditSearch, savedSearch, project, urlSearchParams},
            }: PayloadAction<{
                query: LegacySearchState;
                isEditSearch: boolean;
                project: any; //TODO type
                savedSearch: any; //TODO type
                urlSearchParams: {[key: string]: string};
            }>,
        ) => {
            state.booleanQuery = query.booleanQuery || '';
            state.errors = null;
            state.validated = true;
            state.validating = false;
            state.persistId = query.id || null;
            state.additionalQueries = query.additionalQueries || [];
            state.linkedinChannelIds = query.linkedinChannelIds || [];
            state.savedSearchId = null;
            //state.recentQueries = [];//?
            //state.savedSearchQueries = {};
            state.saving = false;
            state.saveError = null;
            state.context = {
                project,
                isEditSearch,
                savedSearch,
                urlSearchParams,
            };

            if (isEditSearch) {
                state.guidedForm = null;
            }

            state.isGuided = !!state.guidedForm;

            updateContext(state);
        },

        //This is a temporary action - long term we need to refactor how we
        //handle saving/editing/persisting queries
        updateSavedAndRecentQueries(state, {payload: queryObj}: PayloadAction<LegacySearchState>) {
            if (queryObj) {
                state.persistId = queryObj.id ?? null;

                if (queryObj.queryId) {
                    state.savedSearchQueries[queryObj.queryId] = queryObj;
                    state.recentQueries.unshift(queryObj); //<TODO is this correct? I actually think recentQueries is actually just 'recent quicksearches', but am not at all sure
                } else {
                    if (!queryObj.id) {
                        // no id -> prepend to current recent queries
                        state.recentQueries.unshift(queryObj);
                    } else {
                        // update existing recent query (and move item to front of the line)
                        state.recentQueries = [
                            queryObj,
                            ...state.recentQueries.filter((rq) => rq.id !== queryObj.id),
                        ];
                    }
                }
            }
        },
        replaceQueryState: (state, action: PayloadAction<QueryStateType>) => action.payload,
    },
    extraReducers: (builder) => {
        builder.addCase(saveActiveQuery.pending, (state, {meta: {arg: savedQueryId}}) => {
            //TODO
        });

        builder
            .addCase(validateBooleanQuery.pending, (state) => {
                state.errors = null;
                state.validating = true;
            })
            .addCase(validateBooleanQuery.fulfilled, (state) => {
                state.errors = null;
                state.validating = false;
                state.validated = true;
            })
            .addCase(validateBooleanQuery.rejected, (state, {payload: errors}) => {
                state.errors = errors as string[];
                state.validating = false;
                state.validated = false;
                state.isGuided = false; //this is required, even if you are using the guided form
            });

        builder
            .addCase(persistActiveQuery.pending, (state) => {
                state.saving = true;
                state.saveError = null;
            })
            .addCase(persistActiveQuery.fulfilled, (state, {payload: queryObj}) => {
                state.saving = false;
                state.saveError = null;
            })
            .addCase(persistActiveQuery.rejected, (state, {payload}) => {
                state.saving = false;
                state.saveError = payload;
            });

        builder
            .addCase(
                loadRecentQueries.fulfilled,

                (state, action) => {
                    state.recentQueriesLoaded = true;
                    state.recentQueries = action.payload;
                },
            )
            .addCase(loadRecentQueries.rejected, (state) => {
                state.recentQueries = [];
                state.errors = [T('queryBuilder.recentQueriesLoadError')];
            });

        //TODO add type
        builder.addCase(createAction<any>(UPDATE_SEARCH_SUCCESS), (state, action) => {
            state.context.savedSearch = action.payload;
        });
    },
});

function updateContext(state: QueryStateType) {
    if (state.context) {
        const {isEditSearch, savedSearch, project} = state.context;
        const isSavedSearch = !!savedSearch && !isEditSearch;

        const newContext = {
            ...state.context,
            isQuickSearch: !isSavedSearch,
            isSavedSearch,
            projectId: project?.id,
            timezone: project?.timezone,
            savedSearchId: savedSearch?.id || undefined
        };

        state.context = newContext;

        const isMultipleSearch = isMultipleSearchFromState(state);

        newContext.isMultipleSearch = isMultipleSearch;
        newContext.isSingleSavedSearch = isSavedSearch && !isMultipleSearch;
        newContext.searchType = savedSearch && !isEditSearch ? 'saved' : 'quick';
    }

    return state;
}

function isMultipleSearchFromState(state: QueryStateType): boolean {
    const {isEditSearch} = state.context;
    const {additionalQueries, linkedinChannelIds} = state;

    return (!isEmpty(additionalQueries) || !isEmpty(linkedinChannelIds)) && !isEditSearch;
}

// export simple actions
export const {
    setGuidedQueryType,
    setBooleanQuery,
    setQueryErrors,
    clearQueryErrors,
    setSavedSearchQueries,
    resetQueryData,
    setQueryStateFromURL,
    setQueryValidating,
    setQueryValidated,
    setAdditionalQueries,
    setLinkedinChannelIds,
    setQueryUnguided,
    updateSavedAndRecentQueries,
    replaceQueryState,
} = querySlice.actions;

// export reducer
export default querySlice.reducer;
