import makeStyles from '@mui/styles/makeStyles';
import { Theme, isV2 } from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    return {
        cardArea: {
            position: 'relative',
            flex: '1 1 0',
            padding: '3px 10px 10px 10px',
            margin: '0 8px',
        },
        card: {
            padding: isV2(theme) ? '10px' : '2px',
        },
    };
});
