import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import resultService from 'hsi/services/resultService';
import {initDateRange} from 'hsi/slices/filters';
import {selectAdditionalQueries, selectLinkedinChannelIds} from 'hsi/selectors';

import processCardRules from 'hsi/utils/cards/processCardRules';
import getConfig from 'hsi/config';
import getCardTypeLimit from 'hsi/utils/cards/getCardTypeLimit';

import ChartsWorker from 'hsi/workers/Charts';

import {
    CLEAR_RESULTS,
    DATA_LOADED,
    DATA_ABORTED,
    LOAD_ERROR,
    LOADING_DATA,
    PEAKS_LOADED,
    TOGGLE_CONFIG,
    REPLACE_RESULTS_STATE,
} from 'hsi/constants/actionTypes';
import {filterStateToAPIFormat} from 'hsi/utils/filters';

import {getCardBreakdownFromState} from 'hsi/utils/cards/breakdowns';
import {getCardAggregateFromState} from 'hsi/utils/cards/aggregates';
import {DefaultTotalVolumeMetrics, TotalVolumeBySearchMetrics, WordCloudTypes } from 'hsi/constants/config'

/**
 * 
 * @param {*} type 
 * @param {*} isBreakdownChange 
 * @param {*} flags 
 * @param {*} pageTypes 
 * @param {{limit?: number}} [options]
 * @returns 
 */
export const loadData = (type, isBreakdownChange = false, flags, pageTypes, options) => {
    if (!flags || !pageTypes) {
        debugger;
    }

    return async (dispatch, getState) => {
        const state = getState();
        let {
            query: {context: queryContext},
            filters: {dateRange, allFiltersConfig, filters},
            queryUserData,
        } = state;
        const additionalQueries = selectAdditionalQueries(state);
        const linkedinChannelIds = selectLinkedinChannelIds(state);

        dispatch({
            type: LOADING_DATA,
            payload: {type, isBreakdownChange},
        });

        if (dateRange.startDate === '' && dateRange.endDate === '') {
            await dispatch(initDateRange(dateRange?.timezone || queryContext.timezone));

            dateRange = getState().filters.dateRange;
        }

        if (['toptopicsBySearch', 'wordCloud'].includes(type)) {
            let types = WordCloudTypes;
            let orderBy = 'volume';

            if(type === 'wordCloud') {
                const wordCloudConfig = queryUserData[queryContext.savedSearchId]?.wordCloudCard;

                if(wordCloudConfig?.types && compact(wordCloudConfig.types).length > 0) {
                    types = wordCloudConfig.types;
                }

                if(wordCloudConfig?.orderBy) {
                    orderBy = wordCloudConfig.orderBy;
                }
            }

            queryContext = {
                ...queryContext,
                additionalParams: {
                    extract: types.map((t) => t.toLowerCase()),
                    orderBy: orderBy,
                },
            };
        }

        if (['totalVolume', 'totalVolumeBySearch'].includes(type)) {
            let metrics = null;

            if(type === 'totalVolume') {
                metrics = uniq(compact(queryUserData[queryContext.savedSearchId]?.totalVolumeCard ?? []));

                if(metrics.length === 0) {
                    metrics = DefaultTotalVolumeMetrics
                }
            } else {
                //totalVolumeBySearch - metrics are not customised, they are fixed
                metrics = TotalVolumeBySearchMetrics;
            }

            queryContext = {
                ...queryContext,
                additionalParams: {
                    metrics
                },
            };
        }

        const {themeColors} = getConfig();

        //Load & format data
        try {
            const data = await ChartsWorker.loadData(
                queryContext,
                type,
                filterStateToAPIFormat(filters, allFiltersConfig),
                dateRange,
                additionalQueries,
                getCardBreakdownFromState(type, queryUserData[queryContext.savedSearchId]?.cardBreakdowns),
                getCardAggregateFromState(type, queryUserData[queryContext.savedSearchId]?.cardAggregates),
                queryUserData[queryContext.savedSearchId]?.cardTableSort?.[type]?.sortColumn,
                options?.limit ?? undefined,
                linkedinChannelIds,
                processCardRules(type, queryContext, flags),
                {
                    themeColors,
                    pageTypes: pageTypes.map(({isAvailable, ...rest}) => rest),
                },
                flags,
            );

            dispatch({
                type: DATA_LOADED,
                payload: {
                    breakdown: getCardBreakdownFromState(type, queryUserData[queryContext.savedSearchId]?.cardBreakdowns),
                    data: data,
                    isBreakdownChange: false,
                    type,
                },
            });
        } catch (e) {
            //we can suppress abort errors because they are the result of user actions
            if (e.name !== 'AbortError') {
                dispatch({
                    type: LOAD_ERROR,
                    payload: {
                        errorCode:
                            e.body && Array.isArray(e.body.errors) && e.body.errors.length > 0
                                ? e.body.errors[0].error_code
                                : null,
                        type,
                    },
                });
            }
        }
    };
};

export const loadAllSearchResults = (flags, pageTypes) => {
    if (!flags || !pageTypes) {
        debugger;
    }

    return async (dispatch, getState) => {
        const charts = getState().charts;
        let dataToDownload = [];

        Object.keys(charts).forEach((cardName) => {
            if (isEmpty(charts[cardName].data)) dataToDownload.push(cardName);
        });

        await Promise.all(
            dataToDownload.map((cardName) =>
                dispatch(loadData(cardName, undefined, flags, pageTypes, {limit: getCardTypeLimit(cardName, true).limit})),
            ),
        );
    };
};

export const abortSearchResults = (type) => (dispatch) => {
    dispatch({
        type: DATA_ABORTED,
        payload: {type},
    });
    return resultService.abortType(type);
};

export const skipLoadData = (type) => (dispatch) => {
    dispatch({
        type: DATA_LOADED,
        payload: {data: null, type},
    });
};

/**
 * 
 * @param {*} type 
 * @param {*} flags 
 * @param {*} pageTypes 
 * @param {{limit?: number}} [options] 
 * @returns 
 */
export const loadSearchResults = (type, flags, pageTypes, options) => {
    if (!flags || !pageTypes) {
        debugger;
    }
    
    return (dispatch, getState) => {
        const queryContext = getState().query.context;
        const rules = processCardRules(type, queryContext, flags);

        if (!rules || !rules.requires) {
            dispatch(skipLoadData(type));
            return;
        }

        // Load data for this card type //
        dispatch(loadData(type, true, flags, pageTypes, options));

        if (rules.peaks) {
            dispatch(detectPeaks(type));
        }
    };
};

export const clearResults = () => ({type: CLEAR_RESULTS});

export const toggleConfig = (type) => (dispatch, getState) => {
    const {sideDrawer} = getState().results;
    if (type) {
        if (sideDrawer.type === type)
            dispatch({
                type: TOGGLE_CONFIG,
                payload: {open: !sideDrawer.open, type},
            });
        else
            dispatch({
                type: TOGGLE_CONFIG,
                payload: {open: true, type},
            });
    } else {
        dispatch({
            type: TOGGLE_CONFIG,
            payload: {open: !sideDrawer.open},
        });
    }
};

export const detectPeaks = (type) => async (dispatch, getState) => {
    const state = getState();
    const {context: queryContext} = state.query;
    const additionalQueries = selectAdditionalQueries(state);
    const linkedinChannelIds = selectLinkedinChannelIds(state);

    const {dateRange} = state.filters;

    if (dateRange.startDate === '' && dateRange.endDate === '') {
        await dispatch(initDateRange(dateRange?.timezone || queryContext.timezone));
    }

    const {queryUserData, filters} = getState();

    dispatch({
        type: TOGGLE_CONFIG,
        payload: false,
    });

    return resultService
        .detectPeaks({
            queryContext,
            type,
            filters,
            additionalQueries,
            linkedinChannelIds,
            breakdown: getCardBreakdownFromState(type, queryUserData[queryContext.savedSearchId]?.cardBreakdowns),
        })
        .then((data) => {
            if (data.length) {
                dispatch({
                    type: PEAKS_LOADED,
                    payload: {type, peaks: data},
                });
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

// Used in shared dashboard to replace the whole state with stored report state
export const replaceResultsState = (newState) => {
    return {
        type: REPLACE_RESULTS_STATE,
        payload: newState,
    };
};
