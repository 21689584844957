import {useCallback, useState} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';

import ActionsMenu from 'hsi/components/ActionsMenu';
import Button from 'hsi/components/Button';
import ConfirmSaveSearchDialog from './ConfirmSaveSearchDialog';
import IconRouter from 'hsi/components/IconRouter';

import useEventTrack from 'hsi/hooks/useEventTrack';

import {openSaveSearchDialog, updateSearch} from 'hsi/actions/savedSearchActions';
import {validateQuery} from 'hsi/services/apiv2/queryValidationService';
import {getSearchResultsUrl} from 'hsi/utils/url';
import {getHistoricDataStartDate} from 'hsi/utils';
import {parseErrors} from 'hsi/utils/api/errorParsing';

import {MAX_QUICK_SEARCH_LENGTH, MAX_EDIT_SEARCH_LENGTH} from 'hsi/constants/config';
import {T} from 'hsi/i18n';
import useStyles from './styles';

const EditSearchBar = ({savedSearch}) => {
    const classes = useStyles();
    const {trackWithSearchData} = useEventTrack();
    const [errors, setErrors] = useState(null);
    const [menuEl, setMenuEl] = useState(null);
    const [proposedSave, setProposedSave] = useState(null);
    const [showConfirmSave, setShowConfirmSave] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const dispatch = useDispatch();
    const {booleanQuery: query, validating} = useSelector((state) => state.query);
    const projects = useSelector((state) => state.user.projects);
    const saveAsNewEnabled = useSelector(
        (state) => state.user.usedQueries < state.user.account.client.queryLimit,
    );

    const saveChanges = useCallback(async () => {
        const startDate = getHistoricDataStartDate({
            timezone: savedSearch.project.timezone,
        });

        const {body: {response: result}} = await validateQuery(projects[0].id, query, true);

        if (
            result?.errors &&
            result?.errors?.length &&
            result?.errors?.[0] !== 'Proximity syntax not supported.'
        ) {
            setErrors(parseErrors(result.errors));
            trackWithSearchData('editSearchDialogSearchErrored', {
                errors: result.errors,
            });
        } else setErrors(null);

        setProposedSave({booleanQuery: query, startDate});
        setIsSaving(false);
        setShowConfirmSave(true);
    }, [projects, query, savedSearch, trackWithSearchData]);

    const cancelChanges = useCallback(() => {
        setProposedSave(null);
        setIsSaving(false);
        setShowConfirmSave(false);
    }, []);

    const toggleMenu = useCallback((e) => {
        setMenuEl(e.currentTarget);
    }, []);

    const closeMenu = useCallback(() => {
        setMenuEl(null);
    }, []);

    const saveAsNew = useCallback(() => {
        dispatch(openSaveSearchDialog(true));
    }, [dispatch]);

    const onConfirmedSave = useCallback(() => {
        setIsSaving(true);

        dispatch(updateSearch({queryId: savedSearch.id, projectId: savedSearch.projectId, newQueryProps: proposedSave}))
            .then((res) => {
                setIsSaving(false);
                trackWithSearchData('editSearchDialogSearchEdited', {
                    search: proposedSave,
                });
                setShowConfirmSave(false);
            })
            .catch(() => {
                setIsSaving(false);
                //handle error here?
            });
    }, [dispatch, proposedSave, savedSearch.id, savedSearch.projectId, trackWithSearchData]);

    const quickSearchEnabled = query?.length <= MAX_QUICK_SEARCH_LENGTH;
    const saveSearchOnly =
        MAX_QUICK_SEARCH_LENGTH < query?.length && query?.length <= MAX_EDIT_SEARCH_LENGTH;
    const searchDisabled = query?.length > MAX_EDIT_SEARCH_LENGTH;
    const savedQuery = savedSearch?.booleanQuery || '';
    const saveDisabled = !query || query === savedQuery || validating || searchDisabled;

    const editBarBg = () => {
        if (searchDisabled) return classes.errorBg;
        if (saveSearchOnly) return classes.warnBg;
        if (quickSearchEnabled) return classes.infoBg;
        return classes.infoBg;
    };

    return (
        <div id="editSearchBar" className={cn(classes.root, editBarBg())}>
            {searchDisabled && (
                <>
                    <div className={cn(classes.iconWrapper, classes.errorIconBg)}>
                        <IconRouter name="warning" />
                    </div>
                    <p>{T('editing.saved.search.tooLong')}</p>
                </>
            )}

            {saveSearchOnly && (
                <>
                    <div className={cn(classes.iconWrapper, classes.warnIconBg)}>
                        <IconRouter name="warning" />
                    </div>
                    <p>{T('editing.saved.search.characters')}</p>
                </>
            )}

            {quickSearchEnabled && (
                <>
                    <div className={cn(classes.iconWrapper, classes.infoIconBg)}>
                        <IconRouter name="info" />
                    </div>
                    <p>{T('editing.saved.search')}</p>
                </>
            )}

            <Link
                key={savedSearch.id}
                to={getSearchResultsUrl(savedSearch)}
                className={classes.exitLink}
            >
                <Button priority="secondary">{T('exit.edit.mode')}</Button>
            </Link>

            {!saveAsNewEnabled && (
                <Button onClick={saveChanges} priority="primary" disabled={saveDisabled}>
                    {T('save.changes')}
                </Button>
            )}

            {saveAsNewEnabled && (
                <ButtonGroup disabled={saveDisabled}>
                    <Button onClick={saveChanges} priority="primary" disabled={saveDisabled}>
                        {T('save.changes')}
                    </Button>
                    <Button size="small" onClick={toggleMenu} priority="primary">
                        <IconRouter name="chevron-down" className={classes.chevron} />
                    </Button>
                </ButtonGroup>
            )}

            <ActionsMenu
                id="actionsMenu"
                anchorEl={menuEl}
                open={!!menuEl}
                onClose={closeMenu}
                children={[
                    <MenuItem key="saveNewSearch" onClick={saveAsNew}>
                        {T('save.as.a.new.search')}
                    </MenuItem>,
                ]}
            />

            {showConfirmSave && (
                <ConfirmSaveSearchDialog
                    cancelChanges={cancelChanges}
                    canSave={!errors}
                    errors={errors}
                    onConfirmedSave={onConfirmedSave}
                    saveAsNew={!errors ? saveAsNew : undefined}
                    searchName={savedSearch.name}
                    showConfirmSave={showConfirmSave}
                    isSaving={isSaving}
                />
            )}
        </div>
    );
};

EditSearchBar.propTypes = {
    savedSearch: PropTypes.object.isRequired,
};

export default EditSearchBar;
