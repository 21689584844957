import { useMemo } from 'react';

// MUI
import Drawer from '@mui/material/Drawer';

//Components
import ToggleDrawerButton from 'hsi/components/ToggleDrawerButton';
import Heading, { HeadingContents, ResetHeadingLevel } from 'hsi/components/aria/Heading';
import TotalVolumeControls from './TotalVolumeControls';
import WordCloudControls from './WordCloudControls';

//Hooks
import { useAppSelector } from 'hsi/hooks/useRedux';

// Redux

// Types
import { CardType } from 'hsi/types/cards';

//Other
import { T } from 'hsi/i18n';

import useStyles from './styles';

//Types
export type ConfigSideDrawerProps = {
    onClose: () => void;
};

//The component
export default function ConfigSideDrawer({onClose}: ConfigSideDrawerProps) {
    const classes = useStyles();
    const {open, type} = useAppSelector(
        (state) => state?.results?.sideDrawer as {open: boolean; type: CardType},
    );

    const configControls = useMemo(() => {
        switch (type) {
            case 'wordCloud':
                return <WordCloudControls />;
            case 'totalVolume':
                return <TotalVolumeControls />;
            default:
                throw new Error('Unknown type for config controls');
        }
    }, [type]);

    return (
        <Drawer
            anchor="right"
            className={classes.drawer}
            data-testid="configSideDrawer"
            onClose={onClose}
            open={open}
            disablePortal={process.env.NODE_ENV !== 'test'} //This breaks the tests for some reason
        >
            <ResetHeadingLevel>
                <div className={classes.content}>
                    <Heading className={classes.title}>{T('configSideDrawer.title')}</Heading>
                    <HeadingContents>
                        <ToggleDrawerButton
                            drawerSide="right"
                            toggleDrawer={onClose}
                            open={open}
                            openLabel={''}
                            className={classes.closeBtn}
                        />
                        <div className={classes.controls}>{configControls}</div>
                    </HeadingContents>
                </div>
            </ResetHeadingLevel>
        </Drawer>
    );
}
