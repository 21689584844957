import {PayloadAction, createAction, createSlice} from '@reduxjs/toolkit';

import {
    DATA_LOADED,
    DATA_ABORTED,
    LOAD_ERROR,
    LOADING_DATA,
    ALL_RELOAD_SEARCH_DATA_ACTIONS,
} from 'hsi/constants/actionTypes';

import {CardDataType} from 'hsi/types/cards';
import {ChartKey} from 'hsi/types/charts';

export type DefaultChartStateType<T = any> = {
    data?: T;
    error: boolean;
    errorCode: string | null;
    loaded: boolean;
    loading: boolean;
};

export type ChartReducerStateType = {
    [Key in keyof CardDataType]-?: DefaultChartStateType<CardDataType[Key]>;
};

const defaultChartState: DefaultChartStateType = {
    data: undefined,
    error: false,
    errorCode: null,
    loaded: false,
    loading: false,
};

export const initialState: ChartReducerStateType = {
    benchmark: defaultChartState,
    benchmarkBySearch: defaultChartState,
    shareOfVoice: defaultChartState,
    emotionHistory: defaultChartState,
    emotionVolume: defaultChartState,
    gender: defaultChartState,
    geography: defaultChartState,
    heatmap: defaultChartState,
    mentionsHistory: defaultChartState,
    netSentimentHistory: defaultChartState,
    pageTypeBySearch: defaultChartState,
    sentimentHistory: defaultChartState,
    sentimentVolume: defaultChartState,
    sentimentVolumeBySearch: defaultChartState,
    topauthors: defaultChartState,
    topauthorsBySearch: defaultChartState,
    tophashtags: defaultChartState,
    tophashtagsBySearch: defaultChartState,
    topicWheel: defaultChartState,
    topinterests: defaultChartState,
    toplanguages: defaultChartState,
    topSharedURLs: defaultChartState,
    topsites: defaultChartState,
    topsitesBySearch: defaultChartState,
    toptopicsBySearch: defaultChartState,
    totalMentions: defaultChartState,
    totalVolume: defaultChartState,
    totalVolumeBySearch: defaultChartState,
    wordCloud: defaultChartState,
};

const slice = createSlice({
    name: 'chart',
    initialState,
    reducers: {
        replaceChartState: (state, action: PayloadAction<ChartReducerStateType>) => action.payload,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAction<{type: ChartKey}>(LOADING_DATA), (state, {payload: {type}}) => {
                state[type].loading = true;
                state[type].loaded = false;
            })
            .addCase(
                createAction<{type: ChartKey; data: any}>(DATA_LOADED),
                (state, {payload: {type, data}}) => {
                    state[type].data = data;
                    state[type].loading = false;
                    state[type].loaded = true;
                    state[type].error = false;
                    state[type].errorCode = null;
                },
            )
            .addCase(createAction<{type: ChartKey}>(DATA_ABORTED), (state, {payload: {type}}) => {
                state[type].loading = false;
            })
            .addCase(
                createAction<{type: ChartKey; errorCode: string}>(LOAD_ERROR),
                (state, {payload: {type, errorCode}}) => {
                    state[type].data = initialState[type].data;
                    state[type].error = true;
                    state[type].errorCode = errorCode;
                    state[type].loaded = true;
                    state[type].loading = false;
                },
            )

            .addMatcher(
                ({type}) => ALL_RELOAD_SEARCH_DATA_ACTIONS.includes(type),
                resetStateToInitial,
            );
    },
});

export const {replaceChartState} = slice.actions;
export default slice.reducer;

function resetStateToInitial(...args: any[]): ChartReducerStateType {
    return initialState;
}
