// TODO: Add tests
import {useCallback, useEffect, useState} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

import FacebookTokenService from 'hsi/services/facebookTokenService';
import * as TargetedDataService from 'hsi/services/targetedDataService';

import {toggleAddOwnedDialog, toggleFacebookLoginDialog} from 'hsi/slices/facebookAuthentication';
import {showNotification} from 'hsi/actions/notificationsActions';

import {FB_APP_SCOPE} from 'hsi/constants/config';
import * as queryKeys from 'hsi/constants/queryKeys';
import {formatTrackableOwnedAuthentications} from 'hsi/utils/dataManagement/formatAuthentications';
import {T} from 'hsi/i18n';

const FB_APP_VERSION = 'v13.0';

const useFacebookLogin = () => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const clientId = useAppSelector((state) => state.user.account!.client.id);
    const {FB_APP_ID: facebookAppId} = useAppSelector((state) => state.user.settings);

    const [accessToken, setAccessToken] = useState<string>();
    const [error, setError] = useState<any>();
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const getTrackableOwnedAuthentications = useCallback(
        async (accessToken?: string) => {
            if (!accessToken) {
                throw new Error('No access token provided');
            }
            // TODO: Change this to useFacebookUserAuthentications
            await FacebookTokenService.storeUserToken(clientId.toString(), accessToken, facebookAppId);

            const response = await TargetedDataService.addFacebookAuthentications(
                facebookAppId,
                accessToken,
            );

            return formatTrackableOwnedAuthentications(response);
        },
        [clientId, facebookAppId],
    );

    const trackableOwnedAuthentications = useQuery({
        // TODO: We shouldn't need this but it breaks when you add accessToken. Needs more investigation
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [queryKeys.TRACKABLE_OWNED_AUTHENTICATIONS],
        queryFn: () => getTrackableOwnedAuthentications(accessToken),
        enabled: !!accessToken,
        onError: (error) => {
            console.log('error adding authentications', error);
            dispatch(
                showNotification({
                    message: T('authTokens.tokensPage.unknownError'),
                    variant: 'warning',
                }),
            );
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries([queryKeys.FACEBOOK_USER_AUTHENTICATIONS]);

            if (!data?.length) {
                dispatch(
                    showNotification({
                        message: T('authTokens.tokensPage.userTokenSaved'),
                        variant: 'success',
                        title: T('authTokens.tokensPage.successTitle'),
                    }),
                );
            } else {
                queryClient.invalidateQueries([queryKeys.FACEBOOK_PAGE_AUTHENTICATIONS]);
                queryClient.invalidateQueries([queryKeys.INSTAGRAM_ACCOUNT_AUTHENTICATIONS]);
                dispatch(toggleAddOwnedDialog(true));
            }
        },
    });

    const login = useCallback(
        (onLogin?: () => void) => {
            setError(undefined);
            setLoaded(false);
            setLoading(true);

            window.FB.login(
                 (response) => {
                    try {
                        queryClient.invalidateQueries([queryKeys.TRACKABLE_OWNED_AUTHENTICATIONS]);
                        setAccessToken(response?.authResponse?.accessToken);
                    } catch (error) {
                        setError(error);
                        dispatch(
                            showNotification({
                                message: T('authTokens.tokensPage.fbLoginError'),
                                variant: 'warning',
                            }),
                        );
                    } finally {
                        setLoaded(true);
                        setLoading(false);

                        //This is a very ugly workaround to fix a race condition introduced with react 18
                        setTimeout(() => {
                            onLogin?.();
                            dispatch(toggleFacebookLoginDialog(false));
                        }, 100);
                    }
                },
                {
                    scope: FB_APP_SCOPE,
                    return_scopes: true,
                },
            );
        },
        [dispatch, queryClient],
    );

    useEffect(() => {
        if (!window.fbAsyncInit) {
            window.fbAsyncInit = () => {
                window.FB?.init({
                    appId: facebookAppId,
                    version: FB_APP_VERSION,
                });
            };

            window.fbAsyncInit();
        }
    }, [facebookAppId]);

    return {error, loaded, loading, login, trackableOwnedAuthentications};
};

export default useFacebookLogin;
