//TODO Combine into a single component, and use Context.Provider? Actually no, ReportsProvider is not a context,
//it is doing... something? I need to investigate
import React, {useMemo, useReducer} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import Button, {ButtonProps} from 'hsi/components/Button';
import BuildHSIReportModal from 'hsi/components/BuildHSIReportModal';
import ExportWithNotesModal, {ReportModalSections} from 'hsi/components/ExportWithNotesModal';
import EditReportModal from 'hsi/containers/Reports/EditReportModal';
import SimpleMenu, {SimpleMenuEntry} from 'hsi/components/SimpleMenu';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useReports, {ReportsProvider} from 'hsi/hooks/useReports';
import useQueryContext from 'hsi/hooks/useQueryContext';
import useIsMultipleSearch from 'hsi/hooks/useIsMultipleSearch';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';

//Other
import {T} from 'hsi/i18n';

//Types
type CreateReportModalProps = {} & Omit<ButtonProps, 'onClick' | 'priority' | 'endIcon' | 'ref'>;

type ReportTypes = 'HSIReport' | 'ExportWithNotes' | false;

// Action types
type OPEN_HSI_REPORT = {type: 'OPEN_HSI_REPORT'};
type CREATE_SNAPSHOT = {type: 'CREATE_SNAPSHOT'};
type VIEW_REPORTS_LIST = {type: 'VIEW_REPORTS_LIST'};
type CLOSE_MODAL = {type: 'CLOSE_MODAL'};
type OpenReportActions = OPEN_HSI_REPORT | CREATE_SNAPSHOT | VIEW_REPORTS_LIST | CLOSE_MODAL;

type OpenModalState = {isReportOpen: ReportTypes; openWithSection: ReportModalSections};

const initialState: OpenModalState = {
    isReportOpen: false,
    openWithSection: 'createReport',
};

const openModalReducer = (state: OpenModalState, action: OpenReportActions): OpenModalState => {
    switch (action.type) {
        case 'OPEN_HSI_REPORT':
            return {
                ...state,
                isReportOpen: 'HSIReport',
                openWithSection: undefined,
            };
        case 'CREATE_SNAPSHOT':
            return {
                ...state,
                isReportOpen: 'ExportWithNotes',
                openWithSection: 'createReport',
            };
        case 'VIEW_REPORTS_LIST':
            return {
                ...state,
                isReportOpen: 'ExportWithNotes',
                openWithSection: 'viewReports',
            };
        case 'CLOSE_MODAL':
            return initialState;
        default:
            return initialState;
    }
};

const CreateReportModal = React.forwardRef<HTMLButtonElement, CreateReportModalProps>(
    function CreateReportModal(props, ref) {
        const {
            createReport: {hasExternalReport},
        } = useConfig();
        const isMultipleSearch = useIsMultipleSearch();
        const {
            actions: {
                openModal: openScheduleEmailReportsModal,
                createReport: createScheduleEmailReport,
            },
        } = useReports();
        const queryContext = useQueryContext();
        const {track} = useEventTrack();
        const {isSharableDashboardsEnabled} = useFlags();

        const [state, dispatch] = useReducer(openModalReducer, initialState);


        const isScheduleReportEnabled = !isMultipleSearch;

        const entries = useMemo(() => {
            const entries: SimpleMenuEntry[] = [];

            if (hasExternalReport) {
                entries.push({
                    label: T('savedsearchbar.buildreport.tooltip'),
                    onClick: () => dispatch({type: 'OPEN_HSI_REPORT'}),
                });
            }

            entries.push({
                label: isSharableDashboardsEnabled
                    ? T('savedsearchbar.buildreport.reportOptions.exportToPDFWithNotes')
                    : T('savedsearchbar.buildreport.reportOptions.exportAsPDF'),
                onClick: () => {
                    track('snapshotExportClicked', {
                        copy: isSharableDashboardsEnabled
                            ? T('savedsearchbar.buildreport.reportOptions.exportToPDFWithNotes')
                            : T('savedsearchbar.buildreport.reportOptions.exportAsPDF'),
                    });
                    dispatch({type: 'CREATE_SNAPSHOT'});
                },
            });

            entries.push({
                label: T('savedsearchbar.buildreport.reportOptions.scheduleReport'),
                onClick: () => {
                    openScheduleEmailReportsModal();
                    track('reportScheduleOpen');
                },
                disabled: !isScheduleReportEnabled,
                tooltip: !isScheduleReportEnabled
                    ? T('savedsearchbar.scheduleReportTooltip')
                    : undefined,
            });

            if (isSharableDashboardsEnabled) {
                entries.push({divider: true});

                entries.push({
                    label: T('savedsearchbar.buildreport.reportOptions.viewReports'),
                    onClick: () => {
                        dispatch({type: 'VIEW_REPORTS_LIST'});
                    },
                });
            }

            return entries;
        }, [
            hasExternalReport,
            isScheduleReportEnabled,
            isSharableDashboardsEnabled,
            openScheduleEmailReportsModal,
            track,
        ]);

        return (
            <>
                <SimpleMenu
                    title={'Export this card as: '}
                    hideTitle
                    entries={entries}
                    buttonComponent={
                        <Button {...props} priority="cta" endIcon={<ExpandMoreIcon />} ref={ref}>
                            {T('savedsearchbar.buildreport.button')}
                        </Button>
                    }
                    onOpen={() => track('reportCreateClicked')}
                    //menuDistance={8}
                />

                <EditReportModal
                    acceptButtonText={T('reports.editReportModal.createScheduleAcceptButton')}
                    onAccept={createScheduleEmailReport}
                    titleText={T('reports.editReportModal.createScheduleTitle')}
                />

                <BuildHSIReportModal
                    open={state.isReportOpen === 'HSIReport'}
                    closeFunc={() => dispatch({type: 'CLOSE_MODAL'})}
                    search={queryContext}
                />

                {queryContext?.savedSearchId && queryContext?.projectId && state.isReportOpen && (
                    <ExportWithNotesModal
                        handleClose={() => dispatch({type: 'CLOSE_MODAL'})}
                        isMultipleSearch={isMultipleSearch}
                        isOpen={state.isReportOpen === 'ExportWithNotes'}
                        savedSearchName={queryContext.savedSearch!.name}
                        savedSearchId={queryContext.savedSearchId}
                        projectId={queryContext.projectId}
                        openWithSection={state.openWithSection}
                    />
                )}
            </>
        );
    },
);

export default React.forwardRef<HTMLButtonElement, CreateReportModalProps>(function CreateReport(
    props,
    ref,
) {
    return (
        <ReportsProvider {...props}>
            <CreateReportModal {...props} ref={ref} />
        </ReportsProvider>
    );
});
