import { SnapshotList } from 'hsi/types/snapshots';
import type {SortOption } from './types';

export const SORT_OPTIONS: readonly SortOption[] = [
    {value: 'name_asc', label: 'Name (A-Z)'},
    {value: 'name_desc', label: 'Name (Z-A)'},
    {value: 'snapshot_date_desc', label: 'Snapshot Date (Most Recent First)'},
    {value: 'snapshot_date_asc', label: 'Snapshot Date (Oldest First)'},
] as const;

export const sortSnapshots = (reports: SnapshotList, sortBy: string) => {
    return [...reports].sort((a, b) => {
        switch (sortBy) {
            case 'name_asc':
                return a.name.localeCompare(b.name);
            case 'name_desc':
                return b.name.localeCompare(a.name);
            case 'snapshot_date_desc':
                return (
                    new Date(b.creation_date).getTime() -
                    new Date(a.creation_date).getTime()
                );
            case 'snapshot_date_asc':
                return (
                    new Date(a.creation_date).getTime() -
                    new Date(b.creation_date).getTime()
                );
            default:
                return 0;
        }
    });
};

export const formatDateRange = (
    startDate: string,
    endDate: string,
    formatFn: (date: string) => string,
): string => {
    return `${formatFn(startDate)} - ${formatFn(endDate)}`;
};
