import {useMemo} from 'react';
import localForage from 'localforage';

//Hooks
import {useAppSelector} from './useRedux';

export default function usePersistLocal() {
    const userId: number = useAppSelector((state) => state.user.account!.id);
    const persistId = `${userId}`;

    return useMemo(
        () => ({
            getItem: <T = any>(key: string) => localForage.getItem<T>(`${persistId}::${key}`),
            setItem: <T = any>(key: string, value: T) =>
                localForage.setItem(`${persistId}::${key}`, value),
        }),
        [persistId],
    );
}
