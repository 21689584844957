import {makeStyles} from '@mui/styles';
import {Theme} from 'hsi/types/theme';

export default makeStyles((theme: Theme) => ({
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
    },
    passwordField: {
        width: '100%',
        padding: '24px 8px',
    },
}));
