import {push} from 'redux-first-history';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import isBCR from 'hsi/utils/isBCR';
import {serializeSearch} from 'hsi/utils/url';

import {
    CLEAR_ERROR,
    DELETE_SEARCH,
    SET_SEARCHES_LOADING,
    LOAD_SEARCHES,
    MORE_PROJECT_QUERIES_LOADED,
    OPEN_SAVE_SEARCH_DIALOG,
    OPEN_EDIT_SAVE_SEARCH_DIALOG,
    OPEN_EDIT_SAVE_SEARCH,
    PROJECT_QUERY_AND_GROUPS_LOADED,
    SAVE_SEARCH_ERROR,
    SAVE_SEARCH_START,
    SAVE_SEARCH_SUCCESS,
    SEARCH_LOADED,
    SET_SEARCH_LOADING,
    UPDATE_SEARCH_START,
    UPDATE_SEARCH_SUCCESS,
    UPDATE_SEARCH_ERROR,
} from 'hsi/constants/actionTypes';

import {showNotification} from 'hsi/actions/notificationsActions';
import {setSavedSearchQueries, saveActiveQuery, persistActiveQuery} from 'hsi/actions/queryActions';
import {clearResults} from 'hsi/actions/resultsActions';
import savedSearchService from 'hsi/services/savedSearchService';
import {createQuery, updateQuery} from 'hsi/services/apiv2/savedSearchService'
import queryService from 'hsi/services/queryService';
import {updateUsedQueries} from 'hsi/actions/userActions';

import {T} from 'hsi/i18n';
import { getAllQueries } from 'hsi/services/apiv2/userService';
import { isBCRApiError } from 'hsi/types/shared';

const SEARCH_URL = '/search/results/';

export const setSearchLoading = (loading) => ({
    type: SET_SEARCH_LOADING,
    payload: loading,
});

export const loadSearches = () => async (dispatch, getState) => {
    const {
        user: {projects},
    } = getState();

    dispatch({
        type: SET_SEARCHES_LOADING,
        payload: true,
    });

    try {
        const allQueriesResult = await getAllQueries();

        if(!allQueriesResult.ok) {
            dispatch(
                showNotification({
                    message: T('error.loadSearches'),
                    variant: 'warning',
                }),
            );
    
            return;
        }

        const projectsById = projects.reduce((output, project) => {
            output[project.id] = project;

            return output;
        }, {})

        //PP: I dislike that we do this, but it is too big a change to fix this, so I'm leaving it for now.
        allQueriesResult.body.response.results.forEach((query) => {
            query.project = projectsById[query.projectId] ?? null;
        })

        dispatch({
            type: LOAD_SEARCHES,
            payload: allQueriesResult.body.response,
        });
        dispatch(setSavedSearchQueries(allQueriesResult.body.response));
        dispatch({
            type: SET_SEARCHES_LOADING,
            payload: false,
        });
    }
    catch(e) {
        dispatch(
            showNotification({
                message: T('error.loadSearches'),
                variant: 'warning',
            }),
        );
        return;
    }
};

export const loadSearch = (searchId) => async (dispatch, getState) => {
    return savedSearchService.loadSearch(searchId).then((savedSearch) => {
        dispatch(searchLoaded(savedSearch));
    });
};

export const searchLoaded = (payload) => ({type: SEARCH_LOADED, payload});

export const openSaveSearchDialog = (val) => ({
    type: OPEN_SAVE_SEARCH_DIALOG,
    payload: val,
});

export const openEditSavedSearchDialog = (isOpen, queryId) => ({
    type: OPEN_EDIT_SAVE_SEARCH_DIALOG,
    payload: {isOpen, queryId},
});

export const clearError = () => ({type: CLEAR_ERROR});

export const deleteSearch = (search) => async (dispatch) => {
    try {
        await savedSearchService.deleteSearch(search).then((res) => {
            dispatch({
                type: DELETE_SEARCH,
                payload: res,
            });
            dispatch(updateUsedQueries());
            dispatch(loadSearches());
        });
        await queryService.deleteByBCRQueryId(search.id);
    } catch (e) {
        console.log(e);
        dispatch(
            showNotification({
                message: T('error.deleteQuery'),
                variant: 'warning',
            }),
        );
    }
};

export const saveSearch = (search, onSuccess) => async (dispatch, getState) => {
    const {
        user: {projects},
    } = getState();
    dispatch({type: SAVE_SEARCH_START});
    const currProject = find(projects, (p) => p.id === search.teamId);

    if(!currProject) {
        throw new Error('Unable to create query, unknown project id');
    }

    try {
        const result = await createQuery(currProject.id, {
            name: search.name,
            booleanQuery: search.query,
            description: search.description ?? '',
            startDate: search.startDate,
        });

        if (isBCRApiError(result.body.response)) {
            dispatch({
                type: SAVE_SEARCH_ERROR,
                payload: result.body.response.errors[0],
            });
        } else {
            const newQuery = result.body.response;
            const savedSearch = {...newQuery, project: currProject};
            dispatch(openSaveSearchDialog(false));
            dispatch({
                type: SAVE_SEARCH_SUCCESS,
                payload: savedSearch,
            });
            dispatch(saveActiveQuery(savedSearch.id));
            dispatch(push(`${SEARCH_URL}${currProject.id}/${newQuery.id}`));
            dispatch(showNotification({message: T('search.saved'), variant: 'success'}));
            dispatch(updateUsedQueries());

            onSuccess?.(savedSearch);
        }
    } catch (error) {
        console.log('error', error);
        dispatch({
            type: SAVE_SEARCH_ERROR,
            payload: error,
        });
    }
};

export const updateSearch = (props) => (dispatch, getState) => {
    const {queryId, projectId, newQueryProps} = props;
    const project = getState().user.projects.find(({id}) => id === projectId)

    if(!project) {
        throw new Error(`Unable to update query, unknown projectId: ${projectId}`);
    }

    dispatch({type: UPDATE_SEARCH_START, payload: props});

    return updateQuery(projectId, queryId, newQueryProps)
        .then((result) => {
            if (isBCRApiError(result.body.response)) {
                //TODO, right now, we do nothing with this
                dispatch({
                    type: UPDATE_SEARCH_ERROR,
                    payload: result.body.response.errors[0],
                });
            } else {
                //query updated successfully
                
                dispatch(openSaveSearchDialog(false));

                dispatch(persistActiveQuery());
                dispatch(clearResults());
                dispatch({
                    type: UPDATE_SEARCH_SUCCESS,
                    payload: {...result.body.response, project},
                });

                const message = isBCR() ? T('query.edited') : T('search.edited');
                dispatch(showNotification({message: message, variant: 'success'}));
            }
        })
        .catch((e) => {
            //TODO, right now, we do nothing with this
            dispatch({
                type: UPDATE_SEARCH_ERROR,
                payload: e?.body?.message || e?.toString() || 'Unknown error',
            });
        });
};

export const loadProjectQueries = (pid) => async (dispatch, getState) => {
    const project = find(getState().user.projects, (p) => p.id === pid);
    savedSearchService.loadProjectQueries(pid).then((response) => {
        dispatch({
            type: MORE_PROJECT_QUERIES_LOADED,
            payload: response.results.map((query) => {
                query.project = project;
                return query;
            }),
        });
    });
};

export const loadProjectQueriesAndQueryGroups =
    (projectId, showLoading = true) =>
    async (dispatch, getState) => {
        dispatch(setSearchLoading(showLoading));
        const project = find(getState().user.projects, (p) => p.id === projectId);
        await Promise.all([
            savedSearchService.loadProjectQueries(projectId),
            savedSearchService.loadProjectQueryGroup(projectId),
        ]).then((responses) => {
            const {results: queries} = responses[0];
            const {results: queryGroups} = responses[1];

            queryGroups.forEach((qg) => {
                qg.isGroup = true;
            });
            const items = sortBy(
                [...queries, ...queryGroups].map((item) => {
                    item.project = project;
                    return item;
                }),
                'name',
            );

            dispatch({
                type: PROJECT_QUERY_AND_GROUPS_LOADED,
                payload: {projectId, items},
            });
        });
    };

export const openEditSearch = (savedSearch) => (dispatch, getState) => {
    dispatch({type: OPEN_EDIT_SAVE_SEARCH});
    dispatch(
        push({
            pathname: `/search/edit/${savedSearch.project.id}/${savedSearch.id}`,
            search: serializeSearch({query: savedSearch.booleanQuery}),
        }),
    );
};
