import {PayloadAction, createAction, createSlice} from '@reduxjs/toolkit';
import { LOAD_SEARCHES } from 'hsi/constants/actionTypes';
import { GetAllQueriesResponse } from 'hsi/services/apiv2/userService';
import { QueryData, QueryDataKeys } from 'hsi/types/queryData';
import { Nullable } from 'hsi/types/shared';
import { WritableDraft } from 'immer/dist/types/types-external';

const initialState: Record<number, Partial<Nullable<QueryData>>> = {};

const slice = createSlice({
    name: 'queryData',
    initialState,
    reducers: {
        updateQueryData<T extends QueryDataKeys>(state: WritableDraft<typeof initialState>, {payload: {queryId, key, value}}: PayloadAction<{queryId: number, key: T, value: QueryData[T] | null}>) {
            if(!state[queryId]) {
                state[queryId] = {};
            }

            state[queryId][key] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAction<GetAllQueriesResponse>(LOAD_SEARCHES), (state, {payload: {queryData}}) => {
                return queryData.reduce<Record<number, Partial<Nullable<QueryData>>> >((output, {queryId, queryData}) => {
                    if(queryId !== null) {
                        output[queryId] = queryData ?? {};
                    }

                    return output;
                }, {});
            });
    }
});

export const {updateQueryData} = slice.actions;
export default slice.reducer;
