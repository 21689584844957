import {CCFlagsType} from 'hsi/types/cc-flags';
import {PageTypeDefinitionType} from 'hsi/types/shared';

function checkCCFlag(flags: CCFlagsType | undefined, flag: keyof CCFlagsType) {
    return !flags || !!flags[flag];
}

const pageTypes: PageTypeDefinitionType[] = [
    {
        value: 'twitter',
        iconName: 'x-twitter',
        isAvailable: (_, flags) => checkCCFlag(flags, 'twitterDataSourceEnabled'),
    },
    {
        value: 'facebook',
        isAvailable: ({isSuccess, hasFacebookPages}) => {
            if (!isSuccess) return true;
            else return hasFacebookPages ?? true;
        },
        iconName: 'facebook',
    },
    {
        value: 'facebook_public',
        iconName: 'facebook',
    },
    {
        value: 'instagram',
        isAvailable: ({isSuccess, hasInstagramAccounts}) => {
            if (!isSuccess) return true;
            else return hasInstagramAccounts ?? true;
        },
        iconName: 'instagram',
    },
    {
        value: 'instagram_public',
        isAvailable: (_, flags, isSavedSearch) => {
            if (checkCCFlag(flags, 'instagramPublicDataSourceEnabled')) {
                if (!isSavedSearch) {
                    return checkCCFlag(flags, 'instagramPublicEnabledForQuickSearch');
                }
                return true;
            }
            return false;
        },
        iconName: 'instagram',
    },
    {
        value: 'tumblr',
        iconName: 'tumblr',
    },
    {
        value: 'forum',
        iconName: 'forums',
    },
    {
        value: 'youtube',
        iconName: 'youtube',
    },
    {
        value: 'reddit',
        iconName: 'reddit',
    },
    {
        value: 'news',
        iconName: 'news',
        isAvailable: (_, flags) => checkCCFlag(flags, 'isNewsContentSourceEnabled'),
    },
    {
        value: 'blog',
        iconName: 'blogs',
    },
    {
        value: 'review',
        iconName: 'reviews',
    },
    {
        value: 'qq',
        iconName: 'qq',
    },
    {
        value: 'linkedin',
        iconName: 'linkedin',
        isAvailable: (targetedDataAvailability, flags, isSavedSearch, projectId) =>
            // TODO: isSavedSearch is not guarenteed to be up to date so we can't use this check here
            // For the filter checkboxes it's actually overridden here: useTempUpdateFilterOptions
            // (!projectId || !!isSavedSearch) &&
            targetedDataAvailability?.hasLinkedinChannels ?? true,
    },
    {
        value: 'bluesky',
        isAvailable: (_, flags) => checkCCFlag(flags, 'blueskyDataSourceEnabled'),
        iconName: 'bluesky',
    },
];

export default pageTypes;
