// TODO: Swap to use useLinkedinChannels add function
// TODO: Add tests
import {useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';

import useEventTrack from 'hsi/hooks/useEventTrack';
import useProjectsById from 'hsi/hooks/useProjectsById';
import {useAppDispatch} from 'hsi/hooks/useRedux';

import {showNotification} from 'hsi/actions/notificationsActions';

import * as targetedDataService from 'hsi/services/targetedDataService';

import * as queryKeys from 'hsi/constants/queryKeys';

import tns from 'hsi/i18n/tns';
const t = tns('addTargetedDataDialog.LinkedinChannel');

const channelExistsError = 'Query name is not unique:';
const orgAlreadyAddedError =
    'LinkedIn Organization is already associated with a channel for this project';
const hasError = (err: string, value: string) => err.toLowerCase().includes(value.toLowerCase());

const getChannelCreationError = (res: any, channelName: string) => {
    const error = res?.errors?.[0]?.message;
    if (typeof error !== 'string') return null;
    if (hasError(error, channelExistsError))
        return t('notifications.channelCreationError.channelExistsMessage', {name: channelName});
    if (hasError(error, orgAlreadyAddedError))
        return t('notifications.channelCreationError.orgAlreadyAddedMessage');
    return t('notifications.channelCreationError.genericMessage');
};

const errorNotification = (message: string) =>
    showNotification({
        title: t('notifications.channelCreationError.title'),
        message,
        variant: 'warning',
    });

const successNotification = (name: string, team: string) =>
    showNotification({
        title: t('notifications.channelCreationSuccess.title'),
        message: t('notifications.channelCreationSuccess.message', {
            name,
            team,
        }),
        variant: 'success',
    });

type UseCreateChannelProps = {
    onClose?: () => void;
    org: string;
    setLoading: (value: boolean) => void;
};

const useCreateChannel = ({onClose, org, setLoading}: UseCreateChannelProps) => {
    const dispatch = useAppDispatch();
    const {track} = useEventTrack();
    const projectsById = useProjectsById();
    const queryClient = useQueryClient();

    return useCallback(
        async ({channelName, projectId}: {channelName: string; projectId: string}) => {
            setLoading(true);
            const res = await targetedDataService.createLinkedinChannel({
                org,
                channelName,
                projectId,
            });
            setLoading(false);

            const err = getChannelCreationError(res, channelName);
            if (err) return dispatch(errorNotification(err));

            dispatch(successNotification(channelName, projectsById[Number(projectId)]?.name));

            track('targetedDataSourceAdded', {
                type: 'Owned Linkedin Page',
            });

            queryClient.invalidateQueries([queryKeys.LINKEDIN_CHANNELS]);

            onClose?.();
        },
        [setLoading, org, dispatch, projectsById, track, queryClient, onClose],
    );
};

export default useCreateChannel;
