// TODO: Add tests
import {useQuery} from '@tanstack/react-query';
import {useAppSelector} from 'hsi/hooks/useRedux';

import FacebookTokenService from 'hsi/services/facebookTokenService';

import {FACEBOOK_INVITE_URL} from 'hsi/constants/queryKeys';

const useFacebookInviteUrl = () => {
    const userSettings = useAppSelector((state) => state.user.settings);
    const clientId = useAppSelector((state) => state.user.account!.client.id);

    const inviteUrl = useQuery({
        queryKey: [FACEBOOK_INVITE_URL, clientId],
        queryFn: () => FacebookTokenService.getInviteUrl(clientId.toString()),
        select: (result) => {
            if (!result?.invitePath) {
                throw new Error('No invite path');
            }

            return new URL(result.invitePath, userSettings.BCR_URL).toString();
        },
    });

    return inviteUrl;
};

export default useFacebookInviteUrl;
