import {
    LOG_IN,
    LOG_OUT,
    LOG_IN_LOADING,
    SET_USED_QUERIES,
    SET_USER_LINK_PENDING,
    SET_PACKAGE_USAGE,
    SET_LOGIN_LOADING_MSG,
    OPEN_IMPERSONATION_DIALOG,
    SET_IMPERSONATION_LOADING,
    REPLACE_USER_STATE,
} from 'hsi/constants/actionTypes';
import {ISO8601String, ProjectType} from 'hsi/types/shared';
import eventTracking from 'hsi/utils/mixpanel';
import {AnyAction} from 'redux';

type UIRole = 'regular' | 'admin' | 'superadmin' | 'view';

type Client = {
    id: number;
    name: string;
    theme: string; // e.g. 'hootsuite-insights',
    parentId: number;
    billableClientId: number;
    salesforceId: unknown | null;
    userInactivityTimeoutMinutes: number;
    queryLimit: number;
    mentionLimit: unknown | null; // probably a number?
    monthlyMentionsLimit: unknown | null; // probably a number?
    monthlyBackfillMentionsLimit: unknown | null; // probably a number?
    startDate: ISO8601String;
    crimsonMode: boolean;
    agency: boolean;
    modules: string[];
    clientPackageId: number;
    clientPackageName: string;
    monthsHistoricData: number;
    monthsRollingData: number;
    nonTwitterChannelLimit: number;
    twitterChannelLimit: number;
    excludedStandardContentSources: unknown[]; //<< I think this is a way to prevent use of certain content sources?
    maxSampledVolume: number;
    dailyMentionExportLimit: number;
    earliestQueryStartDate: ISO8601String; //TODO we should probably be using this?
    internal: boolean;
    engagementMetricConfig: {
        metricId: string;
        contentSource: string; //FACEBOOK | REDDIT | TWITTER | LINKEDIN
        label: string;
        weight: number;
        active: boolean;
    }[];
    openAiConsentGiven: boolean;
    totalEngagementEnabled: boolean;
};

type Account = {
    id: number;
    username: string;
    uiRole: UIRole;
    firstName: string;
    /** e.g. en-GB */
    uiLanguage: string;
    customRoleId: number;
    customRoleName: string;
    missionControlRoleId: number;
    missionControlRoleName: string;
    tags: unknown;
    passwordExpiryDate: null | ISO8601String;
    creationDate: ISO8601String;
    learnabilityLoginFormStatus: string;
    lastName: string;
    defaultProjectId: number;
    userEmail: string;

    client: Client;
    token: {
        authorities: string[];
        external: boolean;
    };

    //Something to do with churnzero?
    sourceId?: string;
    sourceEmail?: string;
    isFr: string;
};

export type UserState = {
    isLoggedIn: null | boolean;
    loginLoading: boolean;
    loginLoadingMessage: null | string;
    projects: ProjectType[] | null;
    packageUsage: unknown; //TODO {}?
    usedQueries: number;
    openImpersonationDialog: boolean;
    impersonationLoading: boolean;

    account: Account | undefined;
    settings: Record<string, string>;
    defaultProject: ProjectType | null;

    isViewOnly: boolean;
    canSaveSearch: boolean;
    linkPending: boolean;
    sessionStart: null | ISO8601String;
    canImpersonate: boolean;
    isImpersonated: boolean;

    // Apparently we can also have these props... somehow?
    // This... is a mess.
    errors?: any;
    errorCode?: any;
    status?: any;
};

const initialState: UserState = {
    isLoggedIn: null,
    loginLoading: true,
    loginLoadingMessage: null,
    projects: null,
    packageUsage: {},
    usedQueries: 0,
    openImpersonationDialog: false,
    impersonationLoading: false,

    account: undefined,
    settings: {},
    defaultProject: null,
    isViewOnly: false,
    canSaveSearch: false,
    linkPending: false,
    sessionStart: null,
    canImpersonate: false,
    isImpersonated: false,
};

const userReducer = (state: UserState = initialState, action: AnyAction): UserState => {
    switch (action.type) {
        case LOG_IN_LOADING:
            return {
                ...state,
                loginLoading: action.payload,
            };
        case LOG_IN:
            const account = action.payload.account;
            if (action && action.payload && action.payload.account) {
                eventTracking.register({
                    email: account.username,
                    client: account.client.name,
                    clientId: account.client.id,
                    source: (window as any).APP_SOURCE,
                });
                eventTracking.identify(account.id);

                eventTracking.people.set({
                    distinct_id: account.id,
                    email: account.username,
                    first_name: account.firstName,
                    last_name: account.lastName,
                    client: account.client.name,
                    language: account.uiLanguage,
                    created: account.creationDate,
                    role: account.uiRole,
                });

                if (
                    (window as any).APP_SOURCE === 'FALCON' &&
                    typeof (window as any).analytics !== 'undefined'
                ) {
                    (window as any).analytics.identify(
                        account.id,
                        {
                            email: account.username,
                            createdAt: account.creationDate,
                            firstName: account.firstName,
                            lastName: account.lastName,
                            client: account.client.name,
                        },
                        {
                            integrations: {
                                All: false,
                                Appcues: true,
                            },
                        },
                    );
                }
            }
            return {
                ...state,
                ...action.payload,
            };
        case LOG_OUT:
            return {
                ...initialState,
                isLoggedIn: false,
                loginLoading: false,
                projects: [],
            };

        case SET_LOGIN_LOADING_MSG:
            return {
                ...state,
                loginLoadingMessage: action.payload,
            };

        case SET_USED_QUERIES:
            return {
                ...state,
                usedQueries: action.payload.queryCount,
            };
        case SET_USER_LINK_PENDING:
            return {
                ...state,
                loginLoading: false,
                linkPending: action.payload,
                isLoggedIn: action.payload === true ? false : state.isLoggedIn,
            };
        case SET_PACKAGE_USAGE:
            return {...state, packageUsage: action.payload};
        case OPEN_IMPERSONATION_DIALOG:
            return {...state, openImpersonationDialog: action.payload};
        case SET_IMPERSONATION_LOADING:
            return {...state, impersonationLoading: action.payload};
        case REPLACE_USER_STATE: //?????
            return action.payload;
        default:
            return state;
    }
};

export default userReducer;
