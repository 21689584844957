import {useMemo} from 'react';

import {T} from 'hsi/i18n';
import {ColumnDefinition} from 'hsi/components/table/ManagedTable/types';
import {TDateISO} from 'hsi/types/dates';
import {formatTo} from 'hsi/utils/dates';
import {Link} from 'react-router-dom';

type reportListRow = {
    creation_date: TDateISO;
    url: string;
    name: string;
    id: string;
};
type Columns = keyof reportListRow;
type TableColumnDefinition = ColumnDefinition<Columns>;

export default function useGetTableColumns() {
    return useMemo<TableColumnDefinition[]>(() => {
        const columns = [
            {
                name: 'name',
                label: T('exportReports.list.table.name'),
                width: '2fr',
                horizontalAlign: 'start',
                format: (row: reportListRow) => <span>{row.name}</span>,
            } as TableColumnDefinition,
            {
                name: 'creation_date',
                label: T('exportReports.list.table.date'),
                horizontalAlign: 'start',
                width: '1fr',
                sortable: true,
                format: (row: reportListRow) => (
                    <span>{formatTo(row.creation_date, undefined, 'MMM d, h:mm a ZZZZ')}</span>
                ),
            } as TableColumnDefinition,
            {
                name: 'url',
                label: T('exportReports.list.table.url'),
                width: '2fr',
                horizontalAlign: 'start',
                format: (row: reportListRow) => {
                    const path = `/shared-dashboard/${row.id}`;
                    return (
                        <Link to={path} target="_blank" rel="noopener noreferrer">
                            {`${window.location.origin}${path}`}
                        </Link>
                    );
                },
            } as TableColumnDefinition,
        ];

        return columns;
    }, []);
}
