import http from 'hsi/classes/Http';
import { ApiResponse } from 'hsi/types/apiv2';
import { ApiQueryPersistedData } from 'hsi/types/query';
import { SavedSearchType } from 'hsi/types/shared';

export type GetAllQueriesResponse = {results: SavedSearchType[], queryData: ApiQueryPersistedData[]}


export async function getAllQueries() {
    return http.get<ApiResponse<GetAllQueriesResponse>>(`/apiv2/user/queries`)
}