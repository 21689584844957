// Components
import IconRouter from 'hsi/components/IconRouter';
import PasswordGenerator from '../PasswordGenerator';

// Other
import {useConfig} from 'hsi/contexts/ConfigContext';
import {T} from 'hsi/i18n';
import useStyles from './styles';

type GeneratePasswordPageProps = {
    setIsPasswordValid: (isValid: boolean) => void;
    handlePasswordChange: (password: string) => void;
    password: string;
    onEnterHandler: () => void;
};

/**
 * Prompt user to generate a password when creating a shared report for a query for the first time
 * @param params
 * @returns
 */
export default function GeneratePasswordPage({
    setIsPasswordValid,
    password,
    handlePasswordChange,
    onEnterHandler
}: GeneratePasswordPageProps) {
    const classes = useStyles();
    const {
        sharedReports: {
            password: {rules},
        },
    } = useConfig();

    return (
        <>
            <span>{T(`exportReports.passwordGenerator.copy`)}</span>

            <PasswordGenerator
                setIsPasswordValid={setIsPasswordValid}
                password={password}
                setPassword={handlePasswordChange}
                onEnterHandler={onEnterHandler}
            />

            <div className={classes.validationRules}>
                {rules.map(({name, pattern}) => {
                    const isValid = pattern.test(password);

                    return (
                        <div role="status" aria-live="polite" key={name} className={classes.rule}>
                            {isValid ? (
                                <IconRouter
                                    aria-hidden="true"
                                    name="f-check"
                                    className={classes.checkIcon}
                                />
                            ) : (
                                <IconRouter
                                    aria-hidden="true"
                                    name="close"
                                    className={classes.crossIcon}
                                />
                            )}
                            <span className="offscreen">
                                {isValid ? 'Requirement met' : 'Requirement not met'}
                            </span>
                            <span>{T(`exportReports.passwordGenerator.validations.${name}`)}</span>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
