import {PayloadAction, createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import { ALL_RELOAD_SEARCH_DATA_ACTIONS } from 'hsi/constants/actionTypes';
import { RootReducer } from 'hsi/reducers/rootReducer';
import { checkSearchHasData as checkSavedSearchHasDataService } from 'hsi/services/apiv2/savedSearchService';
import { checkSearchHasData as checkQuickSearchHasDataService } from 'hsi/services/apiv2/quickSearchService';
import { QueryContextType } from 'hsi/types/query';
import { isHttpError } from 'hsi/classes/Http';
import { SavedSearchType } from 'hsi/types/shared';

export type HasDataCheckStatus = 'pending' | 'loading' | 'loaded' | 'error';

export type SearchResultsType = {
    /** Does this query + filters/date range etc actually have any data to display? Null = check has yet to complete */
    hasData: null | boolean;

    hasDataCheckStatus: HasDataCheckStatus,

    /** Value of -1 = unknown, otherwise will be an HTTP status code */
    hasDataCheckError: number | null,
};

const initialState: SearchResultsType = {
    hasData: null,
    hasDataCheckStatus: 'pending',
    hasDataCheckError: null,
};

const slice = createSlice({
    name: 'searchResults',
    initialState,
    reducers: {

        setSearchHasData(state, {payload}: PayloadAction<boolean>) {
            state.hasData = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(checkSavedSearchHasData.pending, checkQuickSearchHasData.pending) , (state) => {
                state.hasData = null;
                state.hasDataCheckError = null;
                state.hasDataCheckStatus = 'loading';
            })
            .addMatcher(isAnyOf(checkSavedSearchHasData.fulfilled, checkQuickSearchHasData.fulfilled), (state, {payload}) => {
                state.hasDataCheckStatus = 'loaded';
                state.hasData = payload;
            })
            .addMatcher(isAnyOf(checkSavedSearchHasData.rejected, checkQuickSearchHasData.rejected), (state, {payload}) => {
                state.hasDataCheckStatus = 'error';
                state.hasDataCheckError = payload ?? -1;
                state.hasData = false;
            })

            .addMatcher(({type}) => ALL_RELOAD_SEARCH_DATA_ACTIONS.includes(type), (state) => {
                state.hasData = null;
            });
    }
});

export const checkSavedSearchHasData = createAsyncThunk<boolean, number, {state: RootReducer, rejectValue: number}>('searchResults/checkSearchHasData', async (queryId, {getState, dispatch, rejectWithValue}) => {
    if(!queryId) {
        throw new Error('Unknown/invalid query id');
    }

    const {search: {searches}, filters, query:{ context, additionalQueries, linkedinChannelIds }} = getState();

    const projectId: number | null = (searches as SavedSearchType[]).find(({id}) => id === queryId)?.projectId ?? null;

    if(!projectId) {
        throw new Error('Unable to find query projectId');
    }
    
    let result: Awaited<ReturnType<typeof checkSavedSearchHasDataService>> | null = null;
    
    try {
        result = await checkSavedSearchHasDataService(queryId, projectId, filters, context as QueryContextType, additionalQueries, linkedinChannelIds);
    }
    catch(e) {
        if(isHttpError(e)) {
            return rejectWithValue(e?.status ?? -1);
        }

        //An unknown error
        return rejectWithValue(-1);
    }

    if(!result.ok) {
        //TODO ideally would differential between different type types, but for now just a catch all
        return rejectWithValue(result.status ?? -1);
    }

    return result.body.response;
});

export const checkQuickSearchHasData = createAsyncThunk<boolean, void, {state: RootReducer, rejectValue: number}>('', async (quickSearchQuery, {getState, dispatch, rejectWithValue}) => {
    const {filters, query:{ context }, user: {projects}} = getState();

    //Hmm????
    const projectId: number | null = projects?.[0].id ?? null;

    if(!projectId) {
        throw new Error('Unable to find query projectId');
    }
    
    let result: Awaited<ReturnType<typeof checkQuickSearchHasDataService>> | null = null;
    try {
        result = await checkQuickSearchHasDataService(projectId, filters, context as QueryContextType);
    }
    
    catch(e) {
        if(isHttpError(e)) {
            return rejectWithValue(e?.status ?? -1);
        }

        //An unknown error
        return rejectWithValue(-1);
    }
    
    if(!result.ok) {
        return rejectWithValue(result.status ?? -1);
    }

    return result.body.response;
})

export const {setSearchHasData} = slice.actions;
export default slice.reducer;
