import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'right' | 'fixed' | 'grow'>()((theme, _params, classes) => {
    const v2 = isV2(theme);

    return {
        searchBar: {
            position: 'sticky',
            zIndex: 1299,
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: v2 ? theme.colors.uiGrayDefault : theme.colors.lightGrey20,
            minHeight: theme.elements.searchBarContainerHeight,
            padding: v2 ? '0 20px 0 0' : '0 20px',
            boxShadow: v2
                ? `0px 1px 0px ${theme.colors.uiGrayDarker}`
                : `0px 2px 0px ${theme.colors.lightGrey100}`,

            color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
        },

        backBtn: {
            margin: v2 ? '0 0 0 6px' : undefined,
        },

        backBtnIcon: {
            width: v2 ? 24 : 20,
            height: v2 ? 24 : 20,
            flex: `0 0 20px`,
            alignSelf: 'center',
            margin: v2 ? -4 : -2,
        },

        breadcrumbsContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flex: '0 1 auto', //starts big, and shrinks (happens after growing)
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            minWidth: 'auto',
            textOverflow: 'ellipsis',
            marginLeft: v2 ? '10px' : '20px',
        },

        btnsContainer: {
            flex: '1 0 400px', //starts small, and grows (happens before shrinking)
            minWidth: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [`& > .${classes.right}`]: {
                justifySelf: 'flex-end',
            },

            [`& > .${classes.fixed}`]: {
                flex: '0 0 auto',
            },

            [`& > .${classes.grow}`]: {
                flex: '1 1 0',
                minWidth: 0,
            },
        },

        manageSearchButton: {
            display: 'inline-flex',
            margin: v2 ? undefined : '0 0 0 12px',

            '&[disabled]': {
                opacity: 0.5,
                cursor: 'default',
            },

            '& svg': {
                width: v2 ? 22 : 16,
                height: v2 ? 22 : 16,

                margin: v2 ? -3 : undefined,
            },
        },

        tagBtn: {
            display: 'inline-flex',
            margin: v2 ? undefined : '0 12px',

            '& svg': {
                width: v2 ? 22 : 16,
                height: v2 ? 22 : 16,

                margin: v2 ? -3 : undefined,
            },
        },

        savedSearches: {
            ...(v2
                ? theme.fonts.headtitleNormal
                : {
                      fontSize: '20px',
                      color: theme.colors.darkGrey60,
                      fontWeight: 500,
                  }),
            textTransform: 'capitalize',
        },
        searchLabel: {
            marginRight: 0,
            color: theme.colors.textDefault,
            whiteSpace: 'inherit',
            overflow: 'inherit',
            textOverflow: 'inherit',

            ...(v2
                ? theme.fonts.headtitleStrong
                : {
                      fontSize: '20px',
                      fontWeight: theme.font.bold,
                  }),
        },

        arrowIcon: {
            margin: '0 10px',

            '--size': v2 ? '22px' : '18px',
            height: 'var(--size)',
            width: 'var(--size)',
            minWidth: 'var(--size)',

            fill: v2 ? theme.colors.bgOverlayHeavy : theme.colors.darkGrey100,
        },

        controlsContainer: {
            justifyContent: 'flex-end',
            justifySelf: 'flex-end',
            display: 'flex',
            flex: '0 0 auto',
            gap: '10px',

            '& .MuiButton-contained': {
                flex: '0 0 auto',
                '& .icon-tag': {
                    marginRight: '7px',
                },
                '&:hover': {
                    '& .icon-tag': {
                        fill: v2 ? theme.colors.bgOverlayHeavy : undefined, //Was 'theme.colors.primaryDark' - doesn't exist?,
                    },
                },
            },
        },

        divider: {
            marginLeft: 24,
            marginRight: 24,
        },

        //Required for compound classes above
        right: {},
        fixed: {},
        grow: {},
    };
});
