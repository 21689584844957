import axios from 'axios';
import {AllFilteringState} from 'hsi/types/filters';
import {CCFlagsType} from 'hsi/types/cc-flags';
import {MentionType} from 'hsi/types/mentions';

import {QueryContextType, QueryStateType} from 'hsi/types/query';
import {AppSource} from 'hsi/types/shared';
import {TimezoneID} from 'hsi/utils/timezones';
import { SnapshotData } from 'hsi/types/snapshots';

const FE_API_URL = '/fe-api';

export const desApi = axios.create({
    baseURL: FE_API_URL,
    headers: {'Content-Type': 'application/json'},
});

export async function getMentionsExportAllowance() {
    const resp = await desApi.post<{limit: number; used: number; remaining: number}>(
        `/export-mentions-allowance`,
    );

    if (resp.status !== 200) {
        // console.log(resp); //TODO handle failed export better
        // debugger;
        throw new Error(`getMentionsExportAllowance request failed: ${resp.statusText}`);
    }

    return resp.data;
}

type ExportMentionsToPDFPayloadType = {
    // extract unused props (there are loads, but it's not easy to tell what does and does not get used,
    // so I am only removing large props that def aren't needed)
    mentions: Omit<MentionType, 'fullText' | 'imageMd5s' | 'imageInfo' | 'entityInfo'>[];
    state: {
        //mentions,
        query: Pick<QueryStateType, 'context' | 'additionalQueries'>;
        search: {
            searches?: any; //TODO type this
        };
        filters: AllFilteringState;
    };
    flags: CCFlagsType;
    appSource: AppSource;
    timezone: TimezoneID;
};

export async function exportMentionsToPDF(
    projectId: QueryContextType['projectId'],
    payload: ExportMentionsToPDFPayloadType,
) {
    const resp = await desApi.post<Blob>(
        `/export-mentions-to-pdf/${projectId}`,
        {url: getExportUrl() + '/mentions', payload},
        {
            responseType: 'blob',
        },
    );

    if (resp.status !== 200) {
        // console.log(resp); //TODO handle failed export better
        // debugger;
        throw new Error(`Export mentions request failed: ${resp.statusText}`);
    }

    return resp.data;
}



export async function exportReportAsPDF(
    projectId: QueryContextType['projectId'],
    payload: SnapshotData,
) {
    const resp = await desApi.post<Blob>(
        `/export-report-to-pdf/${projectId}`,
        {url: getExportUrl() + '/cards', payload},
        {
            responseType: 'blob',
        },
    );
    if (resp.status !== 200) {
        // User facing error handling in exportActions.ts
        throw new Error(`Export report request failed: ${resp.statusText}`);
    }
    return resp.data;
}

//Not actually sure why this is needed - maybe running in tests doesn't set a value for window.location?
const getExportUrl = () =>
    typeof window.location !== 'undefined'
        ? window.location.protocol + '//' + window.location.host + '/export'
        : 'http://localhost:3000/export';
