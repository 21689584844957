import {useCallback, useMemo} from 'react';

import ItemWithHeader from '../ItemWithHeader';
import CharacterLimit from 'hsi/components/CharacterLimit';
import IconRouter from 'hsi/components/IconRouter';
import TextField, {TextFieldProps} from 'hsi/components/TextField';

import useForceUpdate from 'hsi/hooks/useForceUpdate';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

import {AnnotationKey} from 'hsi/types/charts';

import {T} from 'hsi/i18n';

type AnnotationProps = {
    clearLabel?: React.ReactNode;
    id?: string;
    label: React.ReactNode;
    maxLength?: number;
    multiline?: boolean;
    name: AnnotationKey;
    placeholder?: string;
    setValue: (name: AnnotationKey, value: string) => void;
    valuesRef: React.MutableRefObject<Partial<Record<AnnotationKey, string | undefined>> | null>;
};

export default function Annotation({
    clearLabel,
    id: _id,
    label,
    maxLength = 1000,
    multiline = true,
    name,
    placeholder,
    setValue,
    valuesRef,
}: AnnotationProps) {
    const classes = useStyles();
    const forceUpdate = useForceUpdate();
    const id = useUniqueId(_id, 'annotation');

    const value = !!valuesRef?.current?.[name] ? (valuesRef.current[name] as string) : '';
    const isSummary = name === 'summary';

    const onChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
        (event) => {
            setValue(name, event.target.value);
            forceUpdate();
        },
        [name, setValue, forceUpdate],
    );

    const clearValue = useCallback(() => {
        setValue(name, '');
        forceUpdate();
    }, [name, setValue, forceUpdate]);

    const helperText = useMemo(() => {
        return (
            value.length > 0 
                ? <div className={classes.helperText}>
                    {value.length > maxLength && (
                        <div className={classes.warning} aria-live="assertive" role="alert">
                            <IconRouter
                                aria-hidden="true"
                                className={classes.warningIcon}
                                name="warning"
                            />
                            {T(`exportToPDF.annotations.warning.${isSummary ? 'summary' : 'notes'}`, {
                                max: maxLength,
                            })}
                        </div>
                    )}
                    {value.length > 0 && (
                        <CharacterLimit
                            className={classes.characterLimit}
                            current={value.length}
                            data-testid={`characterLimit-${name}`}
                            hideMaxAlert
                            max={maxLength}
                        />
                    )}
                </div>
                : null
        );
    }, [classes, isSummary, maxLength, name, value]);

    const header = useMemo(() => <>
        <label htmlFor={id}>
            {label}
        </label>
        <label htmlFor={id} className="offscreen">
            {T('exportToPDF.annotations.maxLengthLabel', {maxLength})}
        </label>
    </>, [id, label, maxLength]);

    const headerRight = useMemo(() => (helperText || (value && clearLabel)) 
            ? <>
                {helperText}
                {value && clearLabel && (
                    <button onClick={clearValue} className={classes.clearField}>
                        {clearLabel}
                    </button>
                )}
            </> 
            : null, 
        [classes.clearField, clearLabel, clearValue, helperText, value]
    );

    return (
        <ItemWithHeader header={header} headerRight={headerRight}>
            <TextField
                error={value?.length > maxLength}
                id={id}
                inputProps={{maxLength: Math.round(maxLength * 1.5)}}
                minRows={3}
                multiline={multiline}
                onChange={onChange}
                placeholder={placeholder || T('exportToPDF.annotations.annotationPlaceholder')}
                value={value}
                variant="outlined"
            />
        </ItemWithHeader>
    );
}
