import React, {PropsWithChildren, useEffect, useState} from 'react';
import * as configcat from 'configcat-js';
import {useAppSelector} from 'hsi/hooks/useRedux';
import useConfig from 'hsi/hooks/useConfig';
import {CCFlagsType} from 'hsi/types/cc-flags';
import FullscreenLoading from 'hsi/components/FullscreenLoading';
import {T} from 'hsi/i18n';

export type CCContextType = {
    CCFlags: CCFlagsType;
};

//Context
export const CCFlagsContext = React.createContext<CCFlagsType | null>(null);
CCFlagsContext.displayName = 'CCFlagsContext';

//Consts
const LoadingMsg = <FullscreenLoading message={T('loading')} />;

//The component
export default function CCFlagsProvider({children}: PropsWithChildren) {
    const {settings, account} = useAppSelector((state) => state.user);
    const [CCFlags, setCCFlags] = useState<CCFlagsType | null>(null);
    const {appSource} = useConfig();

    useEffect(() => {
        const getClient = async (settings: any) => {
            const configCatClient = configcat.getClient(settings.CONFIGCAT_SDK_KEY);
            const userObject = new configcat.User(
                account!.id.toString(),
                account!.userEmail,
                undefined,
                {
                    clientId: account!.client.id,
                    appSource: appSource,
                    luqiFrance: account!.isFr,
                },
            );

            const allCCFlags = await configCatClient.getAllValuesAsync(userObject);

            setCCFlags(
                allCCFlags.reduce<Partial<CCFlagsType>>((output, {settingKey, settingValue}) => {
                    output[settingKey as keyof CCFlagsType] = settingValue ?? undefined;

                    return output;
                }, {}),
            );

            // Listen for any future changes to flag values
            configCatClient.on('flagEvaluated', ({key, value}) => {
                setCCFlags((flags) => ({
                    ...flags,
                    [key]: value,
                }));
            });
        };

        getClient(settings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return CCFlags === null ? (
        LoadingMsg
    ) : (
        <CCFlagsContext.Provider value={CCFlags}>{children}</CCFlagsContext.Provider>
    );
}
