//PP the way many of these methods are working currently is problematic for me
//My main issue is always passing in timezone to all methods, which I think causes confusion
//are we asking the method to interpret the time different depending on the time zone
//or or just use the supplied timezone for the formatting of the output?

//What I envisioned was a normalise method, that would take a date and a timezone and return a
//luxon date object, that would then be used for all internal date manipulation, display etc
//and then some standard methods to go back to strings etc. when you needed to (for API calls
//or storing in redux etc)

//Also, we would only have general purpose utility methods here, not very specific methods
//that only get used in a single place. As it is, in my opinion a lot of these utility methods
//serve mainly to obscure the exact details of what is being done to the date.

// https://moment.github.io/luxon/api-docs/index.html
// https://moment.github.io/luxon/demo/global.html
import {DateTime, DateTimeUnit, DurationLike, IANAZone, Interval} from 'luxon';

import {
    InputDateType,
    isDate,
    isDateTime,
    isTDateISODate,
    RangeDefinition,
    TDateISO,
    TDateISODate,
} from 'hsi/types/dates';
import {isValidTimezone, TimezoneID} from './timezones';
import {ValueOfArray} from 'hsi/types/shared';
import {isProduction} from './url';

const DEFAULT_TIMEZONE: TimezoneID = 'UTC';

//type guards
function isNumber(date: InputDateType): date is number {
    return typeof date === 'number' && !Number.isNaN(date);
}

// TODO: Remove default timezone
const opts = (timezone?: TimezoneID) => {
    const zone = timezone || DEFAULT_TIMEZONE;

    if (!IANAZone.isValidZone(zone)) {
        throw new Error(`Timezone in invalid format: ${zone}`);
    }

    return {zone};
};

//type InputDateType = string | TDateISO | TDateISODate | Date | number | DateTime;

// This takes either a JS date object, a number timestamp or an ISO date and
// converts it into a luxon datetime with a timezone for further date processing.
// This should only really be used within the date functions in this file.
function normalizeDate(date: InputDateType, timezone?: TimezoneID): DateTime {
    if (!date) {
        throw new Error(`Argument 'date' is required`);
    }

    if (timezone && !isValidTimezone(timezone)) {
        throw new Error("Argument 'timezone; in invalid format");
    }

    if (isDate(date)) {
        return DateTime.fromJSDate(date as Date, opts(timezone));
    }

    if (isNumber(date)) {
        return DateTime.fromMillis(date, opts(timezone));
    }

    if (isTDateISODate(date)) {
        return DateTime.fromISO(date + 'T00:00:00Z', {zone: 'UTC'}).setZone(timezone ?? 'UTC', {
            keepLocalTime: true,
        });
    }

    if (isDateTime(date)) {
        //Do we do anything with timezone here? Not sure what the user would be expecting at this point
        return timezone ? date.setZone(timezone) : date;
    }

    //If we get here, assume it's a string
    const dateTime = DateTime.fromISO(date as string, opts(timezone));

    if (!dateTime.isValid) {
        throw new Error(`Argument 'date' in invalid format`);
    }

    return dateTime;
}

export function isValidDate(date: InputDateType): boolean {
    try {
        return !!normalizeDate(date);
    }

    catch(e) {
        return false;
    }
}

// This converts a luxon datetime into UTC ISO time. This is the format of time
// that should be used within the app and for API calls.
function toStateFormat(date: InputDateType, timezone?: TimezoneID): TDateISO {
    return normalizeDate(date, timezone).toUTC().toISO() as TDateISO;
}

// Returns date in ISO string format
// E.g. "2021-12-14T23:59:59.999Z"
// PP: Literally did the same as toStateFormat, so I changed it to be an alias. We should probably pick one name and use it everywhere
const convertToISODate = toStateFormat;

// Converts dates to standard internal ISO UTC date format
// PP: Again, literally did the same as toStateFormat
const getISODate = toStateFormat;

function toISODate(date: DateTime): TDateISODate {
    return date.toFormat('yyyy-LL-dd', {locale: 'en-US'}) as TDateISODate; //is locale require?
}

// Allowed formats
// If you add one, have a think about why you need to add one and could you just
// reuse an existing format for consistency?
const formats = [
    'h a', // H AM / 9 AM
    'h a ZZZZ', // H AM ZZZZ / 9 AM UTC
    'DDD', // MMMM DD, YYYY / November 1, 2011
    'DD', // MMM D, YYYY / Oct 31, 2011
    'DD ZZZZ', // MMM D, YYYY ZZZZ / Oct 31, 2011 UTC
    'ZZZZ', // ZZZZ / GMT-4
    'MMM d', // MMM D / Oct 7
    'MMM d ZZZZ', // MMM D ZZZZ / Oct 7 UTC
    'LLL yyyy', // MMM, YYYY / Oct 2011
    'LLLL yyyy', // MMMM, YYYY / October 2011
    'LLL yyyy ZZZZ', // MMM, YYYY / Oct 2011 UTC
    'd LLLL yyyy', // DD MMMM YYYY / 7 October 2011
    'yyyy-LL-dd', // YYYY-MM-DD / 2011-10-31
    'yyyy-LL-ddZZ', // YYYY-MM-DDZ / 2011-10-31+0:00 - ISO date format
    'yyyy-LL-dd ZZZZ', // YYYY-MM-DD ZZZZ / 2011-10-31 UTC
    'yyyy-LL-dd h:mm:ss', // YYYY-MM-DD ZZZZ / 2011-10-31 9:00:00
    "yyyy-LL-dd'T'h:mm:ssZZ", // YYYY-MM-DDZ / 2011-10-31+0:00 - ISO date-time format
    'ccc, MMM dd yyyy', // ddd, mmm dd yyy / Wed, Dec 15 2021
    'MMM d, h:mm a ZZZZ', // mmm d, h:mm AM UTC / Sep 10, 9:00 AM UTC
    'MMM d, yyyy h:mm a ZZZZ', // mmm d, yyyy h:mm AM UTC / Sep 10, 2022 9:00 AM UTC
    'yyyy', // 2022
] as const;

export type DateTimeFormats = ValueOfArray<typeof formats>;

// https://moment.github.io/luxon/#/formatting?id=table-of-tokens

function formatTo(
    date: InputDateType,
    timezone: TimezoneID | undefined,
    format: DateTimeFormats,
    locale: string = 'en-US',
) {
    if (!format) {
        throw new Error('Format must be provided');
    }

    if (!formats.includes(format)) {
        throw new Error(`Format "${format}" is not a valid format: ${JSON.stringify(formats)}`);
    }

    return normalizeDate(date, timezone).toFormat(format, {locale});
}

// Returns Date object which includes the local system time of the user
// E.g. toString() === "Wed Dec 15 2021 10:59:59 GMT+0000 (Greenwich Mean Time)"
// Dates should be converted back to ISO format to avoid local system setting leaking in
//TODO should probably not use this method, it's not a good way to achieve this as it allows users
//timezone to affect the result
function convertToJSDate(date: InputDateType, timezone?: TimezoneID) {
    const dateTime = normalizeDate(date, timezone);

    return new Date(dateTime.get('year'), dateTime.get('month') - 1, dateTime.get('day'));
}

// Takes an array of dates and returns the earliest date out of the array
const getEarliestDate = (dates: InputDateType[], timezone?: TimezoneID) => {
    if (!dates || !Array.isArray(dates) || !dates.length) {
        throw new Error('Dates should be in an array format');
    }

    const dateTimes = dates.map((date) => normalizeDate(date, timezone));
    const earliestDate = DateTime.min(...dateTimes);

    return toStateFormat(earliestDate, timezone);
};

// Gets the start of a particular unit of time (day, week, month, hour, etc)
function getStartOf(date: InputDateType, timezone: TimezoneID | undefined, unit: DateTimeUnit) {
    if (!unit) {
        throw new Error(`Unit must be provided in string format: ${unit}`);
    }

    return toStateFormat(normalizeDate(date, timezone).startOf(unit), timezone);
}

// https://moment.github.io/luxon/api-docs/index.html#datetimeplus
function getPreviousDate(
    date: InputDateType,
    timezone: TimezoneID | undefined,
    duration: DurationLike,
) {
    if (!duration) {
        throw new Error(`duration is required: ${duration}`);
    }

    const dateTime = normalizeDate(date, timezone);
    const previousDate = dateTime.minus(duration);

    return toStateFormat(previousDate, timezone);
}

//What is this?
function getInitialPeriod(timezone?: TimezoneID) {
    if (timezone && !isValidTimezone(timezone)) {
        throw new Error("Argument 'timezone; in invalid format");
    }

    const now = timezone ? DateTime.now().setZone(timezone) : DateTime.now();

    // stage api only provides previous 7 days of data
    const endDateThreshold = isProduction() ? 29 : 7;

    return {
        endDate: now.endOf('day').toUTC().toISO(),
        startDate: now.minus({days: endDateThreshold}).startOf('day').toUTC().toISO(),
    };
}

// TODO: Check that the dates are provided the right way around
type PeriodType = {
    startDate: TDateISO;
    endDate: TDateISO;
    diff: number;
    diffInMonths: number;
};

function getPreviousPeriod(
    startDate: InputDateType,
    endDate: InputDateType,
    timezone?: TimezoneID,
): PeriodType {
    const end = normalizeDate(endDate, timezone);
    const start = normalizeDate(startDate, timezone);

    const diff = Math.round(end.diff(start, 'days').days);
    const diffInMonths = Math.floor(end.diff(start, 'months').months);
    const duration: DurationLike = {days: diff};

    return {
        startDate: start.minus(duration).toUTC().toISO() as TDateISO,
        endDate: end.minus(duration).toUTC().toISO() as TDateISO,
        diff,
        diffInMonths,
    };
}

// Returns the date in local time for use with the react date range picker
//TODO again, will probably remove this once react date picker is gone - PP apparently loads of things use this
const getLocalDate = (date: InputDateType, timezone?: TimezoneID) => {
    const dateTime = normalizeDate(date);
    const localDateTime = dateTime.setZone(timezone);

    return localDateTime.toISO();
};

const getDaysAmount = (startDate: InputDateType, endDate: InputDateType, timezone?: TimezoneID) => {
    const startDateTime = normalizeDate(startDate, timezone);
    const endDateTime = normalizeDate(endDate, timezone);

    const diffInDays = endDateTime.diff(startDateTime, 'days');
    const {days} = diffInDays.toObject();

    return days;
};

const getHours = (date: InputDateType, timezone?: TimezoneID) => {
    const dateTime = normalizeDate(date, timezone);

    return dateTime.hour; // This is in 24 hours
};

const getTimestamp = (date: InputDateType, timezone?: TimezoneID) => {
    const dateTime = normalizeDate(date, timezone);

    return dateTime.valueOf();
};

// Creates a DateTime from an ISO string, adds duration in format of { days: 1 }, returns new ISO date
const getFutureDate = (
    date: InputDateType,
    timezone: TimezoneID | undefined,
    duration: DurationLike,
) => {
    if (!duration) {
        throw new Error(`duration is required: ${duration}`);
    }

    const dateTime = normalizeDate(date, timezone);
    const newDate = dateTime.plus(duration);

    return toStateFormat(newDate, timezone);
};

// https://github.com/moment/luxon/issues/313#issuecomment-909025478
const isToday = (date: InputDateType, timezone?: TimezoneID) => {
    const currentDateTime = normalizeDate(Date.now(), timezone);
    const dateTime = normalizeDate(date, timezone);

    return currentDateTime.toISODate() === dateTime.toISODate();
};

const isSame = (
    date1: InputDateType | undefined,
    date2: InputDateType | undefined,
    unit: DateTimeUnit,
    timezone?: TimezoneID,
) => {
    if (!date1 || !date2) {
        return false;
    }

    const normalizedDate1 = normalizeDate(date1, timezone);
    const normalizedDate2 = normalizeDate(date2, timezone);

    if (!unit || typeof unit !== 'string') {
        throw new Error(`Unit must be provided in string format: ${unit}`);
    }

    return normalizedDate1.hasSame(normalizedDate2, unit);
};

function getIntervalFromRelativeDateRange(
    rangeDefinition: RangeDefinition,
    timezone: TimezoneID,
    now?: DateTime,
) {
    now = (now || DateTime.now()).setZone(timezone);

    return Interval.fromDateTimes(
        now.minus(rangeDefinition.start).startOf('day'),
        now.minus(rangeDefinition.end).endOf('day'),
    );
}

function dateTimeNow(timezone: string): DateTime {
    return DateTime.fromObject({}, {zone: timezone});
}

function isSameTime(value: DateTime | undefined, prevValue: DateTime | undefined) {
    if (!value || !prevValue) {
        return false;
    }

    return +value === +prevValue;
}

// Returns current date time in ISO UTC format
const now = (timezone?: TimezoneID) => getISODate(DateTime.now(), timezone);

export {
    convertToJSDate,
    convertToISODate,
    formats,
    formatTo,
    getDaysAmount,
    getEarliestDate,
    getHours,
    getInitialPeriod,
    getISODate,
    getLocalDate,
    getPreviousDate,
    getPreviousPeriod,
    getStartOf,
    getTimestamp,
    getFutureDate,
    isSame,
    isToday,
    normalizeDate,
    now,
    dateTimeNow,
    toStateFormat,
    getIntervalFromRelativeDateRange,
    isSameTime,
    toISODate,
};
