// Components
import Button from 'hsi/components/Button';
import Dialog, {DialogActions, DialogContent} from 'hsi/components/Dialog';
import FormError from 'hsi/components/FormError';
import PulseLoader from 'hsi/components/PulseLoader';
import TextField from 'hsi/components/TextField';

// Hooks
import {useAuthDialogLogic} from './useAuthDialogLogic';

import {T} from 'hsi/i18n';
import useStyles from './styles';

export interface IAuthDialog {
    snapshotId: string;
    onAuthenticated: () => void;
}

export const AuthDialog = ({snapshotId, onAuthenticated}: IAuthDialog) => {
    const classes = useStyles();

    const {password, loadingAuth, authError, handlePasswordChange, submitHandler, onPressEnter} =
        useAuthDialogLogic({snapshotId, onAuthenticated});

    return (
        <div>
            <Dialog open>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.passwordField}>
                        <label id="password-label">{T('sharedDashboard.passwordLabel')}</label>
                        <TextField
                            fullWidth
                            type="password"
                            label={'password-label'}
                            error={authError.hasError}
                            helperText={
                                authError.hasError && (
                                    <FormError type="warning" errorText={authError.message} />
                                )
                            }
                            value={password}
                            onChange={handlePasswordChange}
                            onKeyDown={onPressEnter}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitHandler} disabled={loadingAuth || !password.trim()}>
                        {loadingAuth ? <PulseLoader /> : <span>{'Submit'}</span>}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
