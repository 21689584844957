import { useCallback } from "react";

import useQueryId from "hsi/hooks/useQueryId";
import useQueryUserData, { useGetQueryUserDataSetter } from "hsi/hooks/useQueryUserData";
import { CardType, BreakdownCardsType, BreakdownCards, DefaultBreakdowns } from "hsi/types/cards";
import { mapValues } from "lodash";
import { QueryUserData } from "hsi/types/queryUserData";


export function cardHasBreakdown(type: any): type is keyof BreakdownCardsType {
    return type in BreakdownCards;
}

export function getCardBreakdownOptions<T extends CardType>(type: T) {
    if(cardHasBreakdown(type)) {
        return BreakdownCards[type]
    }

    return null;
}

export function useGetCardBreakdown<T extends CardType>(queryId: number | null | undefined, type : T) {
    return useQueryUserData(queryId, (queryUserData) => queryId !== null && queryId !== undefined && cardHasBreakdown(type) 
        ? getCardBreakdownFromState(type, queryUserData?.cardBreakdowns)
        : undefined )
}

export function useGetCurQueryCardBreakdown<T extends CardType>(type: T) {
    return useGetCardBreakdown(useQueryId(), type);
}

export function useGetCurQueryCardBreakdowns() {
    return useGetCardBreakdowns(useQueryId());
}

export function useGetCardBreakdowns(queryId?: number | null | undefined) {
    return useQueryUserData(queryId, (queryUserData) => {
        return mapValues(BreakdownCards, (value, type) => {
            return getCardBreakdownFromState(type as CardType, queryUserData?.cardBreakdowns)
        })
    });
}

export function getCardBreakdownFromState(type: CardType, state: QueryUserData['cardBreakdowns'] | null | undefined) {
    return cardHasBreakdown(type) ? state?.[type] ?? DefaultBreakdowns[type] : undefined;
}

/**
 * Returns a function that can be called to update the value of a given card's breakdown
 * @param queryId 
 * @param type  the card type
 * @returns setter function
 */
export function useGetCardBreakdownSetter<T extends keyof BreakdownCardsType>(queryId: number | null | undefined, type: T) {
    const setter = useGetQueryUserDataSetter(queryId ?? null);

    return useCallback(async (value: BreakdownCardsType[T][number]) => {
        if(!setter) {
            return false;
        }

        await setter('cardBreakdowns', (cardBreakdowns) => {
            return {
                ...cardBreakdowns,
                [type]: value,
            };
        });

        return true
    }, [setter, type]);
}
