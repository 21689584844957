import {useState, useCallback, useMemo, ChangeEventHandler, ReactElement, KeyboardEvent} from 'react';

// Components
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextField from 'hsi/components/TextField';
import Button from 'hsi/components/Button';
import ItemWithHeader from '../ItemWithHeader';

// Other
import useUniqueId from 'hsi/hooks/useUniqueId';
import useStyles from './styles';

// Types
type PasswordInputProps = {
    onEnterHandler?: () => void;
    label?: ReactElement;
    placeholder?: string;
    id?: string;
    maxLength?: number;
    password?: string;
    setPassword?: (newPassword: string) => void;
    error?: boolean;
};

/**
 * Password input display component
 * @param params
 * @returns
 */
export default function PasswordInput({
    label,
    placeholder,
    id: _id,
    maxLength,
    password = '',
    setPassword,
    error = false,
    onEnterHandler,
}: PasswordInputProps) {
    const id = useUniqueId(_id, 'password');
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(false); 

    const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
        (e) => {
            setPassword?.(e.target.value);
        },
        [setPassword],
    );

    const enterHandler = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && onEnterHandler) {
            onEnterHandler();
        }
    };

    const InputProps = useMemo(
        () => ({
            endAdornment: (
                <Button
                    className={classes.visibleIcon}
                    size="small"
                    onClick={() => {
                        setIsVisible(!isVisible);
                    }}
                    priority="primary"
                >
                    {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Button>
            ),
        }),
        [classes.visibleIcon, isVisible],
    );

    const inputProps = useMemo(() => (maxLength ? {maxLength} : undefined), [maxLength]);

    return (
        <ItemWithHeader header={<label htmlFor={id}>{label}</label>}>
            <TextField
                error={error}
                inputProps={inputProps}
                id={id}
                multiline={false}
                onChange={onChange}
                placeholder={placeholder}
                value={password}
                variant="outlined"
                type={isVisible ? 'text' : 'password'}
                InputProps={InputProps}
                onKeyDown={enterHandler}
            />
        </ItemWithHeader>
    );
}
