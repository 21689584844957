//Everything contained in this file should be review to see if it should be
//refactored to be part of the config system

export const MENTIONS_PAGE_SIZE = 30; //TODO move to config

export const EMOTION_THRESHOLD = 0.3; //TODO move to config

// FB PAGE SIZE FETCHING LIMIT
export const MAX_DATA_SOURCES = 1000;

// This limit is because FB can bring back LOTS of data. The data team have this limit just to spread out how many pages
// can be added at once
export const MAX_PAGES_PER_TRACK = 100;

// FACEBOOK
export const FB_APP_ID = '126413240746677';
export const FB_APP_SCOPE =
    'pages_read_user_content,pages_read_engagement,pages_manage_ads,pages_manage_metadata,email,instagram_basic,instagram_manage_comments,instagram_manage_insights,ads_read,ads_management,public_profile,pages_show_list,business_management';

// SEARCH LENGTH
export const MAX_QUICK_SEARCH_LENGTH = 600;
export const MAX_EDIT_SEARCH_LENGTH = 100000;

export const SENTIMENT_CATEGORIES = ['positive', 'neutral', 'negative'] as const;
export const EMOTIONS_CATEGORIES = [
    'anger',
    'disgust',
    'fear',
    'joy',
    'sadness',
    'surprise',
] as const;
export const GENDERS = ['female', 'male'] as const;

export const SOCIAL_PANEL_GENERATIONAL_NAMES = [
    'Baby Boomers (EN)',
    'Gen X (EN)',
    'Gen Y / Millennials (EN)',
    'Gen Z (EN)',
];

export const WEEKDAYS = [
    {id: 1, name: 'Monday'},
    {id: 2, name: 'Tuesday'},
    {id: 3, name: 'Wednesday'},
    {id: 4, name: 'Thursday'},
    {id: 5, name: 'Friday'},
    {id: 6, name: 'Saturday'},
    {id: 7, name: 'Sunday'},
] as const;

// Total volume card //

export const TotalVolumeMetrics = ['volume', 'reachEstimate', 'impressions', 'authors', 'avgFollowers', 'retweetRate'] as const;
export const DefaultTotalVolumeMetrics: (typeof TotalVolumeMetrics[number])[] = ['volume', 'reachEstimate', 'impressions', 'authors'];
export const TotalVolumeBySearchMetrics: (typeof TotalVolumeMetrics[number])[] = ['volume', 'reachEstimate', 'impressions', 'authors'];

// Word Cloud config //
export const WORD_CLOUD_DEFAULT = 50;

export const WordCloudTypes = [
    'emojis',
    'hashtags',
    'places',
    'organisations',
    'people',
    'words',
    'phrases'
] as const;

export const WordCloudTypeToColorKey: Record<typeof WordCloudTypes[number], string> = {
    emojis : 'emoji',
    hashtags : 'hashtag',
    places: 'place',
    organisations: 'organisation',
    people: 'person',
    words: 'word',
    phrases: 'phrase',
}

export const WordCloudOrderBy = ['volume', 'trending'] as const;
export const WordCloudColourBy = ['topicType', 'gender', 'sentiment', 'volume', 'trending', 'random', 'none'] as const;

export const MONTHS_HISTORIC_DATA = 12;
export const AUTH_TOKEN_LOGIN_LEARN_MORE_URL =
    'https://help.hootsuite.com/hc/en-us/articles/1260804250230';

export const LOGIN_DEBOUNCE_MS = 3000;
export const MAX_LOGIN_ATTEMPTS = 4;

export const RECONNECTION_BANNER_HIDE_NUM_SESSIONS = 10;

export const MAX_INT = 2147483647;

export const MAX_SELECTED_MENTIONS = 100;

//Card sorting/pinning
export const CARD_SECTION_PINNED = '$pinned';
export const CARD_SECTION_HIDDEN = '$hidden';

// ChurnZero
export const CHURN_ZERO_DATA_BY_REGION = {
    US: {
        url: 'https://cision.us1app.churnzero.net/churnzero.js',
        key: '1!3aRiF4SCJiVp3NJBoenlhkqo-B8Tsw6j3RELHAxKj6Yt8AD',
    },
    // Canada ChurnZero data also supports LATAM and APAC regions.
    Canada: {
        url: 'https://analytics.churnzero.net/churnzero.js',
        key: '1!SteJDbATaqtqUxcB5xtHaCoxkM26e48F2V6OFeK6w3Et893',
    },
    EMEIA: {
        url: 'https://cision.eu1app.churnzero.net/churnzero.js',
        key: '1!bFIjfasWAlel2xjQa1zlGpV2cIrs8O2AoxkMZe0jgQItDE1',
    },
    France: {
        url: 'https://cision-t3556.eu1app.churnzero.net/churnzero.js',
        key: '1!YiOvofs045bLR4Cqm8W2L4gCOUKnxBT5QsPfValndNgtDE4',
    },
} as const;

export type ChurnZeroRegion = keyof typeof CHURN_ZERO_DATA_BY_REGION;

export const GCID_REGION_RANGES = {
    US: {from: 2000001, to: 5000000},
    Canada: {from: 5000001, to: 82600000000}, // Also covers LATAM and APAC regions
    EMEIA: {from: 82600000001, to: 92100000000},
    France: {from: 92100000001, to: +Infinity},
} as const;

// Snapshots
export const SNAPSHOT_DATA_VERSION = 1;