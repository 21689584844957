/* A hook to process card selection logic for exported reports. */

import {formatCardTitleAsString} from 'hsi/components/Card/CardTitle';
import {useAppSelector} from 'hsi/hooks/useRedux';
import {CardComponentConfig, CardType} from 'hsi/types/cards';
import {ChartKey} from 'hsi/types/charts';
import {cardHasAggregate} from 'hsi/utils/cards/aggregates';
import {cardHasBreakdown} from 'hsi/utils/cards/breakdowns';
import {isEmpty} from 'lodash';
import {useCallback, useMemo, useState} from 'react';

export const useCardSelection = (
    savedSearchId: number,
    cards: CardComponentConfig[],
    charts: any,
    cardBreakdowns?: Partial<Record<CardType, string>>,
    cardAggregates?: Partial<Record<string, string>>,
) => {
    const {cardsToExport} = useAppSelector((state) => state.pdfExport);

    // Formatted cards with labels
    const formattedCards = useMemo(
        () =>
            cards.map((card) => ({
                ...card,
                label: formatCardTitleAsString(
                    card.title,
                    cardHasBreakdown(card.name) ? cardBreakdowns?.[card.name] : undefined,
                    cardHasAggregate(card.name) ? cardAggregates?.[card.name] : undefined,
                ),
            })),
        [cards, cardAggregates, cardBreakdowns],
    );

    // Default selected cards from previous export
    const defaultSelectedCards = useMemo<Partial<Record<ChartKey, boolean>>>(
        () =>
            (cardsToExport?.[savedSearchId] || []).reduce(
                (output: Partial<Record<ChartKey, boolean>>, cardName: ChartKey) => {
                    output[cardName] = true;
                    return output;
                },
                {} as Partial<Record<ChartKey, boolean>>,
            ),
        [cardsToExport, savedSearchId],
    );

    // Selected cards state
    const [selectedCards, setSelectedCards] =
        useState<Partial<Record<ChartKey, boolean>>>(defaultSelectedCards);

    // Card pick options for selection
    const cardPickOptions = useMemo(
        () =>
            formattedCards.map(({name, title}) => ({
                name,
                label: formatCardTitleAsString(
                    title,
                    cardHasBreakdown(name) ? cardBreakdowns?.[name] : undefined,
                    cardHasAggregate(name) ? cardAggregates?.[name] : undefined,
                ),
            })),
        [cardAggregates, cardBreakdowns, formattedCards],
    );

    // Selected card pick options
    const selectedCardPickOptions = useMemo(
        () => cardPickOptions.filter((cardPickOption) => !!selectedCards[cardPickOption.name]),
        [cardPickOptions, selectedCards],
    );

    // Callback to save selected cards
    const saveCardsToExport = useCallback(
        (selectedOptions: typeof cardPickOptions) => {
            setSelectedCards(
                selectedOptions.reduce<typeof selectedCards>((output, {name}) => {
                    output[name] = true;
                    return output;
                }, {} as typeof selectedCards),
            );
        },
        [setSelectedCards],
    );

    // Callback to delete a card from selection
    const deleteHandler = useCallback(
        (deletedOption: {name: string}) =>
            setSelectedCards((currentSelectedCards: typeof selectedCards) => ({
                ...currentSelectedCards,
                [deletedOption.name]: false,
            })),
        [setSelectedCards],
    );

    // Check if all cards are selected or none
    const exportAll = Object.values(selectedCards).every((val) => !val);

    // Filtered cards based on selection
    const selectCardsConfig = useMemo(
        () =>
            exportAll ? formattedCards : formattedCards.filter(({name}) => !!selectedCards[name]),
        [formattedCards, exportAll, selectedCards],
    );

    const selectedCardsLoaded = cards.every((card) => {
        if (isEmpty(selectedCards)) return charts[card.name].loaded === true;
        else return !selectedCards[card.name] || charts[card.name].loaded === true;
    });

    return {
        formattedCards,
        selectedCards,
        cardPickOptions,
        selectedCardPickOptions,
        saveCardsToExport,
        deleteHandler,
        selectCardsConfig,
        selectedCardsLoaded,
    };
};
