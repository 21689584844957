import {CardLoadStateProps} from 'hsi/components/Card/CardLoadState';
import { MapObjValuesToValue } from './shared';
import { WordCloudTypes, WordCloudOrderBy, WordCloudColourBy, TotalVolumeMetrics } from 'hsi/constants/config';

export type AggregateType = string; //TODO actual values list?
export type BreakdownType = string;

export type CardTitleObj = {
    breakdownType: BreakdownType;
    title: string;
    subTitle?: string;
    generalDimension?: string;
    overDimension?: string;
};

//TODO dates are ISO strings?

export type CardTitleType = string | CardTitleObj;

//Top authors data
export type TopAuthorsCardData = {
    //TODO are any of these optional?
    id: string;
    name: string;
    displayName: string;
    domain: string;
    percentageOfTotal: number;
    reachEstimate: number;
    twitterFollowers: number;
    twitterAvatar?: string; //A url to a twitter avatar image
    countryName: string;
    value: number;
    values: [
        //'values' is for multiple searches
        {
            data: {}; //????????
            name: string;
            value: any; //null?
            id: number;
        },
    ];
}[];

export type TopHashtagsCardData = {
    id: string;
    name: string;
    value: number;

    //Will always be 0 for quicksearch
    impressions: number;
    reachEstimate: number;
    retweets: number;
    tweets: number;
}[];

export type BenchmarkCardData = {
    categories: {
        currentValue: number;
        previousValue: number;
        name: string;
    }[];
};

export type BenchmarkBySearchCardData = any;
export type ShareOfVoiceCardData = any;
export type EmotionHistoryCardData = {
    series: {
        date: string;
        anger: boolean;
        disgust: boolean;
        fear: boolean;
        joy: boolean;
        sadness: boolean;
        surprise: boolean;
    }[];
};

export type EmotionVolumeCardData = {
    name: string;
    value: number;
}[];

export type GenderCardData = {
    name: string;
    value: number;
    pct: number;
}[];

export type GeographyDataItem = {
    id: string;
    name: string;
    total: number;

    authorTotal: number;
    authorPercent: number;

    [key: number]: number;
};

export type GeographyCardData = GeographyDataItem[];

export type HeatmapCardData = {
    results: {
        name: string;
        values: {
            name: string;
            value: number;
        }[];
    }[];
};

export type MentionsHistoryCardData = {
    series: {
        date: string;
    }[];
    categories: {
        id: string;
    }[];
};

export type NetSentimentHistoryCardData = {
    series: {
        date: string;
    }[];

    categories: {
        id: string;
    }[];
};

export type PageTypeBySearchCardData = any;

export type SentimentHistoryCardData = {
    series: {
        date: string;
        positive: boolean;
        neutral: boolean;
        negative: boolean;
    }[];
};

export type SentimentVolumeCardData = {
    categories: {
        name: string;
        value: number;
    }[];
    total: number;
};

export type SentimentVolumeBySearchCardData = any;
export type TopauthorsBySearchCardData = any;
export type TophashtagsBySearchCardData = any;
export type TopicWheelCardData = any;

export type TopInterestsType = {
    id: string;
    name: string;
    total: number;
    authorTotal: number;
    /** a fraction between 0 and 1 (with 1 = 100%) */
    authorPercent: number;
    authorSeries: {value: number}[]
};

export type TopInterestsCardData = TopInterestsType[];

export type TopLanguageType = {
    id: string;
    name: string;
    total: number;
    authorTotal: number;
    /** a fraction between 0 and 1 (with 1 = 100%) */
    authorPercent: number;
}

export type TopLanguagesCardData = TopLanguageType[];

export type TopSharedUrlType = {
    name: string;
    volume: number;
};

export type TopSharedURLsCardData = TopSharedUrlType[];

export type TopSiteType = {
    name: string;
    value: number;
}

export type TopSitesCardData = TopSiteType[];

export type TopSitesBySearchCardData = any;
export type TopTopicsBySearchCardData = any;
export type TotalMentionsCardData = any;

export type TotalVolumeCardData = {
    id: string;
    current: number;
    previous: number;
    changePercentage: number;
}[];



export type TotalVolumePerQueryData = {
    /** query id */
    id: number;
    /** query name */
    name: string;
    value: number;
    /** Not clear what (if any) purpose this serves*/
    data: {};
};

export type TotalVolumeBySearchType = {
    id: TotalVolumeMetricType;
    /** I think this is actually a percentage, not a fraction */
    changePercentage: number;
    current: number;
    currentValues: TotalVolumePerQueryData[];
    previous: number;
    previousValues: TotalVolumePerQueryData[];
};
export type TotalVolumeBySearchCardData = TotalVolumeBySearchType[];

export type WordCloudCardData = {
    topics: {
        label: string;
        topicType: string;
        volume: number;
        sentimentScore: number;
        gender: {
            female: boolean;
            male: boolean;
        };
        trending: number;
    }[];
};

export type CardDataType = {
    benchmark: BenchmarkCardData;
    benchmarkBySearch: BenchmarkBySearchCardData;
    shareOfVoice: ShareOfVoiceCardData;
    emotionHistory: EmotionHistoryCardData;
    emotionVolume: EmotionVolumeCardData;
    gender: GenderCardData;
    geography: GeographyCardData;
    heatmap: HeatmapCardData;
    mentionsHistory: MentionsHistoryCardData;
    netSentimentHistory: NetSentimentHistoryCardData;
    pageTypeBySearch: PageTypeBySearchCardData;
    sentimentHistory: SentimentHistoryCardData;
    sentimentVolume: SentimentVolumeCardData;
    sentimentVolumeBySearch: SentimentVolumeBySearchCardData;
    topauthors: TopAuthorsCardData;
    topauthorsBySearch: TopauthorsBySearchCardData;
    tophashtags: TopHashtagsCardData;
    tophashtagsBySearch: TophashtagsBySearchCardData;
    topicWheel: TopicWheelCardData;
    topinterests: TopInterestsCardData;
    toplanguages: TopLanguagesCardData;
    topSharedURLs: TopSharedURLsCardData;
    topsites: TopSitesCardData;
    topsitesBySearch: TopSitesBySearchCardData;
    toptopicsBySearch: TopTopicsBySearchCardData;
    totalMentions: TotalMentionsCardData;
    totalVolume: TotalVolumeCardData;
    totalVolumeBySearch: TotalVolumeBySearchCardData;
    wordCloud: WordCloudCardData;
};

export type CardType = keyof CardDataType; //Is this correct?

export interface CardTypeProps
    extends Omit<
        CardLoadStateProps,
        | 'title'
        | 'subTitle'
        | 'error'
        | 'loading'
        | 'loaded'
        | 'hasData'
        | 'selected'
        | 'loadData'
        | 'type'
        | 'notAvailablePerDataLimitation'
        | 'children'
        | 'skipCardContent'
        | 'unsupported'
    > {
    title: CardTitleType;
}

export type CardComponentType = React.ForwardRefExoticComponent<
    CardTypeProps & React.RefAttributes<HTMLDivElement>
>;

export type CardComponentConfig = {
    name: keyof CardDataType;
    section: string;
    title: CardTitleType;
    height?: number;
    component: CardComponentType;
};

// Aggregates
export const AggregateCards = {
    mentionsHistory: ['volume', 'impressions', 'reachEstimate']
};

export type AggregateCardsType = typeof AggregateCards;

/**
 * Default values for each card types breakdown
 */
export const DefaultAggregates: MapObjValuesToValue<AggregateCardsType> = {
    mentionsHistory: 'volume',
};


// Breakdowns
export const HistoryCardBreakdowns = ['hours', 'days', 'weeks', 'months'] as const;
const BenchmarkCardBreakdowns = ['pageTypes', 'sentiment', 'emotions', 'interest', 'languages', 'countries'] as const;

export const BreakdownCards = {
    benchmark: BenchmarkCardBreakdowns, 
    emotionHistory: HistoryCardBreakdowns, 
    mentionsHistory: HistoryCardBreakdowns, 
    netSentimentHistory: HistoryCardBreakdowns, 
    sentimentHistory: HistoryCardBreakdowns 
} as const;

export type BreakdownCardsType = typeof BreakdownCards;
export type Breakdowns = keyof BreakdownCardsType;

/**
 * Default values for each card types breakdown
 */
export const DefaultBreakdowns: MapObjValuesToValue<BreakdownCardsType> = {
    benchmark: 'pageTypes',
    emotionHistory: 'days',
    mentionsHistory: 'days',
    netSentimentHistory: 'days',
    sentimentHistory: 'days',
};

// Card specific types

// -Total Volume
export type TotalVolumeMetricType = typeof TotalVolumeMetrics[number];

// -Word cloud
export type WordCloudTypesType = typeof WordCloudTypes[number];
export type WordCloudOrderByType = typeof WordCloudOrderBy[number];
export type WordCloudColourByType = typeof WordCloudColourBy[number];
