import http from 'hsi/classes/Http';
import {cleanObject} from 'hsi/utils/url';

export default class QueryService {
    static async getUserRecentQueries(userId) {
        return http
            .post('/fe-api/insights-queries/userRecentQueries', {userId})
            .then((res) => res.body);
    }

    /**
     * Persists the query on the middleware layer (nothing to do with BCR API)
     * @param {*} queryData 
     * @returns 
     */
    static async saveQuery(queryData) {
        
        return http
            .post('/fe-api/insights-queries/saveQuery', cleanObject(queryData))
            .then((res) => res.body);
    }

    static async queriesByGuidedType({type}) {
        return http
            .post('/fe-api/insights-queries/queriesByGuidedType', {type})
            .then((res) => res.body);
    }

    static async recentGuidedQueries({userId}) {
        return http
            .post('/fe-api/insights-queries/recentGuidedQueries', {userId})
            .then((res) => res.body);
    }

    static async deleteByBCRQueryId(queryId) {
        return http
            .post('/fe-api/insights-queries/deleteByQueryId', {queryId})
            .then((res) => res.body);
    }
}
