import React from 'react';
import PropTypes from 'prop-types';

import {T} from 'hsi/i18n';

import {Dialog, DialogContent, DialogActions, DialogContentText} from 'hsi/components/Dialog';
import Button from 'hsi/components/Button';
import FormError from 'hsi/components/FormError';
import PulseLoader from 'hsi/components/PulseLoader';

const ConfirmSaveSearchDialog = ({
    cancelChanges,
    canSave,
    errors,
    onConfirmedSave,
    saveAsNew,
    searchName,
    showConfirmSave,
    isSaving,
}) => (
    <Dialog
        title={T('edit.saved.search')}
        open={showConfirmSave}
        onClose={cancelChanges}
        className="confirm-delete-saved-search"
    >
        <DialogContent>
            {!errors && (
                <>
                    <DialogContentText className="information">
                        {T('confirm.edit.saved.search', {search: searchName || ''})}
                    </DialogContentText>
                    <DialogContentText className="cannot-undo">
                        {T('confirm.edit.cannot.undo')}
                    </DialogContentText>
                </>
            )}

            {errors &&
                errors.map((error, idx) => (
                    <FormError
                        key={idx}
                        type="warning"
                        errorText={error.titleId ? T(error.titleId) : T('api.error2')}
                        actionText={error.descId ? T(error.descId) : error.desc}
                    />
                ))}
        </DialogContent>
        <DialogActions>
            {!!saveAsNew && (
                <Button
                    classes={{label: 'secondary'}}
                    priority="secondary"
                    onClick={saveAsNew}
                    disabled={isSaving}
                >
                    {T('save.as.a.new.search')}
                </Button>
            )}
            <Button onClick={cancelChanges}>{T('cancel')}</Button>
            <Button disabled={!canSave || isSaving} priority="primary" onClick={onConfirmedSave}>
                {isSaving ? <PulseLoader /> : T('save')}
            </Button>
        </DialogActions>
    </Dialog>
);

ConfirmSaveSearchDialog.propTypes = {
    cancelChanges: PropTypes.func.isRequired,
    canSave: PropTypes.bool.isRequired,
    errors: PropTypes.array,
    onConfirmedSave: PropTypes.func.isRequired,
    saveAsNew: PropTypes.func,
    searchName: PropTypes.string.isRequired,
    showConfirmSave: PropTypes.bool.isRequired,
};

export default ConfirmSaveSearchDialog;
