import qs from 'qs';

import http from '../classes/Http';

let validationCtrl = null;

const serializeParams = (params) => {
    return qs.stringify(params, {addQueryPrefix: true, indices: false});
};

/**
 * 
 * @deprecated use APIv2 version of validateQuery service. This is left here just for the mentions table
 * @param {{query: string, projectId: number, isSaved: boolean}} params 
 * @returns 
 */
export const validateQuery = async ({query, projectId, isSaved}) => {
    if (validationCtrl) validationCtrl.abort();
    validationCtrl = new AbortController();
    let res;
    if (isSaved) {
        res = await http.post(`/api/projects/${projectId}/query-validation`, {
            booleanQuery: query,
            languages: ['en'],
        });
    } else {
        res = await http.get(
            `/api/projects/${projectId}/search/query-validation?search=${encodeURIComponent(
                query,
            )}`,
        );
    }
    validationCtrl = null;
    //Check if all the reported issues are 'unsupported_proximity', change the
    //error from 'This query has multiple issues.' to 'Proximity syntax not supported.'
    //so the rest of the application deals with the error correctly
    if (
        res.body?.issues?.length > 0 &&
        res.body.issues.every(({errorCode}) => errorCode === 'unsupported_proximity')
    ) {
        //
        return Promise.resolve({
            ...res.body,
            errors: ['Proximity syntax not supported.'],
        });
    }

    return Promise.resolve(res.body);
};

export const validateFilterQuery = async ({query, projectId}) => {
    if (validationCtrl) validationCtrl.abort();
    validationCtrl = new AbortController();
    const res = await http.get(
        `/api/projects/${projectId}/query-validation/searchwithin${serializeParams({
            booleanQuery: query,
            languages: ['en'],
        })}`,
    );
    validationCtrl = null;
    //Check if all the reported issues are 'unsupported_proximity', change the
    //error from 'This query has multiple issues.' to 'Proximity syntax not supported.'
    //so the rest of the application deals with the error correctly
    if (
        res.body?.issues?.length > 0 &&
        res.body.issues.every(({errorCode}) => errorCode === 'unsupported_proximity')
    ) {
        return Promise.resolve({
            ...res.body,
            errors: ['Proximity syntax not supported.'],
        });
    }

    return Promise.resolve(res.body);
};
