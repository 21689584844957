import {AllFilteringState} from 'hsi/types/filters';
import {LegacySearchState, QueryExportState, QueryStateType} from 'hsi/types/query';

//I think this actually only exists for the middlewares persistence layer...
export function createQueryObjFromState({
    queryState,
    filterState,
    exportState,
    savedSearchId,
}: {
    queryState: QueryStateType;
    filterState: Pick<AllFilteringState, 'collapsedSections' | 'dateRange' | 'filters' | 'version'>;
    exportState: QueryExportState;
    savedSearchId?: number;
}): LegacySearchState {
    const {
        persistId: id,
        guidedForm: formState,
        booleanQuery,
        additionalQueries,
        linkedinChannelIds,
        context,
    } = queryState;
    const {collapsedSections, dateRange, filters, version} = filterState;
    if (savedSearchId || context?.savedSearchId) {
        return {
            id: id ?? undefined,
            queryId: savedSearchId || context?.savedSearchId || null,
            booleanQuery,
            formState,
            filterState: {collapsedSections, dateRange, filters, version},
            exportState,
            additionalQueries,
            linkedinChannelIds,

            created: '',
            modified: '',
        };
    } else {
        return {
            id: id ?? undefined,
            queryId: null,
            booleanQuery,
            formState,
            filterState: {collapsedSections, dateRange, filters, version},
            exportState,
            additionalQueries: null,
            linkedinChannelIds: null,

            //Hmm...
            created: '',
            modified: '',
        };
    }
}

export function createDefaultQueryObj({
    booleanQuery,
    savedSearchId,
}: {
    booleanQuery: string;
    savedSearchId?: number;
}): LegacySearchState {
    return {
        booleanQuery,
        queryId: savedSearchId || null,

        formState: null,
        filterState: {
            collapsedSections: {},
            dateRange: {
                startDate: '',
                endDate: '',
                timezone: '',
                relativeRange: null,
            },
            filters: {},
            version: 0,
        },
        additionalQueries: null,
        linkedinChannelIds: null,
        exportState: {
            cardsToExport: [],
            annotations: {},
            currentCard: null,
            isPortalEnabled: true,
            title: '',
        },

        //Hmm...
        created: '',
        modified: '',
    };
}

//BCR-1375 patch query state to persist: delete guided form state when saving from edit mode & not guided
export function parseGuidedFormToSave(queryState: QueryStateType): QueryStateType {
    const {
        guidedForm,
        isGuided,
        context: {isEditSearch},
    } = queryState;
    return {
        ...queryState,
        guidedForm: isEditSearch && !isGuided ? null : guidedForm,
    };
}
