import http from "hsi/classes/Http";
import { ApiResponse } from "hsi/types/apiv2";
import qs from "qs";

export type ValidateQueryIssue = {
    startColumn: number;
    endRow: number;
    startRow: number;
    endColumn: number;
    errorCode: string | null;
    message: string;
    type: 'error' | 'info'; // There may be other values here
};

export type ValidateQueryResponse = {
    query: string;
    errors: string[];
    issues: ValidateQueryIssue[];
    valid: boolean;
};

/**
 * Validate a query
 * @param projectId I do not know why this is required
 * @param query the boolean query to test
 * @param isSavedSearch
 * @returns 
 */
export async function validateQuery(projectId: number, query: string, isSavedSearch: boolean) {
    const url = isSavedSearch 
        ? `/apiv2/${projectId}/saved-search/validate-query?${qs.stringify({query})}`
        : `/apiv2/${projectId}/quick-search/${encodeURIComponent(query)}/validate`;

    return http.get<ApiResponse<ValidateQueryResponse>>(url)
}