import {find} from 'lodash';
import {
    OPEN_SAVE_SEARCH_DIALOG,
    OPEN_EDIT_SAVE_SEARCH_DIALOG,
    CLEAR_ERROR,
    CLEAR_RESULTS,
    DELETE_SEARCH,
    LOAD_SEARCHES,
    SET_SEARCHES_LOADING,
    SET_SEARCH_LOADING,
    SAVE_SEARCH_ERROR,
    SAVE_SEARCH_SUCCESS,
    SAVE_SEARCH_START,
    UPDATE_SEARCH_START,
    UPDATE_SEARCH_ERROR,
    UPDATE_SEARCH_SUCCESS,
    MORE_PROJECT_QUERIES_LOADED,
    PROJECT_QUERY_AND_GROUPS_LOADED,
    SEARCH_LOADED,
} from '../constants/actionTypes';
import { AnyAction } from 'redux';
import { BCRApiError, SavedSearchType } from 'hsi/types/shared';

export type SavedSearchReducerState = {
    searches: SavedSearchType[];
    searchesLoading: boolean;
    searchesLoaded: boolean;
    searchesEverLoaded: boolean,
    searchesByProject: Record<number, SavedSearchType>,
    error:  string | BCRApiError | null,
    /** The 6 most recently modified saved searches */
    lastModifiedSearches: SavedSearchType[],
    openSaveDialog: boolean,
    editQueryId: number | null,
    saving: boolean,
    searchLoading: boolean | null,
}

const initialState: SavedSearchReducerState = {
    searches: [],
    searchesLoading: false,
    searchesLoaded: false,
    searchesEverLoaded: false,
    searchesByProject: {},
    error: null,
    lastModifiedSearches: [],
    openSaveDialog: false,
    editQueryId: null,
    saving: false,
    searchLoading: null,
};

function sortSearchResults(results: SavedSearchType[]) {
    return {
        searches: results.sort(function (a, b) {
            if (a.creationDate < b.creationDate) return -1;
            if (a.creationDate > b.creationDate) return 1;
            return 0;
        }),
        lastModifiedSearches: results
            .sort(function (a, b) {
                if (a.lastModificationDate < b.lastModificationDate) return 1;
                if (a.lastModificationDate > b.lastModificationDate) return -1;
                return 0;
            })
            .slice(0, 6),
    };
};

export default function searchReducer (state: SavedSearchReducerState = initialState, action: AnyAction): SavedSearchReducerState {
    switch (action.type) {
        case OPEN_SAVE_SEARCH_DIALOG:
            return {
                ...state,
                openSaveDialog: action.payload,
                editQueryId: action.payload ? null : state.editQueryId,
            };
        case OPEN_EDIT_SAVE_SEARCH_DIALOG:
            return {
                ...state,
                openSaveDialog: action.payload.isOpen,
                editQueryId: action.payload.queryId,
            };
        case SAVE_SEARCH_START:
        case UPDATE_SEARCH_START:
            return {...state, saving: true, error: null};
        case CLEAR_ERROR:
            return {...state, error: null};
        case CLEAR_RESULTS:
            return {
                ...state,
                error: null,
                saving: false,
            }
        case DELETE_SEARCH:
            const results = state.searches.filter((s) => s.id !== action.payload.id);
            return Object.assign({}, state, sortSearchResults(results));
        case LOAD_SEARCHES:
            return {
                ...state,
                ...sortSearchResults(action.payload.results),
                searchesLoaded: true,
                searchesEverLoaded: true,
            };
        case SET_SEARCHES_LOADING:
            return {
                ...state,
                searchesLoading: action.payload,

                //If we're loading searches, searches aren't loaded, otherwise the value will remain unchanged
                searchesLoaded: action.payload ? false : state.searchesLoaded,
            };
        case MORE_PROJECT_QUERIES_LOADED:
            const currentSearchIds = state.searches.map((s) => s.id);
            const newSearches = (action.payload as SavedSearchType[]).filter((r) => !currentSearchIds.includes(r.id));
            return {...state, searches: [...state.searches, ...newSearches]};
        case PROJECT_QUERY_AND_GROUPS_LOADED:
            const {projectId, items} = action.payload;
            return {
                ...state,
                searchesByProject: {...state.searchesByProject, [projectId]: items},
                searchLoading: false,
            };
        case SAVE_SEARCH_ERROR:
        case UPDATE_SEARCH_ERROR:
            return {...state, error: action.payload, saving: false};
        case SAVE_SEARCH_SUCCESS:
            return {
                ...state,
                error: null,
                saving: false,
                searches: [action.payload, ...state.searches],
                lastModifiedSearches: [action.payload, ...state.lastModifiedSearches].slice(0, 6),
            };
        case SET_SEARCH_LOADING:
            return {...state, searchLoading: action.payload};
        case UPDATE_SEARCH_SUCCESS:
            const updateSearches = state.searches.map((s) =>
                s.id === action.payload.id ? action.payload : s,
            );
            return {
                ...state,
                ...sortSearchResults(updateSearches),
            };
        case SEARCH_LOADED:
            // check replace
            const found = find(state.searches, (s) => s.id === action.payload.id);
            const allSearches = found
                ? state.searches.map((s) => (s.id === action.payload.id ? action.payload : s))
                : [action.payload, ...state.searches];
            return {
                ...state,
                ...sortSearchResults(allSearches),
            };
        default:
            return state;
    }
};
