import {useCallback, useMemo} from 'react';
import cn from 'classnames';
//Components
import ExportHeader from 'hsi/components/ExportHeader';
//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useGetCards from 'hsi/hooks/useGetCards';
import useOtherQueryNames from 'hsi/hooks/useOtherQueryNames';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppSelector} from 'hsi/hooks/useRedux';
import useStyles from './styles';
import useTimezone from 'hsi/hooks/useTimezone';

//Other
import {T} from 'hsi/i18n';
//Constants
import {AnnotationKey} from 'hsi/types/charts';
import { IsCardInteractivityDisabled } from 'hsi/contexts/IsCardInteractivityDisabled';
//The component
export default function SearchResults() {
    const classes = useStyles();
    const {searchResults: config, exportType} = useConfig();
    const names = useOtherQueryNames();
    const {savedSearchId, savedSearch, isMultipleSearch} = useQueryContext();
    const timezone = useTimezone();

    //redux
    const dateRange = useAppSelector((state) => state.filters.dateRange);
    const {cardsToExport, annotations} = useAppSelector((state) => state.pdfExport);

    const searchAnnotations = savedSearchId ? annotations[savedSearchId] : null;
    const renderableCardsList = savedSearchId ? cardsToExport[savedSearchId] : null;

    //filtered by user selected list
    const rules = {
        ...config,
        cardRules: config.cardRules.filter(
            ({name}) => renderableCardsList?.length === 0 || renderableCardsList?.includes(name),
        ),
    };
    const cards = useGetCards(rules);
    const renderAnnotation = useCallback(
        (name: AnnotationKey) => {
            if (!searchAnnotations?.[name]) {
                return null;
            }
            return (
                <div className={classes.annotations}>
                    {name === 'summary'
                        ? T('exportToPDF.annotations.summary')
                        : `${T(`cards.${name}.title`)} ${T(
                              'exportToPDF.annotations.notes',
                          ).toLowerCase()}`}
                    <div className={classes.text}>{searchAnnotations[name]}</div>
                </div>
            );
        },
        [searchAnnotations, classes],
    );
    const cardElements = useMemo(
        () =>
            cards.map((card) => (
                <div key={card.name} className={classes.sectionWrapper}>
                    <card.component title={card.title} height={card.height || 320} />
                    {renderAnnotation(card.name)}
                </div>
            )),
        [cards, renderAnnotation, classes],
    );
    const subtitle = useMemo(
        () =>
            isMultipleSearch
                ? T('exportToPDF.header.multipleSearch', {other: names?.join(', ')})
                : null,
        [isMultipleSearch, names],
    );

    if(!savedSearch) {
        throw new Error('Saved search only');
    }

    //Render
    return (
        <div className={classes.contents} data-testid="exportSearchResults">
            <ExportHeader
                title={savedSearch.name}
                subtitle={subtitle}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                timezone={timezone}
                tagline={T(`exportToPDF.header.${exportType}`)}
            />
            {renderAnnotation('summary')}
            <IsCardInteractivityDisabled.Provider value={true}>
                <div className={cn(classes.cardArea, 'card-area')}>{cardElements}</div>
            </IsCardInteractivityDisabled.Provider>
        </div>
    );
}
