import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import LineChart from 'hsi/components/LineChart';

//Hooks
import useDates from 'hsi/hooks/useDates';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryContext from 'hsi/hooks/useQueryContext';
import useQueryId from 'hsi/hooks/useQueryId';
import { useGetCardBreakdownSetter, getCardBreakdownOptions } from 'hsi/utils/cards/breakdowns';
import { useGetCardAggregateSetter, getCardAggregateOptions } from 'hsi/utils/cards/aggregates';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Utils
import {formatHistoryPeaks, hasHistoryData} from 'hsi/utils/chart';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//The component
const HistoryCard = React.forwardRef(
    (
        {
            breakdown,
            aggregate,
            config,
            data,
            error,
            loadData: _loadData,
            loaded,
            loading,
            title,
            trackingName,
            type,
            usesIndexedValues,
            ...props
        },
        ref,
    ) => {
        const classes = useStyles();
        const dispatch = useDispatch();
        const dates = useDates();
        const {trackWithSearchData} = useEventTrack();
        //Redux
        const dateRange = useSelector((state) => state.filters.dateRange);
        const isSelectedCard = useSelector((state) => state.mentions.drillInCard === type);
        const peaks = useSelector((state) => state.results.peaks);

        const {timezone} = useQueryContext(); // TODO: This should probably use the useTimezone hook

        //Calculated values
        const daysShown = useMemo(
            () => dates.getDaysAmount(dateRange.startDate, dateRange.endDate),
            [dates, dateRange],
        );
        const hasData = hasHistoryData(data, usesIndexedValues);

        //Callbacks
        const isValidDateRange = useCallback(
            (breakdown) => breakdown !== 'hours' || (breakdown === 'hours' && daysShown < 1),
            [daysShown],
        );

        const loadData = useCallback(
            (isBreakdown, newBreakdown) => {
                if (!isBreakdown) return _loadData(false);

                if (isValidDateRange(newBreakdown)) {
                    return _loadData(true);
                } else {
                    return;
                }
            },
            [_loadData, isValidDateRange],
        );

        const onItemClick = useCallback(
            (item, peak = null) => {
                const label = config.drillInLabel(item);
                trackWithSearchData('cardDrilledIn', {
                    breakdown,
                    type,
                    value: label,
                });

                const dateRange = {
                    startDate: dates.getStartOf(item.date, breakdown === 'hours' ? 'hour' : 'day'),
                    endDate: dates.getFutureDate(item.date, {[breakdown]: 1}),
                };

                dispatch(mentionsDrillIn(config.filters(item), type, dateRange, label, peak));
            },
            [breakdown, config, dates, dispatch, trackWithSearchData, type],
        );

        const queryId = useQueryId();
        const setCardBreakdown = useGetCardBreakdownSetter(queryId, type);
        const setCardAggregate = useGetCardAggregateSetter(queryId, type);

        const updateCardBreakdown = useCallback(
            (newBreakdown) => {
                setCardBreakdown(newBreakdown);
                loadData(true, newBreakdown);
                trackWithSearchData('cardCustomized', {
                    breakdown: newBreakdown,
                    type,
                });
            },
            [setCardBreakdown, loadData, trackWithSearchData, type],
        );

        const updateCardAggregate = useCallback(
            (newAggregate) => {
                setCardAggregate(newAggregate);
                loadData(true, newAggregate);
                trackWithSearchData('cardCustomized', {
                    breakdown: newAggregate,
                    type,
                });
            },
            [setCardAggregate, loadData, trackWithSearchData, type],
        );

        const tooltipUnit = useCallback((type, aggregate) => {
            const units = {
                netSentimentHistory: T('score'),
                volume: T('mentions'),
                impressions: T('impressions'),
                reachEstimate: T('reach'),
            };

            return type === 'netSentimentHistory' ? units[type] : units[aggregate];
        }, []);

        const content = useMemo(() => {
            const formattedPeaks = aggregate === 'volume' ? formatHistoryPeaks(peaks, type) : [];

            return (
                <div className={classes.historyCardContent} data-testid="historyCardChart">
                    <div className="history-card-chart">
                        <LineChart
                            categories={data?.categories}
                            drillInCard={onItemClick}
                            interval={data?.interval}
                            peaks={formattedPeaks}
                            series={data?.series}
                            timezone={timezone}
                            tooltipUnit={tooltipUnit(type, aggregate)}
                        />
                    </div>
                </div>
            );
        }, [classes, data, onItemClick, peaks, timezone, type, aggregate, tooltipUnit]);

        const historyCardTitle = useMemo(() => {
            return React.cloneElement(title, {
                updateBreakdown: updateCardBreakdown,
                breakdown,
                breakdownOptions: getCardBreakdownOptions(type),
                updateAggregate: updateCardAggregate,
                aggregate: aggregate,
                aggregateOptions: getCardAggregateOptions(type),
            });
        }, [title, updateCardBreakdown, breakdown, updateCardAggregate, aggregate, type]);

        return (
            <CardLoadState
                {...props}
                title={historyCardTitle}
                error={error}
                loading={loading}
                loaded={loaded}
                hasData={hasData}
                unsupported={!isValidDateRange(breakdown)}
                selected={isSelectedCard}
                loadData={loadData}
                ref={ref}
                type={type}
            >
                {content}
            </CardLoadState>
        );
    },
);

HistoryCard.propTypes = {
    breakdown: PropTypes.string.isRequired,
    config: PropTypes.shape({
        drillInLabel: PropTypes.func.isRequired,
        filters: PropTypes.func.isRequired,
    }),
    data: PropTypes.object,
    error: PropTypes.bool,
    loadData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    title: PropTypes.element.isRequired,
    trackingName: PropTypes.shape({
        customized: PropTypes.string.isRequired,
        drillIn: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
};

HistoryCard.displayName = 'HistoryCard';

export default HistoryCard;
