//This should probably be refactored to make the link between a card and how it gets exported clearer.
//Currently, if you change a caard it's not at all clear that you will also need to go somewhere else
//to change how it gets exported.

import {forwardRef, useCallback, useMemo} from 'react';
import cn from 'classnames';

import IconButton, {IconButtonProps} from '@mui/material/IconButton';

// Components
import IconRouter from 'hsi/components/IconRouter';
import SimpleMenu from 'hsi/components/SimpleMenu';
import Tooltip from 'hsi/components/Tooltip';

// Actions
import {exportCard, exportAsCSV} from 'hsi/actions/exportActions';

// Hooks
import useConfig from 'hsi/hooks/useConfig';
import useDates from 'hsi/hooks/useDates';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

// Other
import formatter from 'hsi/utils/csvFormattingUtils';
import useStyles from './styles';
import {T} from 'hsi/i18n';
import {ChartKey} from 'hsi/types/charts';
import { useGetCurQueryCardBreakdown } from 'hsi/utils/cards/breakdowns';

const AVAILABLE_FORMATS = ['jpg', 'png', 'csv'] as const; // TODO: Use types available in actions
export type AvailableFormatType = (typeof AVAILABLE_FORMATS)[number];

interface ExportButtonProps extends IconButtonProps {
    classes: ReturnType<typeof useStyles>;
    className?: string;
    disabled?: boolean;
}

//The export button
const ExportButton = forwardRef(
    (
        {classes, disabled = false, className, onClick, ...rest}: ExportButtonProps,
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => (
        <IconButton
            aria-label={T('exportCard.buttonLabel')}
            className={cn(classes.config, className)}
            onClick={onClick}
            disabled={disabled}
            ref={ref}
            {...rest}
            >
            <IconRouter name="cloud-download" />
        </IconButton>
    ),
);

type ExportMenuProps = {
    mainTitle?: string;
    type: ChartKey;
    disabled?: boolean;
};

//The component
const ExportMenu = ({mainTitle, type, disabled}: ExportMenuProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {cardExportMenu} = useConfig();
    const {formatTo} = useDates();
    const {trackWithSearchData} = useEventTrack();

    const {isSavedSearch, savedSearch, isMultipleSearch} = useQueryContext();
    const isExporting = useAppSelector((state) => state.pdfExport.isExporting);
    const dateRange = useAppSelector((state) => state.filters.dateRange);
    const data = useAppSelector((state) => state.chart[type]?.data);
    const breakdown = useGetCurQueryCardBreakdown(type);

    const getDateRange = useCallback(
        () => `${formatTo(dateRange.startDate, 'DDD')} - ${formatTo(dateRange.endDate, 'DDD')}`,
        [formatTo, dateRange],
    );

    const getData4Csv = useCallback(
        <K extends keyof typeof formatter>(data: Parameters<(typeof formatter)[K]>[0], type: K) => {
            // TODO: Data should be typed properly here but doesn't work for some reason
            return formatter[type](data as any, savedSearch?.name, breakdown!);
        },
        [savedSearch?.name, breakdown],
    );

    const handleCardExport = useCallback(
        (format: AvailableFormatType) => async () => {
            trackWithSearchData('cardExported', {
                format,
                type,
                breakdown,
            });

            switch (format) {
                case 'csv':
                    const data4CSV = getData4Csv(data, type as keyof typeof formatter);
                    dispatch(exportAsCSV(data4CSV, getDateRange(), mainTitle));
                    break;
                default:
                    dispatch(exportCard(type, T(`cards.${type}.title`), format, savedSearch?.name ?? ''));
            }
        },
        [
            dispatch,
            savedSearch,
            type,
            getDateRange,
            getData4Csv,
            data,
            breakdown,
            mainTitle,
            trackWithSearchData,
        ],
    );

    const getMenuEntry = useCallback(
        (format: AvailableFormatType) => {
            return cardExportMenu?.hasFormatIcon ? (
                <div className={classes.entry}>
                    <span className={classes.formatIcon} aria-hidden>
                        {format}
                    </span>
                    <span>{T(`exportCard.${format}`)}</span>
                </div>
            ) : (
                T(`exportCard.${format}`)
            );
        },
        [cardExportMenu, classes],
    );

    const entries = useMemo(() => {
        return (
            isMultipleSearch || type === 'topicWheel'
                ? AVAILABLE_FORMATS.filter((format) => format !== 'csv')
                : AVAILABLE_FORMATS
        ).map((format) => ({
            label: getMenuEntry(format),
            onClick: handleCardExport(format),
        }));
    }, [getMenuEntry, handleCardExport, isMultipleSearch, type]);

    return (
        <>
            <Tooltip
                disable={isExporting}
                tooltip={T('exportCard.exportComponent')}
                distance={12}
                placement="top"
            >
                <SimpleMenu
                    title={'Export this card as: '}
                    hideTitle
                    entries={entries}
                    buttonComponent={
                        <ExportButton disabled={!!disabled || !isSavedSearch} classes={classes} />
                    }
                    menuDistance={8}
                />
            </Tooltip>
        </>
    );
};

export default ExportMenu;
