import {ReactElement} from 'react';

import useStyles from './styles';


type ItemWithHeaderProps = {
    header: ReactElement;
    headerRight?: ReactElement | null;
} & JSX.IntrinsicElements['div'];

export default function ItemWithHeader({
    header,
    headerRight,
    children,
    ...rest
}: ItemWithHeaderProps) {
    const classes = useStyles();
    
    return (
        <div className={classes.itemWithHeader}>
            <div className={classes.header}>
                {header}
                {headerRight && <span className={classes.headerRight}>
                    {headerRight}
                </span>}
            </div>
            {children}
        </div>
    );
}
