//TODO
//-onShow/Hide callbacks,
//-styling
//-'arrow' config option
import {
    forwardRef,
    memo,
    cloneElement,
    useState,
    useMemo,
    useEffect,
    useRef,
    createContext,
    useContext,
    HTMLAttributes,
    ReactNode,
    Children,
    ReactElement,
} from 'react';
import classNames from 'classnames';
import {
    offset,
    flip as flipMiddleware,
    shift as shiftMiddleware,
    size,
    autoUpdate,
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    useClick,
    useHover,
    useFocus,
    FloatingFocusManager,
    FloatingPortal,
    FloatingOverlay,
    Placement,
    Middleware,
    Boundary,
} from '@floating-ui/react-dom-interactions';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';
import useMemoCompare from 'hsi/hooks/useMemoCompare';
import useRefCallback from 'hsi/hooks/useRefCallback';
import {useGetPortalRoot} from 'hsi/contexts/portal';

//Utils
import {assignRef, combineProps} from 'hsi/utils/react';

//Other
import useStyles from './styles';
import {isEqual} from 'lodash';

//Types
type PopoverRenderFuncArgs = {labelId: string; descriptionId: string; close: () => void};
export type PopoverRenderFunc = (props: PopoverRenderFuncArgs) => ReactNode;

interface PopoverArgs<T extends HTMLElement = HTMLElement>
    extends Omit<HTMLAttributes<T>, 'content'> {
    content: ReactNode | PopoverRenderFunc;
    show?: boolean;
    flip?: boolean;
    shift?: boolean;
    distance?: Parameters<typeof offset>[0];
    placement?: Placement;
    boundary?: Boundary;
    size?: NonNullable<Parameters<typeof size>[0]>['apply'];
    portal?: boolean;
    overlay?: boolean;
    asTooltip?: boolean;
    debug?: boolean /*, modal, autoFocus*/;
    onShow?: () => void;
    onHide?: () => void;
    padding?: number;
    disabled?: boolean;
    visuallyHiddenDismiss?: string | boolean;
}

//The component
const Popover = memo(
    forwardRef<HTMLElement | null, PopoverArgs>(function Popover(
        {
            children,
            content,
            show = undefined,
            flip = true,
            shift = true,
            distance = 5,
            placement = 'bottom',
            boundary,
            size: sizeApply,
            id,
            portal = false,
            overlay = false,
            asTooltip = false,
            debug /*, modal, autoFocus*/,
            onShow = undefined,
            onHide = undefined,
            padding = 0,
            disabled = false,
            visuallyHiddenDismiss,
            ...rest
        }: PopoverArgs,
        ref,
    ) {
        debug && console.log('render Popover');

        const classes = useStyles();
        const [open, setOpen] = useState(false);
        const firstTimeRef = useRef(true);

        const portalRoot = useGetPortalRoot();

        //Calculated values
        const isOpen = disabled ? false : show ?? open;

        const {x, y, reference, floating, strategy, context} = useFloating({
            open: isOpen,
            onOpenChange: disabled ? undefined : setOpen,
            middleware: [
                offset(distance),
                size ? size({apply: sizeApply}) : null,
                flip && flipMiddleware({boundary}),
                shift && shiftMiddleware({padding}),
            ].filter((x) => !!x) as Middleware[],
            placement,
            whileElementsMounted: autoUpdate,
        });

        id = useUniqueId(id, 'popover');
        const labelId = `${id}-label`;
        const descriptionId = `${id}-description`;

        const {getReferenceProps, getFloatingProps} = useInteractions([
            useHover(context, {enabled: asTooltip}),
            useFocus(context, {enabled: asTooltip}),
            useClick(context, {enabled: !asTooltip}),
            useRole(context, {role: asTooltip ? 'tooltip' : 'dialog'}),
            useDismiss(context, {outsidePress: false}),
        ]);

        //Side effect
        useEffect(
            () => {
                if (firstTimeRef.current) {
                    firstTimeRef.current = false;
                    return;
                }

                isOpen ? onShow?.() : onHide?.();
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [isOpen],
        );

        useEffect(
            () => {
                //Popover will be set to closed if it is disabled
                if (open && disabled) {
                    setOpen(false);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [disabled],
        );

        //Rendering
        const renderedContent = useMemo(() => {
            debug && console.log('Popover renderedContent');
            const props = {
                labelId,
                descriptionId,
                close: () => {
                    setOpen(false);
                },
            };

            return (
                <PopoverContext.Provider value={props}>
                    {content instanceof Function ? content(props) : content}
                </PopoverContext.Provider>
            );
        }, [content, labelId, descriptionId, setOpen, debug]);

        const floatingRef = useRefCallback((...args: Parameters<typeof floating>) =>
            floating(...args),
        );

        const floatingProps = useMemoCompare(
            () =>
                getFloatingProps({
                    className: classes.popover,
                    ref: floatingRef,
                    style: {
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                    },
                    onKeyDown: (e) => {
                        if (e.which === 27) {
                            e.stopPropagation();
                            setOpen(false);
                        }
                    },
                    'aria-labelledby': labelId,
                    'aria-describedby': descriptionId,
                }),
            [classes.popover, floatingRef, strategy, x, y, setOpen, labelId, descriptionId],
            isEqual,
            (key, value) => key.startsWith('on') && value instanceof Function,
        );

        const popoverElement = useMemo(() => {
            debug && console.log('Popover popoverElement');
            return <div {...floatingProps}>{renderedContent}</div>;
        }, [renderedContent, floatingProps, debug]);

        const popoverContent = useMemo(() => {
            debug && console.log('Popover popoverContent');

            //onKeyDown handler is needed to make esc close work + play nice with MUI dialogs and their close on esc handling
            return (
                isOpen &&
                (asTooltip ? (
                    popoverElement
                ) : (
                    <FloatingOverlay
                        //replace the default click outside handling from the useDismiss hook with this,
                        //because when using nested popovers, clicking outside would close all of them
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                //If this element has been clicked, close and stop processing the click
                                setOpen(false);
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                        className={classNames(overlay ? classes.overlay : classes.blankOverlay)}
                    >
                        <FloatingFocusManager
                            context={context}
                            visuallyHiddenDismiss={visuallyHiddenDismiss}
                        >
                            {popoverElement}
                        </FloatingFocusManager>
                    </FloatingOverlay>
                ))
            );
        }, [
            debug,
            isOpen,
            asTooltip,
            popoverElement,
            overlay,
            classes.overlay,
            classes.blankOverlay,
            context,
            visuallyHiddenDismiss,
        ]);

        const targetRef = useRefCallback((elem: HTMLElement) => {
            if (debug) {
                debugger;
            }
            assignRef(reference, elem);
            assignRef((children as any).ref, elem);
            assignRef<HTMLElement>(ref, elem);
        });

        const referenceProps = useMemoCompare(
            () => getReferenceProps(),
            [getReferenceProps],
            undefined,
            (key, value) => key.startsWith('on') && value instanceof Function,
        );

        const childElem = useMemo(() => {
            debug && console.log('Popover childElem');

            const child = Children.only(children) as ReactElement;

            return cloneElement(
                child,
                combineProps(
                    {
                        ref: targetRef,
                        tabIndex: asTooltip ? '0' : undefined,
                        'aria-expanded': isOpen,
                    },
                    child.props,
                    rest,
                    referenceProps,
                ),
            );
        }, [debug, children, targetRef, asTooltip, isOpen, rest, referenceProps]);

        return useMemo(() => {
            debug && console.log('Popover render');
            return (
                <>
                    {childElem}
                    {portal ? (
                        <FloatingPortal root={portalRoot}>{popoverContent}</FloatingPortal>
                    ) : (
                        popoverContent
                    )}
                </>
            );
        }, [childElem, portal, portalRoot, popoverContent, debug]);
    }),
);

export default Popover;

//helper utils
export const sizeToOpener: NonNullable<Parameters<typeof size>[0]>['apply'] = (...args: any[]) => {
    const [{elements, rects}] = args;

    Object.assign(elements.floating.style, {
        width: `${rects.reference.width}px`,
    });
};

const PopoverContext = createContext<PopoverRenderFuncArgs | undefined>(undefined);
PopoverContext.displayName = 'PopoverContext';

export function usePopoverContext() {
    return useContext(PopoverContext);
}
