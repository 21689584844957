import {forwardRef} from 'react';

import useCardData from 'hsi/hooks/useCardData';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle, { formatCardTitleAsString } from 'hsi/components/Card/CardTitle';
import ManagedGridTable from 'hsi/components/table/ManagedGridTable';

//Other
import {useDataParser} from './useDataParser';
import useGetDrillInData from './useGetDrillInData';
import { CardTypeProps } from 'hsi/types/cards';
import useGetTableColumns from './useGetTableColumns';

import useStyles from './styles';
import {T} from 'hsi/i18n';
import useCardTableTrackingAndPersistence from '../hooks/useCardTableTrackingAndPersistence';
import useAddPositionToRows from '../hooks/useAddPositionToRows';
import useSortRows from 'hsi/components/table/ManagedTable/useSortRows';
import usePaginateRows from 'hsi/components/table/ManagedTable/usePaginateRows';
import useFormatRows from 'hsi/components/table/ManagedTable/useFormatRows';
import { TotalVolumeBySearchRowItem } from './types';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

//Constants
const type = 'totalVolumeBySearch';
const DEFAULT_DATA: TotalVolumeBySearchRowItem[] = [];
const ROWS_PER_PAGE = 10;

//The component
const TotalVolumeBySearch = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const classes = useStyles();
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    const {searchIds} = useMultipleSearchIds();
    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();

    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });

    const [rows, metrics, hasData] = useDataParser(
        data,
        searchIds,
    );

    const columns = useGetTableColumns(metrics, classes);

    const {page, pages, setPage, ...sorting} = useCardTableTrackingAndPersistence<TotalVolumeBySearchRowItem>(type, columns, {defaultSortColumn: metrics[0] ?? 'volume', totalRows: data?.length ?? 0, rowsPerPage: ROWS_PER_PAGE});

    // Apply sorting
    const sortedRows = useSortRows((rows as TotalVolumeBySearchRowItem[]) ?? DEFAULT_DATA, columns, sorting.sortColumn, sorting.sortDirection);

    // Add position value
    const positionedRows = useAddPositionToRows(sortedRows);

    // Apply pagination
    const paginatedRows = usePaginateRows(positionedRows, page, ROWS_PER_PAGE);

    // Apply formatting
    const formattedRows = useFormatRows(paginatedRows, columns);

    return (
        <CardLoadState
            title={<CardTitle title={title} type={type} hasData={hasData} />}
            error={error}
            hasData={hasData}
            loaded={loaded}
            loading={loading}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <ManagedGridTable
                columns={columns}
                rowData={formattedRows}
                caption={T('managedTable.caption', {name: formatCardTitleAsString(title)})}

                rowSelectable
                onRowClick={(index: number) => {
                    !isCardInteractivityDisabled && drillInMentions(getDrillInData(paginatedRows[index]));
                }}
                {...sorting}
            />
        </CardLoadState>
    );
});

TotalVolumeBySearch.displayName = 'TotalVolumeBySearch';

export default TotalVolumeBySearch;
