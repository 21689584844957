import {createSlice} from '@reduxjs/toolkit';
import {getSnapshot, guestAuth} from './thunks';
import { Snapshot, SnapshotList } from 'hsi/types/snapshots';
import { WritableDraft } from 'immer/dist/types/types-external';

interface SharedDashboardState {
    snapshots: SnapshotList
    currentSnapshot: Snapshot | undefined;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null | undefined;
}

const initialState: SharedDashboardState = {
    snapshots: [],
    currentSnapshot: undefined,
    status: 'idle',
    error: null,
};

const sharedDashboardSlice = createSlice({
    name: 'sharedDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(guestAuth.fulfilled, (state, action) => {
                state.snapshots = action.payload.snapshots;
            })
            .addCase(guestAuth.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getSnapshot.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSnapshot.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.currentSnapshot = action.payload as WritableDraft<Snapshot>;
            })
            .addCase(getSnapshot.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default sharedDashboardSlice.reducer;
