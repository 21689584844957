
import {useMemo} from 'react';

//Hooks
import { TotalVolumeBySearchCardData, TotalVolumeMetricType, TotalVolumePerQueryData } from 'hsi/types/cards';
import { useSearchesById } from 'hsi/hooks/useSearchesById';
import { ParsedTotalVolumeBySearchRow } from './types';
import { TotalVolumeBySearchMetrics } from 'hsi/constants/config';

// The component
export const useDataParser = (data: TotalVolumeBySearchCardData | undefined, queryIds: number[]) => {
    const {searchesById} = useSearchesById();

    const metricsByQueryId = useMemo(() => {
        if (!data || !queryIds) return null;

        return queryIds.reduce((volumePerSearchOutput, queryId) => {
            volumePerSearchOutput[queryId] = TotalVolumeBySearchMetrics.reduce((queryMetricsOutput, metric) => {

                const metricCurrentValues = getMetricCurrentValues(data, metric);
                queryMetricsOutput[metric] = metricCurrentValues 
                    ? sumPerQueryValuesForQueryId(metricCurrentValues, queryId) 
                    : 0;

                return queryMetricsOutput;
            }, {} as Partial<Record<TotalVolumeMetricType, number>>);
            return volumePerSearchOutput;
        }, {} as Record<number, Partial<Record<TotalVolumeMetricType, number>>>);
    }, [data, queryIds]);
    
    const rows = useMemo(
        () =>
            metricsByQueryId === null
                ? null
                : queryIds.map((queryId) => ({
                    id: queryId,
                    name: searchesById[queryId]?.name,
                    ...TotalVolumeBySearchMetrics.reduce<Partial<Record<TotalVolumeMetricType, number | null>>>((res, metric) => {
                        res[metric] = metricsByQueryId?.[queryId]?.[metric] ?? null;
                        return res;
                    }, {}),
                } as ParsedTotalVolumeBySearchRow)),
        [queryIds, searchesById, metricsByQueryId],
    );

    return useMemo(
        () => ([rows, TotalVolumeBySearchMetrics, rows !== null] as const), 
        [rows]
    );
};


function getMetricCurrentValues(data: TotalVolumeBySearchCardData, metric: TotalVolumeMetricType) {
    return metric 
        ? data.find(({id}) => id === metric)?.currentValues ?? undefined//find(data, ({id}) => id === key)?.currentValues
        : undefined ;
};

function sumPerQueryValuesForQueryId(results: TotalVolumePerQueryData[], queryId: number) {
    return results.reduce((acc, curr) => acc + (curr.id === queryId ? curr?.value : 0), 0);
}