import {useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';

// Components
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import AddEditAlert from './AddEditAlert';

// Actions
import {clearError} from 'hsi/slices/alerts';

// Other
import useStyles from './styles';

// Types
import { ApiAlert } from 'hsi/types/alerts';
import { SavedSearchType } from 'hsi/types/shared';

type AlertsDialogProps = {
    title: string;
    alert?: ApiAlert;
    queryId: number; 
    open?: boolean;
    closeFunc: (...props: any[]) => void, 
    savedSearch: SavedSearchType
};


// The component
export default function AlertDialog({title, alert, queryId, open = false, closeFunc, savedSearch}: AlertsDialogProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isVisible, setIsVisible] = useState(open);

    const onClose = useCallback(() => {
        dispatch(clearError());
        closeFunc();
    }, [closeFunc, dispatch]);

    const onClosed = useCallback(() => {
        setIsVisible(false);
    }, []);

    useEffect(() => {
        open && setIsVisible(true);
    }, [open]);

    return (
        <Dialog
            title={title}
            open={open}
            onClose={onClose}
            onClosed={onClosed}
            className={classes.alertDialog}
        >
            {(open || isVisible) && (
                <AddEditAlert //Each time the dialog is opened, we get a new instance of this component, which prevents state from being carried over from previous alerts
                    alert={alert}
                    queryId={queryId}
                    savedSearch={savedSearch}
                    onClose={onClose}
                    wrapContent={wrapContent}
                    wrapActions={wrapActions}
                />
            )}
        </Dialog>
    );
}


//Internal helper methods
function wrapContent(children: JSX.Element) {
    return <DialogContent>{children}</DialogContent>;
}

function wrapActions(children: JSX.Element) {
    return <DialogActions>{children}</DialogActions>;
}
