import {PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import classNames from 'classnames';

//Components
import Drawer from 'hsi/components/Drawer';
import Filters from 'hsi/components/Filters';
import MentionsContainer from 'hsi/containers/MentionsContainer';
import MentionsDrawer from 'hsi/containers/MentionsContainer/MentionsDrawer';
import ConfigSideDrawer from 'hsi/containers/ConfigSideDrawer';
import ToggleDrawerButton from 'hsi/components/ToggleDrawerButton';
import FilterSummary from 'hsi/components/Filters/Summary';
import Heading, {
    HeadingContents,
    ResetHeadingLevel,
    normaliseHeadingLevel,
} from 'hsi/components/aria/Heading';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryContext from 'hsi/hooks/useQueryContext';

//Actions
import {
    setMentionsFull,
    setMentionsIsSelectMode,
    setMentionsOpen,
    setMentionsLayout,
    MentionsLayout,
} from 'hsi/slices/mentions';
import {toggleConfig} from 'hsi/actions/resultsActions';
import {openFilters} from 'hsi/slices/filtersOpen';
import {reset} from 'hsi/slices/filters';
import {persistActiveQuery} from 'hsi/actions/queryActions';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import {trackFilters} from 'hsi/utils/filters';
import {introAnchors} from 'hsi/containers/Intro';
import useIsViewOnly from 'hsi/hooks/useIsViewOnly';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import Tooltip from 'hsi/components/Tooltip';
import useGetFiltersConfig from 'hsi/hooks/useGetFiltersConfig';
import { checkQuickSearchHasData, checkSavedSearchHasData } from 'hsi/slices/searchResults';
import DataCheckStatus from './DataCheckStatus';

type InsightsContainerProps = PropsWithChildren<{
    /**
     * Editing a saved search = false
     */
    isSavedSearch?: boolean;
}>;

//The component
const InsightsContainer = ({children, isSavedSearch = false}: InsightsContainerProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const queryContext = useQueryContext();

    const filtersOpen = useAppSelector((state) => state.filtersOpen);
    const hasFiltersApplied = !useAppSelector((state) => state.filters.noFiltersApplied);
    const numAppliedFilters = useAppSelector((state) => state.filters.numAppliedFilters);
    const {open: isSideDrawerOpen, type: sideDrawerType} = useAppSelector(
        (state) => state.results.sideDrawer
    );
    const {savedSearch} = useQueryContext();

    const isMentionsOpen = useAppSelector((state) => state.mentions.isOpen);
    const mentionsIsSelectMode = useAppSelector((state) => state.mentions.isSelectMode);
    const mentionsFull = useAppSelector((state) => state.mentions.isFull);
    const hasData = useAppSelector((state) => state.searchResults.hasData);
    const canSaveSearch = useAppSelector((state) => state.user.canSaveSearch);
    const validationErrors = useAppSelector((state) => state.query.errors);
    const isEditSearch = useAppSelector((state) => state.query.context.isEditSearch);

    const drillInFilter = useAppSelector((state) => state.mentions.drillInFilter);
    const drillInLabel = useAppSelector((state) => state.mentions.drillInLabel);
    const drillInFrom = useAppSelector((state) => state.mentions.drillInFrom);
    const drillInDates = useAppSelector((state) => state.mentions.drillInDates);
    const peak = useAppSelector((state) => state.mentions.peak);

    const isViewOnly = useIsViewOnly();

    const mentionsFullLayout = useAppSelector((state) => state.mentions.fullLayout);
    const isSelectMode = mentionsIsSelectMode && isSavedSearch && !isViewOnly;
    const isMentionsFull = mentionsFull || isSelectMode;
    const isFiltersOpen = filtersOpen && !isSelectMode;

    const drillIn = useMemo(
        () => ({
            drillInFilter,
            drillInLabel,
            drillInFrom,
            drillInDates,
        }),
        [drillInFilter, drillInLabel, drillInFrom, drillInDates],
    );

    const checkSearchHasData = useCallback(() => {
        dispatch(isSavedSearch 
            ? checkSavedSearchHasData(savedSearch!.id)
            : checkQuickSearchHasData()
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isSavedSearch, savedSearch?.id]);

    useEffect(() => {
        if(hasData === null) {
            checkSearchHasData();
        }
    }, [hasData, checkSearchHasData])

    //Callbacks

    const setIsSelectMode = useCallback(
        (value: boolean) => {
            trackWithSearchData('toggleMentionsSelectMode', {
                state: value,
            });

            dispatch(setMentionsIsSelectMode(value));
        },
        [dispatch, trackWithSearchData],
    );

    const setFiltersOpen = useCallback(
        (value: boolean) => dispatch(openFilters(value)),
        [dispatch],
    );

    const setFullMentionsLayout = useCallback(
        (newLayout: MentionsLayout) => {
            trackWithSearchData('mentionsFullLayoutChanged', {
                state: newLayout,
            });

            dispatch(setMentionsLayout(newLayout));
        },
        [dispatch, trackWithSearchData],
    );

    const doToggleFilters = useCallback(() => {
        if (!filtersOpen) {
            trackWithSearchData('filterDrawerToggled', {
                state: 'open',
            });
        } else {
            trackWithSearchData('filterDrawerToggled', {
                state: 'closed',
            });
        }
        setFiltersOpen(!filtersOpen);
    }, [filtersOpen, setFiltersOpen, trackWithSearchData]);

    const doToggleMentions = useCallback(() => {
        if (!isMentionsOpen) {
            trackWithSearchData('mentionDrawerToggled', {
                state: 'open',
            });
        } else {
            trackWithSearchData('mentionDrawerToggled', {
                state: 'closed',
            });
        }

        dispatch(setMentionsOpen(!isMentionsOpen));
    }, [dispatch, isMentionsOpen, trackWithSearchData]);

    const doToggleConfig = useCallback(() => {
        trackWithSearchData('configDrawerToggled', {
            state: !isSideDrawerOpen ? 'open' : 'closed',
            type: sideDrawerType,
        });
        dispatch(toggleConfig());
    }, [dispatch, isSideDrawerOpen, sideDrawerType, trackWithSearchData]);

    const doToggleMentionsFull = useCallback(() => {
        if (!mentionsFull) {
            trackWithSearchData('mentionDrawerToggled', {
                state: 'expanded',
            });
            dispatch(setMentionsFull(true));
        } else {
            trackWithSearchData('mentionDrawerToggled', {
                state: 'collapsed',
            });
            dispatch(setMentionsFull(false));
        }
    }, [dispatch, mentionsFull, trackWithSearchData]);

    const onFilterChange = useCallback(
        (filterName: string, type: string, ...args: [any, any, any]) => {
            const filterData = trackFilters(filterName, type, ...args);
            if (!!filterData) {
                trackWithSearchData('filterDrawerFilterApplied', {...filterData});
            }
        },
        [trackWithSearchData],
    );

    const onSectionCollapsedChange = useCallback(
        (section: string, collapsed: boolean) => {
            const filter = section === 'pageType' ? 'platform' : section;
            if (collapsed) {
                trackWithSearchData('filterDrawerSectionToggled', {
                    filter,
                    state: 'closed',
                });
            } else {
                trackWithSearchData('filterDrawerSectionToggled', {
                    filter,
                    state: 'open',
                });
            }
        },
        [trackWithSearchData],
    );

    return (
        <div className={classNames('insights-container', classes.insightsContainer)}>
            <Drawer
                as="section"
                aria-label={T('filters.sectionLbl')}
                anchor="left"
                open={isFiltersOpen}
                widthType={'filters'}
                className={classes.filters}
                forceSticky={isMentionsFull}
            >
                {() => (
                    <>
                        <div className={classes.toggleFiltersButtonWrapper}>
                            <Tooltip
                                disable={isFiltersOpen}
                                tooltip={
                                    <ToggleDrawerButtonTooltipContent
                                        isSavedSearch={isSavedSearch}
                                        projectId={queryContext?.projectId!}
                                        isSelectMode={isSelectMode}
                                    />
                                }
                                portal
                            >
                                <ToggleDrawerButton
                                    disabled={isSelectMode}
                                    className={classes.toggleFiltersButton}
                                    drawerSide="left"
                                    open={isFiltersOpen}
                                    toggleDrawer={doToggleFilters}
                                    badge={
                                        numAppliedFilters > 0 ? (
                                            <span aria-hidden>{numAppliedFilters}</span>
                                        ) : undefined
                                    }
                                    openLabel={T('filters.showFiltersLbl', {
                                        num: numAppliedFilters,
                                    })}
                                    closeLabel={T('filters.closeFiltersLbl')}
                                />
                            </Tooltip>
                        </div>
                        <HeadingContents>
                            <Filters.GlobalState
                                className={classNames(
                                    classes.filtersWrapper,
                                    !isFiltersOpen && classes.hide,
                                    introAnchors.filters.filtersPanel,
                                )}
                                canSaveSearch={canSaveSearch}
                                isSavedSearch={isSavedSearch}
                                onChange={() => {
                                    !isEditSearch && dispatch(persistActiveQuery());
                                }}
                                getSectionTestIds={(name: string) => name}
                                onFilterChange={onFilterChange}
                                onSectionCollapsedChange={onSectionCollapsedChange}
                            >
                                {isFiltersOpen && (
                                    <div className={classes.filtersHeader}>
                                        <ResetHeadingLevel
                                            transformLevel={(level) =>
                                                normaliseHeadingLevel(level! - 1)
                                            }
                                        >
                                            <Heading className={classes.filtersTitle}>
                                                {T('filters.title')}
                                            </Heading>
                                        </ResetHeadingLevel>

                                        {hasFiltersApplied && (
                                            <button
                                                onClick={() =>
                                                    dispatch((reset as any)({reset: true}))
                                                } //TODO better typing of the filter slice/reducer
                                                className={classes.clearAllFilters}
                                            >
                                                {T('filters.clearAll')}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </Filters.GlobalState>
                        </HeadingContents>
                    </>
                )}
            </Drawer>

            <DataCheckStatus isSavedSearch={isSavedSearch} hasData={hasData} checkSearchHasData={checkSearchHasData} />

            {!isMentionsFull && (
                <section
                    aria-labelledby="insightsContainer.results.title"
                    className={classNames(
                        classes.content,
                        mentionsFull && classes.contentCollapsed,
                    )}
                    inert={isMentionsFull ? 'inert' : undefined}
                >
                    <Heading id="insightsContainer.results.title" className="offscreen">
                        {T('insightsContainer.results.title')}
                    </Heading>
                    <HeadingContents>
                        {hasData === true && children}
                    </HeadingContents>
                </section>
            )}

            {hasData === true && !validationErrors && (
                <MentionsDrawer
                    isOpen={isMentionsOpen}
                    isFull={isMentionsFull}
                    isSelectMode={isSelectMode}
                    toggleIsOpen={doToggleMentions}
                >
                    <MentionsContainer
                        isSavedSearch={isSavedSearch}
                        isSelectMode={isSelectMode}
                        setIsSelectMode={setIsSelectMode}
                        isFull={isMentionsFull}
                        canEdit={!isViewOnly}
                        toggleIsFull={doToggleMentionsFull}
                        toggleIsOpen={doToggleMentions}
                        setFullLayout={setFullMentionsLayout}
                        fullLayout={mentionsFullLayout}
                        drillIn={drillIn}
                        peak={peak}
                    />
                </MentionsDrawer>
            )}

            <ConfigSideDrawer onClose={doToggleConfig} />
        </div>
    );
};

export default InsightsContainer;

function ToggleDrawerButtonTooltipContent({
    isSavedSearch,
    projectId,
    isSelectMode,
}: {
    isSavedSearch: boolean;
    projectId: number;
    isSelectMode: boolean;
}) {
    const {config: filtersConfig} = useGetFiltersConfig(
        isSavedSearch ? 'saved' : 'quick',
        projectId,
    );
    const filtersState = useAppSelector((state) => state.filters.filters);
    const isNoFilters = useAppSelector((state) => state.filters.noFiltersApplied);

    return useMemo(() => {
        return isNoFilters ? (
            isSelectMode ? (
                T('filters.noActiveFiltersTooltipAndSelectMode')
            ) : (
                T('filters.noActiveFiltersTooltip')
            )
        ) : (
            <>
                <span>{T('filters.filtersSummaryTooltip')}</span>
                <FilterSummary config={filtersConfig} filters={filtersState} />
                {isSelectMode && (
                    <span>
                        <br />
                        {T('filters.filterLockedInSelectMode')}
                    </span>
                )}
            </>
        );
    }, [filtersConfig, filtersState, isNoFilters, isSelectMode]);
}
