import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {AnnotationKey, ChartKey} from 'hsi/types/charts';
import {QueryContextType} from 'hsi/types/query';

export interface ExportPDFState {
    annotations: {
        [key: QueryContextType['savedSearchId']]: Partial<Record<AnnotationKey, string>>;
    };
    cardsToExport: {[key: QueryContextType['savedSearchId']]: ChartKey[]};
    currentCard: ChartKey | null;
    format?: string;
    isExporting?: boolean;
    isPortalEnabled: boolean;
    queryName?: string;
    title: string;
}

const initialState: ExportPDFState = {
    annotations: {},
    cardsToExport: {},
    currentCard: null,
    isPortalEnabled: false,
    format: 'pdf',
    title: '',
};

const exportPDFSlice = createSlice({
    name: 'exportPDF',
    initialState,
    reducers: {
        saveExportConfig: (state, action: PayloadAction<any>) => {
            return {...state, ...action.payload};
        },
        exportCard: (state, action: PayloadAction<any>) => {
            return {...state, ...action.payload};
        },
        exportedCard: (state) => {
            state.currentCard = null;
            state.isExporting = false;
            state.isPortalEnabled = false;
            state.title = initialState.title;
        },
        replacePDFState: (state, action: PayloadAction<ExportPDFState>) => {
            return action.payload;
        },
        resetPDFState: () => {
            return initialState;
        }
    },
});

export const {exportCard, exportedCard, replacePDFState, saveExportConfig, resetPDFState} =
    exportPDFSlice.actions;

export default exportPDFSlice.reducer;
