import http, { HttpResult } from "hsi/classes/Http";
import { ApiResponse, ApiResponseNoData } from "hsi/types/apiv2";
import { QueryData } from "hsi/types/queryData";

const isTest = process.env.NODE_ENV === 'test';

export async function getQueryDataKey<T extends keyof QueryData>(queryId: number, projectId: number, key: T) {
    return http.get<ApiResponse<QueryData[T] | null>>(`/apiv2/${projectId}/saved-search/${queryId}/query-data/${key}`);
}

export async function putQueryDataKey<T extends keyof QueryData>(queryId: number, projectId: number, key: T, value: QueryData[T] | null): Promise<HttpResult<ApiResponseNoData>> {
    return isTest 
    ? Promise.resolve({
        status: 200,
        ok: true,
        body: {
            success: true
        }
    } as HttpResult<ApiResponseNoData>)
    : http.put<ApiResponseNoData>(`/apiv2/${projectId}/saved-search/${queryId}/query-data/${key}`, {value});
}