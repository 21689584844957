import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

// Actions
import { replacePDFState } from 'hsi/slices/pdfExport';
import { replaceResultsState } from 'hsi/actions/resultsActions';
import { replaceUserState } from 'hsi/actions/userActions';
import { replaceChartState } from 'hsi/slices/chart';
import { replaceFilterState } from 'hsi/slices/filters';
import { replaceQueryState } from 'hsi/slices/query';

// Types
import type { RootState } from 'hsi/utils/configureStore';

// Utils
import { getSnapshot } from './thunks';
import { QueryStateType } from 'hsi/types/query';

export const sharedDashboardMiddleware = createListenerMiddleware();

/**
 * Shared Dashboards need to replace state across multiple slices on selecting a report.
 * This middleware listens for a change in a selected report in Shared Dashboards
 * and replaces the state where necessary
 */

sharedDashboardMiddleware.startListening({
    matcher: isAnyOf(getSnapshot.fulfilled),
    effect: async (action, store) => {
        const state = store.getState() as RootState;

        const currentSnapshot = state.sharedDashboard.currentSnapshot?.data;

        if (currentSnapshot) {
            const {chart, filters, query, results, user, pdfExport} = currentSnapshot.state;

            // Chart
            store.dispatch(replaceChartState(chart as Required<typeof chart>));

            // Filters
            // note: TS Issue with calling filter actions
            store.dispatch((replaceFilterState as any)(filters));

            // Query
            store.dispatch(replaceQueryState(query as QueryStateType));

            // Results
            store.dispatch(replaceResultsState(results));

            // User
            store.dispatch(replaceUserState(user));

            // PDF state
            store.dispatch(replacePDFState(pdfExport));
        }
    },
});
