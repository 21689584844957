/* A hook to process annotations (notes) logic for exported reports. */

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {isEmpty} from 'lodash';

import {AnnotationKey} from 'hsi/types/charts';
import useDates from 'hsi/hooks/useDates';
import {T} from 'hsi/i18n';

export const useAnnotations = (
    savedSearchId: number,
    savedSearchName: string,
    initialAnnotations?: Record<number, Record<string, string>>,
    maxLength = 1000,
) => {
    const annotationsRef = useRef<Partial<Record<AnnotationKey, string | undefined>> | null>(
        initialAnnotations ? initialAnnotations[savedSearchId] : {},
    );
    const nameRef = useRef<{name?: string} | null>(null);

    const {formatTo} = useDates();
    const [hasError, setHasError] = useState(false);
    const [hasAnyAnnotations, setHasAnyAnnotations] = useState(() => {
        const initial = initialAnnotations?.[savedSearchId];

        if (!initial) {
            return false;
        }

        return Object.values(initial).some((annotation) => !!annotation);
    });

    const checkAnnotationErrors = useCallback(() => {
        const annotations = annotationsRef.current;
        if (isEmpty(annotations) || !annotations) {
            setHasError(false);
            setHasAnyAnnotations(false);
        } else {
            const annotationsArr = Object.values(annotations);

            setHasError(annotationsArr.some((annotation) => annotation?.length > maxLength));
            setHasAnyAnnotations(annotationsArr.some((annotation) => annotation?.length > 0));
        }
    }, [annotationsRef, maxLength]);

    const setAnnotation = useCallback(
        (name: AnnotationKey, value: string | undefined) => {
            annotationsRef.current = {...annotationsRef.current, [name]: value};
            checkAnnotationErrors();
        },
        [annotationsRef, checkAnnotationErrors],
    );

    const clearAnnotations = useCallback(
        (name?: AnnotationKey) => {
            if (name) {
                setAnnotation(name, '');
            } else {
                annotationsRef.current = {};
                checkAnnotationErrors();
            }
        },
        [annotationsRef, checkAnnotationErrors, setAnnotation],
    );

    const setName = useCallback(
        (name: AnnotationKey, value: string | undefined) => {
            nameRef.current = {[name]: value};
        },
        [nameRef],
    );

    const defaultReportName = useMemo(
        () =>
            T('exportToPDF.header.savedSearch', {
                date: formatTo(new Date(), 'yyyy-LL-dd h:mm:ss'),
                name: savedSearchName,
            }),
        [formatTo, savedSearchName],
    );

    useEffect(() => {
        nameRef.current = {name: defaultReportName};
        checkAnnotationErrors();
    }, [checkAnnotationErrors, defaultReportName, nameRef]);

    return {
        annotationsRef,
        hasError,
        hasAnyAnnotations,
        checkAnnotationErrors,
        setAnnotation,
        clearAnnotations,
        setName,
        nameRef,
    };
};
