// Components
import { useConfig } from "hsi/contexts/ConfigContext";
import PasswordInput from "../PasswordInput";

// Other
import { T } from 'hsi/i18n';
import { useState, useEffect } from "react";

// Types
type PasswordGeneratorProps = {
    setIsPasswordValid: (isValidPassword: boolean) => void;
    password: string;
    setPassword: (newPassword: string) => void;
    onEnterHandler: () => void;
}

// todo: check
/**
 * Password generator component that handles password state and validation
 * @param params
 * @returns 
 */
export default function PasswordGenerator({ setIsPasswordValid, password, setPassword, onEnterHandler }: PasswordGeneratorProps) {
    const { sharedReports: { password: { rules, maxLength } } } = useConfig();
    
    const [error, setError] = useState(false);
    
    // Check validation whenever password changes
    useEffect(() => {
        const isValid = rules.every(({ pattern }) => pattern.test(password));
        setIsPasswordValid(isValid);
        setError(!isValid);
    }, [password, rules, setIsPasswordValid]);

    return (
        <PasswordInput
            label={T('exportReports.passwordGenerator.label')}
            placeholder={T('exportReports.passwordGenerator.placeholder')}
            password={password}
            setPassword={setPassword}
            error={error}
            maxLength={maxLength}
            onEnterHandler={onEnterHandler}
        />
    );
}