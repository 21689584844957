import React, {useCallback, forwardRef, memo, ReactNode, ReactElement} from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Popover, {PopoverRenderFunc} from 'hsi/components/Popover';
import Tooltip from '../Tooltip';

//Utils
import {mergeRefs} from 'hsi/utils/react';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

type PopoverArgs = Parameters<typeof Popover>[0];

type SimpleMenuEntryButton = {
    label: ReactNode;
    onClick?: () => void;
    icon?: ReactNode;
    selected?: boolean;
    disabled?: boolean;
    tooltip?: ReactNode;
};

type SimpleMenuEntryLink = {
    label: ReactNode;
    href: string;
    target?: string;
    icon?: ReactNode;
    onClick?: () => void;
    selected?: boolean;
    disabled?: boolean;
};

type SimpleMenuDivider = {
    divider: true;
};

function isDividerEntry(entry: unknown): entry is SimpleMenuDivider {
    return !!(entry as any)?.divider;
}

function isLinkEntry(entry: SimpleMenuEntry): entry is SimpleMenuEntryLink {
    return !!(entry as any).href;
}

export type SimpleMenuEntry = SimpleMenuEntryButton | SimpleMenuEntryLink | SimpleMenuDivider;

type SimpleMenuArgs = {
    title?: ReactNode;
    hideTitle?: boolean;
    description?: ReactNode;
    entries?: SimpleMenuEntry[];
    buttonIcon?: ReactNode;
    buttonComponent?: ReactElement;
    disabled?: boolean;
    menuDistance?: PopoverArgs['distance'];
    menuLabel?: string;
    menuPlacement?: any;
    //onClick,
    onClose?: PopoverArgs['onHide'];
    onOpen?: PopoverArgs['onShow'];
    nonBWStyle?: boolean; //honestly not sure what this is for?
    portal?: boolean;
};

//The component
const SimpleMenu = forwardRef<HTMLButtonElement | null, SimpleMenuArgs>(function SimpleMenu(
    {
        title,
        hideTitle = false,
        description,
        entries,
        buttonIcon,
        buttonComponent,
        menuDistance = 10,
        menuLabel,
        menuPlacement, //just here to extract from 'rest'?
        onClose,
        onOpen,
        nonBWStyle = false,
        portal = false,
        disabled = false,

        ...rest
    },
    ref,
) {
    const classes = useStyles({selectedItemLbL: T('simpleMenu.selected')});

    const btn = buttonComponent ? (
        React.cloneElement(buttonComponent, {
            ref: mergeRefs(ref, (buttonComponent as any).ref),
            className: cn('bw-simple-menu-btn', buttonComponent.props.className), //why do we need this?
            ...rest,
        })
    ) : (
        <DefaultButton menuLabel={menuLabel} {...rest} ref={ref}>
            {buttonIcon ?? <IconRouter name="mui-more-horiz" className={classes.ellipsisIcon} />}
        </DefaultButton>
    );

    const menuContent = useCallback<PopoverRenderFunc>(
        ({close, labelId, descriptionId}) => {
            return (
                <>
                    {title && (
                        <div id={labelId} className={hideTitle ? 'offscreen' : classes.title}>
                            {title}
                        </div>
                    )}
                    {description && (
                        <div id={descriptionId} className="offscreen">
                            {description}
                        </div>
                    )}
                    <MenuList className="bw-simple-menu-list">
                        {entries?.map((entry, i) => {
                            if(isDividerEntry(entry)) {
                                return <Divider component="li" key={i} />
                            }

                            const {label, icon, onClick, selected, disabled} = entry;

                            let tooltip: ReactNode = null;

                            const itemProps: Parameters<typeof MenuItem>[0] = {
                                key: i,
                                className: nonBWStyle //what is this about?
                                    ? `bw-simple-menu-item ${classes.item}`
                                    : 'bw-simple-menu-item',
                                selected: !!selected,
                                onClick: disabled ? undefined : () => {
                                    close(); //close the menu
                                    onClick?.(); //call the onClick handler for this item, if there is one
                                },
                                disabled,
                            };

                            if (isLinkEntry(entry)) {
                                const {href, target} = entry;
                                //If this menu item is in fact a link use component prop (need to cast to fix TS limitation)
                                // Disable lint because props get added by MUI internally
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                (itemProps as any).component = 'a';
                                (itemProps as any).href = href;
                                (itemProps as any).target = target ?? undefined;
                                itemProps.className = 'bw-simple-menu-item link-item';
                            } else {
                                //Is not a link entry
                                const {tooltip: entryTooltip} = entry;

                                if(entryTooltip) {
                                    tooltip = entryTooltip;

                                    if(disabled) {
                                        //TODO need to re-enable pointer events
                                        itemProps.className = cn(itemProps.className, classes.disabledWithTooltip);
                                    }
                                }
                            }

                            const menuItem = (
                                <MenuItem {...itemProps}>
                                    {!!icon ? (
                                        nonBWStyle ? (
                                            <>
                                                <span className={classes.icon}>{icon}</span>
                                                <span className={classes.text}>{label}</span>
                                            </>
                                        ) : (
                                            <>
                                                <ListItemIcon className="bw-simple-menu-item-icon">
                                                    {icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    className="bw-simple-menu-item-text"
                                                    inset
                                                    primary={label}
                                                />
                                            </>
                                        )
                                    ) : (
                                        label
                                    )}
                                </MenuItem>
                            );

                            return tooltip 
                                ? <Tooltip placement='right' portal tooltip={tooltip}>{menuItem}</Tooltip>
                                : menuItem
                        })}
                    </MenuList>
                </>
            );
        },
        [title, hideTitle, classes.title, classes.item, classes.icon, classes.text, classes.disabledWithTooltip, description, entries, nonBWStyle],
    );

    return (
        <Popover
            content={menuContent}
            distance={menuDistance}
            onShow={onOpen}
            onHide={onClose}
            portal={portal}
            disabled={disabled}
        >
            {btn}
        </Popover>
    );
});

export default memo(SimpleMenu);

type IconButtonArgs = Omit<Parameters<typeof IconButton>[0], 'ref'>;

interface DefaultButtonArgs extends IconButtonArgs {
    menuLabel?: string;
    children: ReactNode;
}

const DefaultButton = forwardRef<HTMLButtonElement | null, DefaultButtonArgs>(
    ({children, className, menuLabel, ...rest}, ref) => {
        const props = {
            className: cn('bw-simple-menu-btn', className),
            'aria-label': menuLabel || 'menu', //TODO this needs to be better
            ...rest,
        };

        return (
            <IconButton {...props} ref={ref} size="large">
                {children}
            </IconButton>
        );
    },
);
