import {useCallback, useState} from 'react';
import classNames from 'classnames';

import Slider, { SliderProps } from '@mui/material/Slider';

//Components
import Checkbox from 'hsi/components/Checkbox';
import Select from 'hsi/components/Select';

// Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
import { useCardTypeLimit } from 'hsi/utils/cards/getCardTypeLimit';
import { WordCloudColourBy, WordCloudOrderBy, WordCloudTypes } from 'hsi/constants/config';
import useGetWordCloudConfig, { DefaultWordCloudConfig } from 'hsi/components/Cards/WordCloud/useGetWordCloudConfig';
import useQueryId from 'hsi/hooks/useQueryId';
import { useGetQueryUserDataSetter } from 'hsi/hooks/useQueryUserData';
import { WordCloudConfigType } from 'hsi/types/queryUserData';


//Utils
type ControlProps<T extends string> = {
    type: 'orderBy' | 'color', 
    value: T, 
    onChange: (value: T) => void, 
    options: T[] | Readonly<T[]>;
};

function Control<T extends string> ({type, value, onChange, options}: ControlProps<T>) {
    const classes = useStyles();
    const id = useUniqueId(type);

    return <div>
        <label htmlFor={id} className={classes.label}>
            {T('configSideDrawer.wordCloud.' + type)}
        </label>
        <Select
            id={id}
            value={value}
            onChange={(e) => onChange(e.target.value as T)}
        >
            {options.map((selectItem: string) => (
                <option key={type + selectItem} value={selectItem}>
                    {T('configSideDrawer.wordCloud.selectItems.' + type + '.' + selectItem)}
                </option>
            ))}
        </Select>
    </div>
}


//The component
export default function WordCloudControls() {
    const classes = useStyles();
    const {trackWithSearchData} = useEventTrack();
    const queryId = useQueryId();

    const wordCloudConfig = useGetWordCloudConfig(queryId);

    const [cloudSize, setCloudSize] = useState(wordCloudConfig.size);

    const {limit: maxItems} = useCardTypeLimit('wordCloud');

    const onChangeSize: SliderProps['onChange'] = (e, value) => {
        setCloudSize(value as number);
    };

    const setter = useGetQueryUserDataSetter(queryId);

    const onParamChange = useCallback(<T extends keyof WordCloudConfigType>(param: T, value: WordCloudConfigType[T]) => {
       if(!setter) {
            return;
        }

        setter('wordCloudCard', (wordCloudConfig) => {
            return {
                ...wordCloudConfig ?? DefaultWordCloudConfig,
                [param]: value
            }
        });

        trackWithSearchData('cardCustomized', {
            metric: param,
            type: 'wordCloud',
            value,
        });
    }, [setter, trackWithSearchData])

    return (
        <>
            <fieldset className={classes.checkboxList}>
                <legend className={classNames(classes.checkboxListHeading, classes.label)}>
                    {T('configSideDrawer.wordCloud.show')}
                </legend>
                {WordCloudTypes.map((wordCloudtype) => {
                    const elemId = `wordCloudConfig-show-${wordCloudtype}`;

                    return <div className={classes.checkboxWithLabel} key={wordCloudtype}>
                        <Checkbox
                            id={elemId}
                            checked={wordCloudConfig.types.includes(wordCloudtype)}
                            onChange={() => {
                                const newValue = WordCloudTypes.filter((itemId) => itemId === wordCloudtype 
                                    ? !wordCloudConfig.types.includes(wordCloudtype)// toggle  current value
                                    : wordCloudConfig.types.includes(itemId)// keep current value
                                );

                                onParamChange('types', newValue);
                            }}
                        />
                            <label
                                className={classes.checkboxLabel}
                                htmlFor={elemId}
                            >
                                {T(`cards.wordCloud.types.${wordCloudtype}`)}
                            </label>
                    </div>
                })}
            </fieldset>
            <Control type="orderBy" value={wordCloudConfig.orderBy} onChange={(value) => onParamChange('orderBy', value)} options={WordCloudOrderBy} />
            <Control type="color" value={wordCloudConfig.color} onChange={(value) => onParamChange('color', value)} options={WordCloudColourBy} />
            <div>
                <label className={classes.label} id="wordCloudConfigSizeLbl">{T('configSideDrawer.wordCloud.cloudSize')}</label>
                <Slider
                    aria-labelledby={'wordCloudConfigSizeLbl'}
                    max={maxItems}
                    min={1}
                    onChange={onChangeSize}
                    onChangeCommitted={(e, value) => onParamChange('size', value as number)}
                    value={cloudSize}
                    valueLabelDisplay="auto"
                />
            </div>
        </>
    );
};
