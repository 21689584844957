import { useEffect, useRef } from "react";


/**
 * This hook will return true on the very first render of a component, and false on all subsequent renders
 * @returns boolean, is this the first render of the component
 */
export default function useIsComponentInit() {
    const isMountRef = useRef(true);

    useEffect(() => {
        isMountRef.current = false;
    }, []);
    
    return isMountRef.current;
  };