import {useRef, useState, useMemo, useCallback, useEffect, memo} from 'react';
import cn from 'classnames';
import Editor, { EditorProps, Monaco, OnChange } from '@monaco-editor/react';
import {editor} from 'monaco-editor';
import useResizeObserver from 'use-resize-observer/polyfilled';
import {useParams, useLocation} from 'react-router-dom';

import HighlightedText from './HighlightedText';

import {editorConfig as _editorConfig, editorTheme, languageId} from './config/editorConfig';
import {bracketMatchingConfig, syntaxHighlightingConfig} from './config/languageConfig';
import {validateQuery} from 'hsi/services/apiv2/queryValidationService';
import getQueryTypeFromUrl from 'hsi/utils/app/getQueryTypeFromUrl';

//Hooks
import { useAppSelector } from 'hsi/hooks/useRedux';
import useConfig from 'hsi/hooks/useConfig';
import usePrevious from 'hsi/hooks/usePrevious';
import useQueryContext from 'hsi/hooks/useQueryContext';
import useStyles from './styles';

import {T} from 'hsi/i18n';

function getEditorHeight(contentHeight: number, maxLines: number | undefined, editorConfig: NonNullable<EditorProps['options']>) {
    if (!maxLines) return contentHeight;
    const maxHeight =
        (maxLines ?? 0) * (editorConfig.lineHeight ?? 1) +
        (editorConfig?.padding?.top || 0) +
        (editorConfig?.padding?.bottom || 0);
    return contentHeight <= maxHeight ? contentHeight : maxHeight;
};

const INVALID_CHARACTER_SUBSTITUTIONS = [
    {match: /[“”]/g, value: '"'},
    {match: /[‘’]/g, value: "'"},
    {match: /[—–]/g, value: '-'},
    {match: /[\u202F\u00A0]/g, value: ' '},
];

type QueryEditorProps = {
    autofocus?: boolean;
    className?: string;
    defaultQuery?: string;
    disabled?: boolean;
    readOnly?: boolean;
    maxLines?: number;
    maxQueryLength: number;
    onBlur?: () => void;
    onChange?: OnChange;
    onError?: (messages: string[]) => void;
    onFocus?: () => void;
    onSubmit?: (value: string) => void;
    onValidated?: (search: string) => void;
    onValidating?: (value: boolean) => void;
    onGoToHelp?: () => void;
    placeholder?: string;
    showEllipsis?: boolean;
};

const QueryEditor = ({
    autofocus,
    className,
    defaultQuery,
    disabled,
    readOnly,
    maxLines,
    maxQueryLength,
    onBlur,
    onChange,
    onError,
    onFocus,
    onSubmit,
    onGoToHelp,
    onValidated,
    onValidating,
    placeholder,
    showEllipsis,
}: QueryEditorProps) => {
    const config = useConfig();

    const editorConfig = _editorConfig(config.editorConfig as EditorProps['options']);
    const classes = useStyles(config.editorConfig);
    const queryContext = useQueryContext();
    const projects = useAppSelector((state) => state.user.projects);
    const urlParams = useParams();
    const location = useLocation();
    const queryType = getQueryTypeFromUrl(location, urlParams);
    const isEditSearch = queryType === 'edit';

    const [query, setQuery] = useState('');
    const [focused, setFocused] = useState(false);
    const [editorWidth, setEditorWidth] = useState<string | number>('100%');
    const [editorHeight, setEditorHeight] = useState(editorConfig.lineHeight);

    const editorRef = useRef<editor.IStandaloneCodeEditor | undefined>();
    const decorationsRef = useRef<string[] | undefined>();
    const sizeRef = useRef<{width: number, height: number} | undefined>();

    const prevEllipsis = usePrevious(showEllipsis);

    useEffect(() => {
        if (prevEllipsis !== showEllipsis && editorRef.current) {
            editorRef.current.setPosition({column: 1, lineNumber: 1});
        }
    }, [prevEllipsis, showEllipsis]);

    useEffect(() => {
        if (typeof defaultQuery !== 'undefined' && defaultQuery !== query) {
            setQuery(defaultQuery);
        }
        // Check if deps can be added without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultQuery]);

    useEffect(() => {
        return () => {
            if (editorRef.current) {
                editorRef.current.dispose();
                editorRef.current = undefined;
            }
        };
    }, [editorRef]);

    const options:editor.IStandaloneEditorConstructionOptions = useMemo(
        () => ({
            ...editorConfig,
            wordWrap: !showEllipsis ? 'on' : 'off',
            maxTokenizationLineLength: 100000,
            ariaLabel: T('queryEditor.editorLbl'),
        }),
        [editorConfig, showEllipsis],
    );

    const {ref, width: resizeWidth} = useResizeObserver();

    useEffect(() => {
        resizeWidth && setEditorWidth(resizeWidth);
    }, [resizeWidth]);

    const setupMonaco = useCallback(
        (editor: editor.IStandaloneCodeEditor, monaco: Monaco, model: editor.ITextModel) => {
            editorRef.current = editor;
            monaco.languages.register({id: languageId});
            monaco.languages.setMonarchTokensProvider(languageId, syntaxHighlightingConfig);
            monaco.languages.setLanguageConfiguration(languageId, bracketMatchingConfig);
            monaco.editor.defineTheme('brandwatchTheme', editorTheme);
            monaco.editor.setTheme('brandwatchTheme');
            editor.updateOptions({readOnly: !!readOnly || !!disabled});

            let tabTrapped = false;
            const disableTabTrap = editor.createContextKey<boolean>('editorTabMovesFocus', false);

            editor.onDidFocusEditorWidget(() => {
                setFocused(true);
                onFocus && onFocus();
            });

            editor.onDidBlurEditorWidget(() => {
                setFocused(false);
                onBlur && onBlur();
            });

            // Persist tab trap
            const macFocusHotkeys =
                monaco.KeyMod.WinCtrl | monaco.KeyMod.Shift | monaco.KeyCode.KeyM;

            const windowsFocusHotkeys = monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyM;

            const setTabTrapped = (isTabTrapped: boolean) => {
                tabTrapped = isTabTrapped;
                disableTabTrap.set(!isTabTrapped);
            };

            (editor as any)._standaloneKeybindingService.addDynamicKeybinding(
                'editor.action.toggleTabFocusMode',
                macFocusHotkeys || windowsFocusHotkeys,
                () => setTabTrapped(!tabTrapped),
            );

            editor.onDidContentSizeChange((size) => {
                const currWidth = editor.getDomNode()!.offsetWidth;
                if (
                    !sizeRef.current ||
                    currWidth !== sizeRef.current.width ||
                    size.contentHeight !== sizeRef.current.height
                ) {
                    const height = getEditorHeight(size.contentHeight, maxLines, editorConfig);
                    setEditorHeight(height);
                    const newSize = {height, width: currWidth};
                    editor.layout(newSize);
                    sizeRef.current = newSize;
                }
            });

            // Monaco config is reset when the guided styles popover appears on focussing the search bar.
            // Therefore we need to ensure the tab trap persists on a config change.
            editor.onDidChangeConfiguration(() => {
                if (!tabTrapped && !disableTabTrap.get()) {
                    disableTabTrap.set(true);
                }
            });

            //Disable command pallette
            editor.addCommand(
                monaco.KeyCode.F1,
                () => {}, //Just do nothing?
            );

            //Change the Alt+F1 shortcut to show help
            editor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.F1, () => {
                onGoToHelp?.();
            });

            editor.addCommand(
                monaco.KeyCode.Enter,
                async () => {
                    if (decorationsRef.current) {
                        editor.deltaDecorations(decorationsRef.current, []);
                        decorationsRef.current = undefined;
                    }
                    const query = editor.getValue().trim();
                    if (!query) {
                        onSubmit?.('');
                        return;
                    }
                    (document.activeElement instanceof HTMLElement) && document.activeElement.blur();
                    try {
                        onValidating && onValidating(true);

                        const result = await validateQuery(queryContext?.projectId || projects![0].id, query, isEditSearch);

                        onValidating && onValidating(false);

                        if (!result.body.response.valid) {
                            const decorations: editor.IModelDeltaDecoration[] = [];

                            result.body.response.issues.forEach(({startColumn, startRow, endColumn, endRow}) => {
                                if(startColumn !== -1 && startRow !== -1 && endColumn !== -1 && endRow !== -1) {
                                    decorations.push({
                                        range: new monaco.Range(
                                            startRow,
                                            startColumn,
                                            endRow,
                                            endColumn + 1,
                                        ),
                                        options: {inlineClassName: 'query-error'},
                                    });
                                }
                                
                            });
                            decorationsRef.current = editor.deltaDecorations([], decorations);
                            onError && onError(result.body.response.issues.map((i) => i.message));
                        } else {
                            //TODO what to do if we have issues of type 'info'?

                            onValidated && onValidated(result.body.response.query);
                            if (query.length > maxQueryLength) {
                                return;
                            }

                            onSubmit && onSubmit(result.body.response.query);
                        }
                    } catch (err) {
                        onValidating && onValidating(false);
                        onError && onError([T('queryBuilder.validationError')]);
                        console.error('validationError', err);
                    }
                },
                '!suggestWidgetVisible',
            );

            model.onDidChangeContent(() => {
                const booleanQuery = model.getValue();

                if (booleanQuery && booleanQuery.match(/[“”‘’—–\u202F\u00A0]/g)) {
                    model.setValue(
                        INVALID_CHARACTER_SUBSTITUTIONS.reduce(
                            (text, {match, value}) => text.replace(match, value),
                            booleanQuery,
                        ),
                    );
                }
            });
        },
        [
            readOnly,
            disabled,
            editorConfig,
            maxLines,
            maxQueryLength,
            onBlur,
            onError,
            onFocus,
            onGoToHelp,
            onSubmit,
            onValidated,
            onValidating,
            projects,
            queryContext?.projectId,
            isEditSearch,
        ],
    );

    const handleOnChange: OnChange = useCallback(
        (val, changeInfo) => {
            setQuery(val ?? '');
            if (decorationsRef.current) {
                editorRef.current?.deltaDecorations(decorationsRef.current, []);
                decorationsRef.current = undefined;
            }
            !readOnly && !disabled && onChange?.(val, changeInfo);
        },
        [readOnly, disabled, onChange],
    );

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.updateOptions({readOnly: !!readOnly || !!disabled});
        }
    }, [readOnly, disabled, editorRef]);

    function onEditorDidMount(editor: editor.IStandaloneCodeEditor, monaco: Monaco) {
        try {
            setupMonaco(editor, monaco, editor.getModel()!);
            setTimeout(() => {
                editor.layout();
                autofocus && !readOnly && !disabled && setTimeout(() => editor.focus());
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div
            ref={ref}
            className={cn(classes.queryEditorRoot, className)}
            data-testid="queryEditor"
            inert={disabled ? 'inert' : undefined}
        >
            {!focused && !!query && showEllipsis && (
                <HighlightedText
                    aria-hidden
                    text={query}
                    className={classes.highlightEllipsisOverlay}
                    ellipsis
                />
            )}
            {!focused && !query && !!placeholder && (
                <div className={classes.placeholder} aria-hidden>
                    {placeholder}
                </div>
            )}
            <Editor
                className={cn(classes.queryEditor, 'query-editor')}
                language={languageId}
                theme={'brandwatchTheme'}
                onMount={onEditorDidMount}
                options={options}
                loading={''}
                onChange={handleOnChange}
                value={query}
                width={editorWidth}
                height={editorHeight}
            />
        </div>
    );
};



export default memo(QueryEditor);
