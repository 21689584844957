import last from 'lodash/last';

import {SENTIMENT_CATEGORIES} from 'hsi/constants/config';

import type {ResultPeaks, SentimentPeakData} from 'hsi/types/peaks';
import type {CardDataType} from 'hsi/types/cards';

export function getMagnitude(n: number) {
    let order = Math.floor(Math.log(n) / Math.LN10);
    return Math.pow(10, order);
}

export function getChartRangeMax(n: number) {
    let firstRound = [0, 0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
    let secondRound = [0, 0.2, 0.4, 0.5, 0.8, 1];
    let selectedRound;

    let mag = getMagnitude(n);
    let base = mag;

    if (n <= 2 * mag) {
        base = mag;
        selectedRound = firstRound;
    } else if (n <= 4 * mag) {
        base = n > 3 * mag ? 3 * mag : 2 * mag;
        selectedRound = secondRound;
    } else {
        selectedRound = firstRound;
        mag *= 10;
        base = 0;
    }

    for (let x of selectedRound) {
        let res = parseFloat((x * mag + base).toFixed(2));
        if (res >= n) {
            return res;
        }
    }
}

export function extractEmotionId(id: number | string) {
    return last(String(id).split(':'))?.toLowerCase();
}

export function hasHistoryData(data: any, usesIndexedValues?: boolean) {
    const hasDataLoaded =
        !!data &&
        !!data?.categories &&
        data.categories.length > 0 &&
        !!data?.series &&
        data.series.length > 0;

    if (hasDataLoaded) {
        const categories = data.categories.map(({id}: {id: any}) => id);

        return data.series.some((val: any) => {
            return usesIndexedValues
                ? categories
                : categories.some((category: any) => val[category] > 0);
        });
    }

    return false;
}

export function hasCategoriesData(data: any) {
    const hasDataLoaded =
        !!data &&
        !!data?.categories &&
        data.categories.length > 0 &&
        !!data?.results &&
        data.results.length > 0;

    if (hasDataLoaded) {
        const categories = data.categories.map(({id}: {id: any}) => id);

        return data.results.some((val: any) => {
            return categories.some((category: any) => val[category] > 0);
        });
    }

    return false;
}

type SentimentCategory = (typeof SENTIMENT_CATEGORIES)[number];

const processSentimentHistory = (p: SentimentPeakData): Array<{[key: string]: any}> => {
    if (SENTIMENT_CATEGORIES.includes(p.id as SentimentCategory)) {
        return p.peaks.map((pk) => ({...pk, id: p.id}));
    }
    return [];
};

const processMentionsHistory = (p: SentimentPeakData): Array<{[key: string]: any}> =>
    p.peaks.map((pk) => ({...pk, id: p.id}));

const processEmotionHistory = (p: SentimentPeakData): Array<{[key: string]: any}> => {
    if (typeof p.id === 'string' && p.id.includes('emotion')) {
        return p.peaks.map((pk) => ({...pk, id: p.id.split(':')[1].toLowerCase()}));
    }
    return [];
};

type HistoryCardType = keyof Pick<
    CardDataType,
    'mentionsHistory' | 'sentimentHistory' | 'emotionHistory'
>;

export function formatHistoryPeaks(
    peaks: ResultPeaks,
    cardType: HistoryCardType,
): Array<{[key: string]: any}> {
    if (!peaks[cardType]) return [];

    const processors: {
        [K in HistoryCardType]: (p: SentimentPeakData) => Array<{[key: string]: any}>;
    } = {
        mentionsHistory: processMentionsHistory,
        sentimentHistory: processSentimentHistory,
        emotionHistory: processEmotionHistory,
    };

    const processor = processors[cardType];
    if (!processor) return [];

    return peaks[cardType]!.flatMap(processor);
}
