import { Theme, isV2 } from 'hsi/types/theme';
import {makeStyles} from '@mui/styles';

export default makeStyles<Theme>((theme) => ({
    alertDialog: {
        ...(isV2(theme) ? theme.fonts.bodyNormal : {}),

        '& .MuiDialog-paper': {
            width: '700px',
        },
    },
}));
