/**
 * APIError mirrors our BaseApiError model used on the server.
 */
export interface ApiError {
    error: boolean;
    status: number;
    body: {
        code: string;
        status: number;
        errors: string[];
    };
}

/**
 * This type guard can be used to check for known server error responses.
 * It should be used when processing API errors.
 * @param error
 * @returns
 */
export function isApiError(error: unknown): error is ApiError {
    if (typeof error !== 'object' || error === null) return false;
    const errorObj = error as any;
    return (
        'error' in errorObj &&
        'status' in errorObj &&
        'body' in errorObj &&
        Array.isArray(errorObj.body.errors)
    );
}
