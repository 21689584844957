import {createAsyncThunk} from '@reduxjs/toolkit';

import {
    sharedDashboardGuestAuth,
    sharedDashboardGuestGetSnapshot,
} from 'hsi/services/apiv2/sharedDashboardsGuest';
import {isApiError} from 'hsi/types/errors';
import { GuestAuthReponse } from 'hsi/types/sharedReports';
import { Snapshot } from 'hsi/types/snapshots';

interface GuestAuthParams {
    snapshotId: string;
    password: string;
}

/**
 * Authentication action for Shared Dashboards.
 * Returns a metadata list of snapshots associated with the snapshot.
 */
export const guestAuth = createAsyncThunk<
    GuestAuthReponse,
    GuestAuthParams,
    {
        rejectValue: string;
    }
>('sharedDashboard/guestAuth', async ({snapshotId, password}, {rejectWithValue}) => {
    try {
        const res = await sharedDashboardGuestAuth(snapshotId, password);
        return res.body.response;
    } catch (error) {
        if (isApiError(error)) {
            return rejectWithValue(error.body.errors[0]);
        }
        return rejectWithValue('An unknown error occurred while authenticating guest.');
    }
});

interface GetSnapshotParams {
    snapshotId: string;
}

/**
 * Get single snapshot.
 * Returns a single snapshot with data needed to render the snapshot.
 */
export const getSnapshot = createAsyncThunk<
    Snapshot,
    GetSnapshotParams,
    {
        rejectValue: string;
    }
>('sharedDashboard/getSnapshot', async ({snapshotId}, {rejectWithValue}) => {
    try {
        const res = await sharedDashboardGuestGetSnapshot(snapshotId);
        return res.body.response;
    } catch (error) {
        if (isApiError(error)) {
            return rejectWithValue(error.body.errors[0]);
        }
        return rejectWithValue('An unknown error occurred while fetching snapshot.');
    }
});
