import makeStyles from '@mui/styles/makeStyles';

import {Theme, isV2} from 'hsi/types/theme';


export default makeStyles((theme: Theme) => {
    const v2 = isV2(theme);

    return {
        itemWithHeader: {
            display: 'flex',
            flexFlow: 'column nowrap',
            gap: '4px',
        },
        header: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            lineHeight: '20px',

            fontWeight: v2 ? theme.fonts.bodyNormal.fontWeight : 700,
            fontSize: v2 ? theme.fonts.bodyNormal.fontSize : '16px',
        },
        headerRight: {
            display: 'flex',
            flexFlow: 'row nowrap',
            gap: '16px',
        },

    };
});
