
import compact from 'lodash/compact';

//Components
import Select from 'hsi/components/Select';

//Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useQueryId from 'hsi/hooks/useQueryId';
import { useCurrentQueryUserData, useGetQueryUserDataSetter } from 'hsi/hooks/useQueryUserData';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import { TotalVolumeMetricType } from 'hsi/types/cards';
import { DefaultTotalVolumeMetrics, TotalVolumeMetrics } from 'hsi/constants/config';

//Types
export type MetricName = `metric${number}`;

export type TotalVolumeControlsProps = {};

//Helpers
function useGetMetrics() {
    const persistedMetrics = useCurrentQueryUserData('totalVolumeCard');

    if( !persistedMetrics || compact(persistedMetrics).length === 0) {
        return DefaultTotalVolumeMetrics;
    }

    return persistedMetrics;
}

//The component
export default function TotalVolumeControls(props: TotalVolumeControlsProps) {
    const classes = useStyles();
    const {trackWithSearchData} = useEventTrack();
    const currentSelectedMetrics = useGetMetrics();
    const userQueryDataSetter = useGetQueryUserDataSetter(useQueryId())

    const onParamChange = (value: TotalVolumeMetricType, type: MetricName) => {
        const index = Number(type.substring(6)) - 1;

        if(isNaN(index) || index < 0 || index >= TotalVolumeMetrics.length) {
            throw new Error('Invalid metric type');
        }

        if(value !== currentSelectedMetrics[index] && userQueryDataSetter) {
            const newParams = [...currentSelectedMetrics];
            newParams[index] = value;

            //Apply and persist new value
            userQueryDataSetter('totalVolumeCard', newParams);

            trackWithSearchData('cardCustomized', {
                metric: type,
                type: 'totalVolume',
                value: compact(newParams).map((totalVolumeMetric) => T(`cards.totalVolume.sections.${totalVolumeMetric}`)),
            });
        }
    };

    return ([...Array(TotalVolumeMetrics.length)].map((_, i) => {
            const n = i+1;
            const type: MetricName = `metric${n}`;
            const id = `select-${type}-${n}`;

            return (
                <div key={id}>
                    <label className={classes.label} htmlFor={id}>
                        {T('configSideDrawer.totalVolume.select.label', {i: n})}
                    </label>
                    <Select
                        id={id}
                        onChange={(e) => onParamChange(e.target.value as TotalVolumeMetricType, type)}
                        value={currentSelectedMetrics[i] || ''}
                    >
                        <option value="">{T('configSideDrawer.totalVolume.select.none')}</option>
                        {TotalVolumeMetrics.map((totalVolumeMetric) => (
                            <option key={type + totalVolumeMetric} value={totalVolumeMetric}>
                                {T(`cards.totalVolume.sections.${totalVolumeMetric}`)}
                            </option>
                        ))}
                    </Select>
                </div>
            );
        })) as any;//Needed to fix error where TS incorrectly states that 'Element[]' is not a valid return type
};
