import http, { HttpResult } from "hsi/classes/Http";
import { ApiResponse, ApiResponseNoData } from "hsi/types/apiv2";
import { QueryUserData } from "hsi/types/queryUserData";

const isTest = process.env.NODE_ENV === 'test';


export async function getQueryUserDataKey<T extends keyof QueryUserData>(queryId: number, projectId: number, key: T) {
    return http.get<ApiResponse<QueryUserData[T] | null>>(`/apiv2/${projectId}/saved-search/${queryId}/query-user-data/${key}`);
}

export async function putQueryUserDataKey<T extends keyof QueryUserData>(queryId: number, projectId: number, key: T, value: QueryUserData[T] | null): Promise<HttpResult<ApiResponseNoData>> {
    return isTest 
        ? Promise.resolve({
            status: 200,
            ok: true,
            body: {
                success: true,
            }
        } as HttpResult<ApiResponseNoData>)
        : http.put<ApiResponseNoData>(`/apiv2/${projectId}/saved-search/${queryId}/query-user-data/${key}`, {value});
}
