//If I were building this from scratch with what I now know, I would have gone about this very differently. However, I did not, so here we are.

import {
    DeprecatedThemeOptions,
    createTheme
} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Colors, Components, Font, Fonts, Theme } from 'hsi/types/theme';

import merge from 'lodash/merge';

type BaseProps =
    | 'components'
    | 'colors'
    | 'font'
    | 'fonts'
    | 'mixin'
    | 'globals'
    | 'elements'
    | 'overrides'
    | 'props'
    | 'palette'
    | 'breakpoints'
    | 'typography';

//Internal generic types
type GetFunc<T> = undefined | ((this: ThemeBuilder, theme: ThemeBuilder) => T);

type ThemeVariables = {
    components?: Components;
    colors?: GetFunc<Colors>;
    font?: GetFunc<Font>;
    fonts?: GetFunc<Fonts>;
    mixin?: GetFunc<any>; //TODO could not get proper typing working
    globals?: GetFunc<any>; //TODO could not get proper typing working
    elements?: GetFunc<any>;
    overrides?: GetFunc<DeprecatedThemeOptions['overrides']>;
    props?: GetFunc<DeprecatedThemeOptions['props']>;
    palette?: GetFunc<DeprecatedThemeOptions['palette']>;
    breakpoints?: GetFunc<DeprecatedThemeOptions['breakpoints']>;
    typography?: GetFunc<DeprecatedThemeOptions['typography']>;
    spacing?: DeprecatedThemeOptions['spacing'];
};

export default class ThemeBuilder {
    readonly id: string;
    readonly v2: boolean;

    readonly spacing: DeprecatedThemeOptions['spacing'];
    readonly imports: string[] | undefined;

    readonly components: Components;
    readonly colors: Colors;
    readonly font: Font;
    readonly fonts: Fonts;
    readonly mixin: any; //TODO could not get proper typing working
    readonly globals: any; //TODO could not get proper typing working
    readonly elements: any;
    readonly overrides: DeprecatedThemeOptions['overrides'];
    readonly props: DeprecatedThemeOptions['props'];
    readonly palette: DeprecatedThemeOptions['palette'];
    readonly breakpoints: DeprecatedThemeOptions['breakpoints'];
    readonly typography: DeprecatedThemeOptions['typography'];

    readonly _components: GetFunc<Components>;
    readonly _colors: GetFunc<Colors>;
    readonly _font: GetFunc<Font>;
    readonly _fonts: GetFunc<Fonts>;
    readonly _mixin: GetFunc<any>; //TODO could not get proper typing working
    readonly _globals: GetFunc<any>; //TODO could not get proper typing working
    readonly _elements: GetFunc<any>;
    readonly _overrides: GetFunc<DeprecatedThemeOptions['overrides']>;
    readonly _props: GetFunc<DeprecatedThemeOptions['props']>;
    readonly _palette: GetFunc<DeprecatedThemeOptions['palette']>;
    readonly _breakpoints: GetFunc<DeprecatedThemeOptions['breakpoints']>;
    readonly _typography: GetFunc<DeprecatedThemeOptions['typography']>;

    readonly _extendsTheme: ThemeBuilder | undefined;
    readonly muiTheme: Theme;

    constructor(
        id: string,
        v2: boolean,
        {
            components,
            colors,
            font,
            fonts,
            mixin,
            globals,
            elements,
            //MUI theme properties
            overrides,
            props,
            palette,
            spacing,
            breakpoints,
            typography,
        }: ThemeVariables = {},
        //Optional theme that is being extended
        extendsTheme?: ThemeBuilder,
    ) {
        this.id = id;
        this.v2 = !!v2; //needed to distinguish HSI theme from others

        this.spacing = spacing || extendsTheme?.spacing;

        this._colors = colors || extendsTheme?._colors || undefined;
        this._font = font || extendsTheme?._font || undefined;
        this._fonts = fonts || extendsTheme?._fonts || undefined;
        this._mixin = mixin || extendsTheme?._mixin || undefined;
        this._globals = globals || extendsTheme?._globals || undefined;
        this._elements = elements || extendsTheme?._elements || undefined;
        this._overrides = overrides || extendsTheme?._overrides || undefined;
        this._props = props || extendsTheme?._props || undefined;
        this._palette = palette || extendsTheme?._palette || undefined;
        this._breakpoints = breakpoints || extendsTheme?._breakpoints || undefined;
        this._typography = typography || extendsTheme?._typography || undefined;

        this._extendsTheme = extendsTheme;

        //now calculate values
        this.components = components || extendsTheme?.components || {};
        this.colors = this._merge('colors');
        this.font = this._merge('font');
        this.fonts = this._merge('fonts');
        this.mixin = this._merge('mixin');
        this.globals = this._merge('globals');
        this.elements = this._merge('elements');
        this.overrides = this._merge('overrides');
        this.props = this._merge('props');
        this.palette = this._merge('palette');
        this.breakpoints = this._merge('breakpoints');
        this.typography = this._merge('typography');

        this.imports = this.font?.url ? [`url('${this.font.url}')`] : undefined;

        // Insert 'overrides' and 'props' theme properties into the components object as required in the MUI V5 theme structure
        // TODO: This is a temporary solution to get the new V5 theme structure working with the old theme structure
        const restructureComponentsForV5 = (propName: string, values: any) => {
            for (let key in values) {
                this.components[key] = {...this.components[key], [propName]: values[key]};
            }
        };

        restructureComponentsForV5('styleOverrides', this.overrides);
        restructureComponentsForV5('defaultProps', this.props);

        //create MUI theme
        this.muiTheme = createTheme({
            components: this.components,
            themeId: this.id,
            v2: this.v2,
            props: this.props,
            overrides: this.overrides,
            colors: this.colors,
            font: this.font,
            fonts: this.fonts,
            typgrphy: this.typgrphy, //Legacy - use the fonts property
            mixin: this.mixin,
            elements: this.elements,

            ...(this.palette && {palette: this.palette}), // conditionally include (ignore if falsy)
            ...(this.breakpoints && {breakpoints: this.breakpoints}), // conditionally include (ignore if falsy)
            ...(this.spacing && {spacing: this.spacing}), // conditionally include (ignore if falsy)
            ...(this.typography && {typography: this.typography}), // conditionally include (ignore if falsy)
        }) as Theme;
    }

    get typgrphy() {
        //TODO Legacy - get rid of this (not 100% sure if it's still needed at all)
        return this.fonts;
    }

    getGlobalStyles() {
        const stylesObj: any = {
            //Not sure how to type this correctly, but not really important here
            '@global': this.globals,
        };

        if (this.imports) {
            stylesObj['@global'] = {
                //need to merge objects like this, so that @imports is first (otherwise it fails silently)
                '@import': this.imports,
                ...this.globals,
            };
        }

        return makeStyles(stylesObj);
    }

    _merge<T extends BaseProps>(this: ThemeBuilder, prop: T): ThemeBuilder[T] {
        return this[`_${prop}`] instanceof Function
            ? this[`_${prop}`]!(this)
            : this._getExtendedValue(prop);
    }

    _getExtendedValue<T extends BaseProps>(prop: T): ThemeBuilder[T] {
        if (!this._extendsTheme) {
            return null;
        }

        const baseProp: keyof ThemeBuilder = `_${prop}`;

        return this._extendsTheme[baseProp] instanceof Function
            ? this._extendsTheme[baseProp](this)
            : this._extendsTheme[baseProp];
    }

    static nullMerge<T>(obj1: T, obj2: T) {
        if (!obj1 && !obj2) {
            return undefined;
        }

        if (!obj1) {
            return obj2;
        }

        if (!obj2) {
            return obj1;
        }

        return merge({}, obj2, obj1);
    }
}
